import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Button,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Modal,
  Typography,
} from "antd";
import Logo from "../../Assets/Icons/newLogo.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import styles from "../styles/TestingRoute.module.css";
import { InputSearch } from "./InputSearch";
import { bugReportHandle } from "../state/actions";
import { ReportModal } from "./ReportModal";
import {Help} from "../../App/components/Help"
import dotEnv from "dotenv";
import { userSignUpAction } from "../../User/UserAuthenticate/state/actions";
import { useMediaQuery } from "react-responsive";
import { generateInitialsFromObj } from "../../Common/utils/extraFunctions";
const { Header } = Layout;
const { Text } = Typography;
dotEnv.config();
// const apiURL = process.env.REACT_APP_WEB_BASE_URL;

/**
 * @function Navbar
 * @returns {Object} JSX.element
 * Navbar component which is rendered for all protected routes or redirects if user is not logged in
 */
function Navbar(props) {
  const dispatch = useDispatch();
  let userKey = new URLSearchParams(window.location.search).get(
    "app_request_key"
  );
  const param = new URLSearchParams(window.location.search);
  const type = param.get("type");
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const { getUserDetailsData, userName } = useSelector(
    (state) => state.account,
    shallowEqual
  );
  const {renderComp} = useSelector(state=>state.bugReport);

  const [modalVisible, setModalVisible] = useState(false);
  const history = useHistory();
  const [fileText, setFileText] = useState([]);
  const [tabName,setTabName] = useState("SUPPORT");
  const [role,setRole] = useState(false);
  const location = useLocation()
  let clearForm = (form) => {
      form.resetFields();
      setFileText({ name: null, size: null });
    dispatch(bugReportHandle.clearImageRest());
  };
  let cancelHandel = (form) => {
        clearForm(form);
        setModalVisible(false);
  };

  const logout = () => {
    dispatch(userSignUpAction.logOut(history));
  };

  let getKey = localStorage.getItem("userAuth") || "";

  const menu = (
    <Menu>
      <Menu.Item key="0" disabled>
        Hi{" "}
        {getUserDetailsData && getUserDetailsData?.firstName
          ? getUserDetailsData?.firstName
          : userName}
      </Menu.Item>
      <Menu.Item key="1" onClick={logout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  // let redirectHome = async () => {
  //   await window?.ReactNativeWebView?.postMessage("WINDOW_CLOSED");
  // };
  // let closeHandle = () => {
  //   if (userKey === null || userKey === undefined) {
  //     window.history.back();
  //   } else {
  //     redirectHome();
  //   }
  // };

  // const handleClose = async () => {
  //   const isMobile = await detectMobile();
  //     if (isMobile) {
  //       redirectHome();
  //     } else {
  //        history.push("/tools");
  //     }
  // };

  // let handledropDownMenu = (e) => {
  //   switch (e.key) {
  //     case "0": {
  //       history.push("/bugsReport");
  //       break;
  //     }
  //     case "1": {
  //       history.push("/yourBugs");
  //       break;
  //     }
  //     case "2": {
  //       handleReportBug();
  //       break;
  //     }
  //     case "3": {
  //       logout();
  //       break;
  //     }
  //     case "4": {
  //       handleClose()
  //       break;
  //     }
  //     case "5": {
  //       history.push("/suggestions");
  //       break;
  //     }
  //     case "6": {
  //       history.push("/yourSuggestions");
  //       break;
  //     }
  //     case "7": {
  //        history.push("/mobilesupport");
  //       break;
  //     }
  //     case "8": {
  //       history.push("/yourTickets");
  //       break;
  //     }
  //     default:
  //       break;
  //   }
  // };
  // const dropdownMenu = () => {
  //   if (userKey === null || userKey === undefined) {
  //     return (
  //       <Menu
  //         style={{ minWidth: "200px" }}
  //         onClick={(e) => handledropDownMenu(e)}
  //       >
  //         <Menu.Item key="0">Bug Listings</Menu.Item>
  //         <Menu.Item key="5">Suggestions</Menu.Item>
  //         <Menu.Item key="7">Support</Menu.Item>
  //         {getUserDetailsData.adminRole === "SUPER_ADMIN" || role ? <Menu.Item key="1">Your Bugs</Menu.Item> : null}
  //         {getUserDetailsData.adminRole === "SUPER_ADMIN" || role ? <Menu.Item key="6">Your Suggestions</Menu.Item> : null}
  //         {getUserDetailsData.adminRole === "SUPER_ADMIN" || role ? <Menu.Item key="8">Your Tickets</Menu.Item> : null}
  //         {/* <Menu.Item key="2">Report Bug</Menu.Item> */}
  //         <Menu.Item key="4">Close</Menu.Item>
  //       </Menu>
  //     );
  //   }
  //   return (
  //     <Menu
  //       style={{ minWidth: "200px" }}
  //       onClick={(e) => handledropDownMenu(e)}
  //     >
  //       <Menu.Item key="0">Bug Listings</Menu.Item>
  //       <Menu.Item key="5">Suggestions</Menu.Item>
  //       <Menu.Item key="7">Support</Menu.Item>
  //       {getUserDetailsData.adminRole === "SUPER_ADMIN" || role ? <Menu.Item key="1">Your Bugs</Menu.Item> : null}
  //       {getUserDetailsData.adminRole === "SUPER_ADMIN" || role ? <Menu.Item key="6">Your Suggestions</Menu.Item> : null}
  //       {getUserDetailsData.adminRole === "SUPER_ADMIN" || role ? <Menu.Item key="8">Your Tickets</Menu.Item> : null}
  //       {/* <Menu.Item key="2">Report Bug</Menu.Item> */}
  //       <Menu.Item key="4">Close</Menu.Item>

  //       {/* <Divider />
  //       <Menu.Item key="3">Logout</Menu.Item> */}
  //     </Menu>
  //   );
  // };

  let handleReportBug = () => {
    setModalVisible(true);
  };

  // if (loggedIn) return <Redirect to={"/bugsReport"} />;

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = (form,imageFormdataRef) => {
    setModalVisible(false);
    clearForm(form)
    if(imageFormdataRef){
        imageFormdataRef.current = [];
    }
  };
  
  const getTabName = () => {
    if(renderComp === "listings"){
      setTabName("BUG_REPORT")
    }else if(renderComp === "suggestions"){
      setTabName("SUGGESTIONS")
    }else{
      setTabName("SUPPORT")
    }
  }

  const handleLogoRedirect = () => {
    Modal.confirm({
      title: "Are you sure you want to exit the RE-Sure site?",
      okText: "Yes",
      okType:"primary",
      cancelText: "No",
      onOk: async () => await window?.ReactNativeWebView?.postMessage("REDIRECT_HOME")
    })
  }

  useEffect(() => {
    for(let key in getUserDetailsData?.roles){
      if(getUserDetailsData?.roles[key] === "RESURE_SUPPORT"){
        setRole(true);
        break;
      }else{
        setRole(false);
      }
    }
  },[getUserDetailsData?.roles])

  useEffect(() => {
    getTabName()
  },[renderComp])

  useEffect(() => {
    if(type?.length){
      if(type === "BUGS"){
        dispatch(bugReportHandle.renderComponant("listings"))
      }else if(type === "SUGGESTIONS"){
        dispatch(bugReportHandle.renderComponant("suggestions"))
      }else{
        dispatch(bugReportHandle.renderComponant("support"))
      }
    }else{
      if(location?.pathname === "/bugsReport"){
        dispatch(bugReportHandle.renderComponant("listings"));
      }else if(location?.pathname === "/suggestions"){
        dispatch(bugReportHandle.renderComponant("suggestions"));
      }else if(location?.pathname === "/mobilesupport"){
        dispatch(bugReportHandle.renderComponant("support"));
      }
    }
  },[location,type])

  return (
    <>
      <Modal
        title="Enter Bug Details"
        visible={modalVisible}
        onOk={handleOk}
        width={"600px"}
        closeIcon={<></>}
        // onCancel={handleCancel}
        footer={false}
      >
        <ReportModal  handleCancel={handleCancel}
         fileText={fileText} clearForm={clearForm} cancelHandel={cancelHandel} setFileText={setFileText} setModalVisible={setModalVisible}
         />
      </Modal>
      <Header
        className="site-layout-background"
        style={{
          padding: "0px",
          background: "hsl(208deg 89% 30%)",
          position: "fixed",
          width: "-webkit-fill-available",
          top: 0,
          zIndex: 100,
          minWidth: "100%",
        }}
      >
        {/* xs={24} sm={20} md={17} lg={20} */}
        <Row >
          <Col
            // flex="0 1 1px"
            style={{
              fontSize: "25px",
              lineHeight: "64px",
              cursor: "pointer",
              transition: "all .5s ease-in-out",
              color: "rgb(78, 115, 223)",

            }}
            xs={6}
            sm={6}
            md={5}
            lg={0}
            xl={0}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "64px",
                alignItems: "center",
              }}
            >
              {/* <Dropdown overlay={dropdownMenu} trigger={["click", "hover"]}>
                <MenuOutlined style={{ color: "white" }} />
              </Dropdown> */}
              <div>
                <img
                  className={styles.logo}
                  style={{height: "auto",width:"70%" }}
                  src={Logo}
                  alt="Logo"
                  onClick={handleLogoRedirect}
                />
              </div>
            </div>
          </Col>
          <Col
            // flex="0 1 1px"
            style={{
              fontSize: "25px",
              lineHeight: "64px",
              cursor: "pointer",
              transition: "all .5s ease-in-out",
              color: "rgb(78, 115, 223)",

            }}
            xs={0}
            sm={0}
            md={0}
            lg={4}
            xl={4}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                height: "64px",
                alignItems: "center",
              }}
            >
              <Link to="/">
                <img
                  className={styles.logo}
                  style={{ width: isMobile ? "50px" : "100px", height: "auto" }}
                  src={Logo}
                  alt="Logo"
                />
              </Link>
            </div>
          </Col>
          <Col
            // flex="1 1 1px"
            style={{
              fontSize: "25px",
              lineHeight: "64px",
              cursor: "pointer",
              transition: "all .5s ease-in-out",
              color: "rgb(78, 115, 223)",

            }}
            xs={13}
            sm={15}
            md={12}
            lg={12}
            xl={14}
          >
            <div
              className={styles.inputSearchWrapper}
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign:"center",
                height: "64px",
                // marginLeft:"250px",
                alignItems: "center",
                // width: "400px",

              }}
            >
             <InputSearch/>
              {/* <Input placeholder="Search any bug" onChange={(e)=>handleSearch(e.target.value)} /> */}
            </div>
          </Col>
          {/* <Col
          xs={2} sm={3} md={0} lg={0} xl={0}
        >
          <PlusCircleFilled
                    style={{
                      fontSize: "30px",
                    //   fill: "#085191",
                    //   color: "#085191",
                    color:"whitesmoke",
                    marginTop:"15px",
                    marginLeft:"15px",
                    }}
                    onClick={() => {
                      handleReportBug();
                    }}
                  />
          </Col> */}
          <Col
            // flex={loggedIn ? "0 1 100px" : "0 1 200px"}
            xs={3}
            sm={1}
            md={7}
            lg={8}
            xl={6}
            style={{display:"flex",alignItems:"center",textAlign:"right",justifyContent:"space-around" }}
            // className={styles.ReportBugAlignment}
          >
            <div style={{marginTop:"2%",color:"white"}}>
              <span><Help tabName={tabName}/></span>
            </div>
            {/* {renderComp === "listings" ? <Button
              style={{
                marginRight: "-5%",
                background: "#1890ff",
                color: "#ffffff",
                border:"none"
              }}
              onClick={handleReportBug}
            >
              Report Bug
            </Button> : null} */}
            {!getKey ? (
              <Link to="/bugsLogin">
                <Button
                  className={styles.avatarSmallScreen}
                  style={{
                    background: "rgb(144, 182, 195)",
                    color: "#ffffff",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                >
                  Login
                </Button>
              </Link>
            ) : (
              <div className={styles.avatarSmallScreen}>
                <Text
                  key="7"
                  style={{
                    color: "#fff",
                    // color: "#858796 !important",
                    marginRight: "10px",
                  }}
                >
                  {getUserDetailsData?.delegateId?.fullName
                ? `${getUserDetailsData?.delegateId?.fullName} logged in as `
                : ""} <span style={{ fontWeight: 500, fontSize: "18px" }}>{userName}</span>
                </Text>
                <Dropdown key="4" overlay={menu} trigger={["click", "hover"]}>
                {getUserDetailsData &&
                getUserDetailsData?.profileImg &&
                getUserDetailsData?.profileImg?.original
                  ? <Avatar
                  key="5"
                  src={getUserDetailsData?.profileImg?.original}
                  style={{ cursor: "pointer",marginLeft:"-5px" }}
                  size="large"
                /> : <Avatar key="5" style={{ cursor: "pointer",marginLeft:"-5px",color:"#085190",border:"1px solid #085190",fontSize:"18px" }}
                  size="large">
                {generateInitialsFromObj(getUserDetailsData)}
                </Avatar>
                }
                </Dropdown>
              </div>
            )}
          </Col>
        </Row>
      </Header>
    </>
  );
}
export { Navbar };
