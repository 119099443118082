import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button } from "antd";
import { clientAction } from "../../Client/state/actions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function PhoneDetails({ data, selectedClient, setModelDrawer, section }) {
  const dispatch = useDispatch();
  const inputRef = useRef()
  const { updatePhoneDetails } = useSelector(
    (state) => state.client.phoneReducer
  );

  if (updatePhoneDetails !== null) {
    dispatch(clientAction.reSetPhone());
  }

  const onFinish = (values) => {
    dispatch(clientAction.updatePhone(values, data, selectedClient, section,"ACCOUNT"));
    setModelDrawer({ status: false, component: "", title: "" });
  };
  const init =
    data.mobilePhoneNumber?.phoneNumber && data.mobilePhoneNumber.phoneNumber.length === 10
      ? data.mobilePhoneNumber.phoneNumber
      : "";
  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 15 }}
      initialValues={{
        phoneNumber: init ? init : "",
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Phone Number"
        name="phoneNumber"
        hasFeedback
        rules={[
          { required: true, message: "" },
          { whitespace: true },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value?.length === 10) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("Please Enter 10 digit Phone Number!")
              );
            },
          }),
        ]}
      >
        <PhoneInput
          ref={inputRef}
          autoFocus
          inputClass="phoneInput"
          country={"us"}
          onlyCountries={["us"]}
          maxLength="10"
          international={false}
          disableCountryCode={true}
          placeholder="Enter Phone Number"
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
export { PhoneDetails };
