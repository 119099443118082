import { message } from "antd";
import { AppDataApi } from "../utils/api";
import { appConstants } from "./action-types";
import { detectWebView } from "../../Common/utils/extraFunctions";
// import axios from "axios";
// import dayjs from "dayjs";

// const apiURL = process.env.REACT_APP_BASEURL;

/**
 * All Actions for App
 */

/**
 * To update Navbar title action
 * @param {string} navbar_title
 */

const setmanualLoadingFun = (data) => {
  return {
    type: appConstants.SET_MANUAL_LOADING,
    manualLoading: data,
  };
};
const deviceTokenFun = (data) => {
  return {
    type: appConstants.DEVICE_TOKEN_SUCCESS,
    deviceTokenData: data,
  };
};

const demoModeToken = (data) => {
  return {
    type: appConstants.DEMO_MODE_SUCCESS,
    demoMode: data,
  };
};

const updatedeviceToken = (data) => {
  return (dispatch, updateState) => {
    AppDataApi.updateDeviceTokenApi(data)
      .then((response) => {
        // dispatch(updateNotificationSucess(response));
      })
      .catch((error) => {
        // dispatch(updateNotificationError(error));
      });
  };
};

const updateNavbarTitle = (text) => {
  return {
    type: appConstants.UPDATE_APP_TITLE,
    appTitle: text,
  };
};
const getNotificationLoading=()=>{
  return {
    type:appConstants.NOTIFICATION_LOADING
    }
  }

const getNotificationError = (error) => {
  return {
    type: appConstants.NOTIFICATION_FAILURE,
    getNotificationError: error,
    getNotificationLoading: false,
  };
};


const getNotificationSucess = (response,source) => {

  return {
    type: appConstants.NOTIFICATION_SUCCESS,
    source:source,
    getNotificationData: response.data.info,
    getNotificationLoading: false,

  };
};

const getNotificationtData = (page,source) => {

  return (dispatch, getState) => {
    dispatch(getNotificationLoading())
    AppDataApi.getNotification(page)
      .then((response) => {
        dispatch(getNotificationSucess(response,source));
      })
      .catch((error) => {
        dispatch(getNotificationError(error));
      });
  };
};
// NOTIFICATION DELETE
const deleteNotificationError = (error) => {
  return {
    type: appConstants.DELETE_NOTIFICATION_COUNT_FAILURE,
    deleteNotificationError: error,
    deleteNotificationLoading: false,
  };
};

const deleteNotificationSucess = (response) => {

  return {

    type: appConstants.DELETE_NOTIFICATION_COUNT_SUCCESS,

    deleteNotificationData: response.data.info,
    deleteNotificationLoading: false,
  };
};

const deleteNotificationtData = (data) => {
  return (dispatch, deleteState) => {
    AppDataApi.deleteNotification(data)
      .then((response) => {
        dispatch(deleteNotificationSucess(response));
        dispatch(getNotificationtData(1,"delete"))
        // dispatch(appStatusChange.getNotificationtCountData());
        message.success("Deleted Successfully!");
      })
      .catch((error) => {
        dispatch(deleteNotificationError(error));
        message.error(
          error?.response?.data?.message || "We encountered an issue while deleting notification data. Please try again later!"
        );
      });
  };
};

/// NOTIFICATION
const getNotificationCountError = (error) => {
  return {
    type: appConstants.NOTIFICATION_COUNT_FAILURE,
    getNotificationCountError: error,
    getNotificationCountLoading: false,
  };
};

const getNotificationCountSucess = (response) => {
  return {
    type: appConstants.NOTIFICATION_COUNT_SUCCESS,
    getNotificationCountData: response.data.info,
    getNotificationCountLoading: false,
  };
};

const getNotificationtCountData = () => {
  return (dispatch, getState) => {
    AppDataApi.getNotificationCount()
      .then((response) => {
        dispatch(getNotificationCountSucess(response));
      })
      .catch((error) => {
        dispatch(getNotificationCountError(error));
      });
  };
};

const updateNotificationError = (error) => {
  return {
    type: appConstants.UPDATE_NOTIFICATION_FAILURE,
    updateNotificationError: error,
    updateNotificationLoading: false,
  };
};

const updateNotificationSucess = (response) => {
  return {
    type: appConstants.UPDATE_NOTIFICATION_SUCCESS,
    updateNotificationData: response.data.info,
    updateNotificationLoading: false,
  };
};

const updateNotificationFun = (data={}) => {
  return (dispatch, updateState) => {
    AppDataApi.updateNotificationApi(data)
      .then((response) => {
        dispatch(updateNotificationSucess(response));
        // dispatch(getNotificationtData());
      })
      .catch((error) => {
        dispatch(updateNotificationError(error));
      });
  };
};

// location
const getLocationError = (error) => {
  return {
    type: appConstants.LOCATION_API_FAILURE,
    getLocationError: error,
    getLocationLoading: false,
  };
};

const getLocationSucess = (response) => {
  return {
    type: appConstants.LOCATION_API_SUCCESS,
    getLocationData: response,
    getLocationLoading: false,
  };
};
// for getting the user's location
const loacionApiCalling = (data) => {
  return (dispatch, updateState) => {
    AppDataApi.getLocation(data)
      .then((response) => {
        dispatch(getLocationSucess(response.data.results));
      })
      .catch((error) => {
        dispatch(getLocationError(error));
      });
  };
};
////// RETS data

const retsDataError = (error) => {
  return {
    type: appConstants.LOCATION_API_FAILURE,
    retsDataError: error,
    retsDataLoading: false,
  };
};

const retsDataSucess = (response) => {
  return {
    type: appConstants.LOCATION_API_SUCCESS,
    retsDataData: response,
    retsDataLoading: false,
  };
};

const retsApiCalling = (data) => {
  return (dispatch, updateState) => {
    AppDataApi.retsData(data)
      .then((response) => {
        dispatch(retsDataSucess(response.data.info));
      })
      .catch((error) => {
        dispatch(retsDataError(error));
      });
  };
};

//Help
const getHelpSuccess = (response) => {
  return {
    type: appConstants.HELP_DATA_SUCCESS,
    helpData: response,
    helpDataLoading: false,
  };
}

const getHelpLoading = () => {
  return {
    type: appConstants.HELP_DATA_LOADING,
    helpDataLoading: true,
  };
}

const getHelpError = (error) => {
  return {
    type: appConstants.HELP_DATA_ERROR,
    helpDataError: error,
    helpDataLoading: false,
  };
}

const helpApiCalling = (data,search) => {
  return (dispatch) => {
    dispatch(getHelpLoading())
    AppDataApi.getHelpData(data,search)
      .then((response) => {
        dispatch(getHelpSuccess(response.data.info.data));
      })
      .catch((error) => {
        dispatch(getHelpError(error));
      });
  };
}

const setClientscreenLogoutFun = (data) => {
    return {
      type: appConstants.CLIENT_SCREEN_LOGOUT,
      clientScreenLogout: true,
    };
  };

const handleCustomerDesk = (data) => {
  return (dispatch) => {
    dispatch(setClientDesk(appConstants.CLIENT_DESK_LOADING, "Please do not close the window"));
    AppDataApi.updateCustomerSubscription(data)
      .then((response)=>{
        message.success(response?.data?.info?.message);
        dispatch(setClientDesk(appConstants.CLIENT_DESK_SUCCESS, response?.data?.info?.message || ""));
        if (data?.action === "CANCEL") {
          if (data.deleteAccount) {
            setTimeout(() => {
              window.location.href = `/login`;
            }, 2000);
          } else {
            setTimeout(() => {
              window.location.href = `/`;
            }, 2000);
          }
        }
        else if(data?.action === "FAILED"){
          window.location.href = response?.data?.info?.sessionUrl;
        }
        else if(data?.action === "UPDATE"){
          window.location.href = response?.data?.info?.sessionUrl;
        }
      })
      .catch((error)=> {
        const errorMessage = error?.response?.data?.message || "We encountered an issue while handling customer desk data. Please try again later!";
        message.error(errorMessage);
        dispatch(setClientDesk(appConstants.CLIENT_DESK_ERROR, errorMessage));
      })
  }
}

const setClientDesk = (type, message) => {
  return {
    type: type,
    message
  };
};

const loadOpacityLoader = (value) => {
  return {
    type: appConstants.LOAD_OPACITY_LOADER,
    loadOpacityLoader: value,
  };
};

export const appStatusChange = {
  updateNavbarTitle,
  getNotificationtData,
  updateNotificationFun,
  deviceTokenFun,
  updatedeviceToken,
  demoModeToken,
  getNotificationtCountData,
  deleteNotificationtData,
  loacionApiCalling,
  retsApiCalling,
  helpApiCalling,
  setmanualLoadingFun,
  setClientscreenLogoutFun,
  handleCustomerDesk,
  loadOpacityLoader
};
