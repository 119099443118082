import { userSignUpConstants } from "./action-types";
import { userDataApi } from "../utils/api";
import { message } from "antd";
import { logoutAndInWithBroadcast } from "../../../App/components/Broadcast";

/**
 * All Actions for Admin
 */

/**
 * To update Admin state
 * @param {string} auth
 */
const userSignupLoading = () => {
  return {
    type: userSignUpConstants.USER_SIGN_UP_LOADING,
  };
};
const signUpError = (error) => {
  return {
    type: userSignUpConstants.USER_SIGN_UP_FAILURE,
    userSignupError: JSON.stringify(error.message),
    userSignupLoading: false,
  };
};

const signUpSuccess = (response) => {
  return {
    type: userSignUpConstants.USER_SIGN_UP_SUCCESS,
    userAuth: response.data.info.accessToken,
    userSignupData: response.data.info,
    userSignupLoading: false,
  };
};

const personSignUp = (data) => {
  return (dispatch, getState) => {
    dispatch(userSignupLoading());
    userDataApi
      .personSignUpRequest(data)
      .then((response) => {
        localStorage.clear();
        dispatch(signUpSuccess(response));
        message.success("Signup Successfully!");
      })
      .catch((error) => {
        dispatch(signUpError(error));
        message.error("Email already exists!");
      });
  };
};

const logInLoading = () => {
  return {
    type: userSignUpConstants.USER_LOG_IN_LOADING,
  };
};
const logInError = (error) => {
  return {
    type: userSignUpConstants.USER_LOG_IN_FAILURE,
    userLogInError: error,
    userLogInLoading: false,
  };
};

const logInSuccess = (loginResponse) => {
  localStorage.setItem("userAuth", loginResponse.accessToken);
  document.cookie = `userAuth=${loginResponse.accessToken}; domain=.resure.realestate; path=/;`;
  return {
    type: userSignUpConstants.USER_LOG_IN_SUCCESS,
    roleType: loginResponse.roles[0],
    userSessionId:loginResponse._id,
    userAuth: loginResponse.accessToken,
    userLogInData: loginResponse,
    userLogInLoading: false,
  };
};

const userLogin = (data) => {
  return (dispatch, getState) => {
    dispatch(logInLoading());
    userDataApi
      .userLogInApiRequest(data)
      .then((response) => {
        let loginResponse = response.data.info
        dispatch(logInSuccess(loginResponse));
        logoutAndInWithBroadcast();
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "We encountered an issue while logging. Please try again later!");
        dispatch(logInError(error));
      });
  };
};

const userForgetPasswordLoading = () => {
  return {
    type: userSignUpConstants.USER_FORGET_PASSWORD_LOADING,
  };
};

const userForgetPasswordError = (error) => {
  return {
    type: userSignUpConstants.USER_FORGET_PASSWORD_FAILURE,
    userForgetPasswordError: error,
    userForgetPasswordLoading: false,
  };
};

const userForgetPasswordSuccess = (response, email) => {
  return {
    type: userSignUpConstants.USER_FORGET_PASSWORD_SUCCESS,
    userForgetPasswordData: response.data.statusCode,
    userForgetPasswordDataEmail: email,
    userForgetPasswordLoading: false,
  };
};

const userForgetPasswordClear = () => {
  return {
    type: userSignUpConstants.USER_FORGET_PASSWORD_CLEAR,
    userForgetPasswordData: null,
    userForgetPasswordDataEmail: null,
    userForgetPasswordLoading: true,
  };
};

const userForgetPassword = (data) => {
  return (dispatch, getState) => {
    dispatch(userForgetPasswordLoading());
    userDataApi
      .userForgetPasswordRequest(data)
      .then((response) => {
        dispatch(userForgetPasswordSuccess(response, data.email));
      })
      .catch((error) => {
        dispatch(userForgetPasswordError(error));
      });
  };
};

const userForgetPasswordCodeVerificationLoading = () => {
  return {
    type: userSignUpConstants.FORGET_PASSWORD_VERIFICATION_LOADING,
  };
};
const userForgetPasswordCodeVerificationError = (error) => {
  return {
    type: userSignUpConstants.FORGET_PASSWORD_VERIFICATION_FAILURE,
    verificationError: error,
  };
};

const userForgetPasswordCodeVerificationSuccess = (response) => {
  return {
    type: userSignUpConstants.FORGET_PASSWORD_VERIFICATION_SUCCESS,
    verificationData: response,
  };
};

const userForgetPasswordCodeVerification = (data, email) => {
  return (dispatch, getState) => {
    dispatch(userForgetPasswordCodeVerificationLoading());
    userDataApi
      .userForgetPasswordCodeVerificationRequest(data, email)
      .then((response) => {
        dispatch(userForgetPasswordCodeVerificationSuccess(response));
      })
      .catch((error) => {
        dispatch(userForgetPasswordCodeVerificationError(error));
      });
  };
};

const resetPasswordSaveNewPasswordLoading = () => {
  return {
    type: userSignUpConstants.RESET_PASSWORD_SAVE_NEW_PASSWORD_LOADING,
  };
};
const resetPasswordSaveNewPasswordError = (error) => {
  return {
    type: userSignUpConstants.RESET_PASSWORD_SAVE_NEW_PASSWORD_FAILURE,
    resetPasswordNewPasswordError: error,
  };
};

const resetPasswordSaveNewPasswordSuccess = (response) => {
  return {
    type: userSignUpConstants.RESET_PASSWORD_SAVE_NEW_PASSWORD_SUCCESS,
    resetPasswordNewPasswordData: response,
  };
};

const resetPasswordSaveNewPassword = (data, email) => {
  return (dispatch, getState) => {
    dispatch(resetPasswordSaveNewPasswordLoading());
    userDataApi
      .resetPasswordSaveNewPasswordRequest(data, email)
      .then((response) => {
        dispatch(resetPasswordSaveNewPasswordSuccess(response));
      })
      .catch((error) => {
        dispatch(resetPasswordSaveNewPasswordError(error));
      });
  };
};

const resetPasswordCleanUp = () => ({
  type: userSignUpConstants.RESET_PASSWORD,
});

const resetAlert = () => ({
  type: userSignUpConstants.RESET_ALERT,
});

const logOut = (history) => (dispatch) => {
  userDataApi
    .userLogOutApiRequest()
    .then((r) => {
      dispatch(LogoutSuccess());
      history.push("/logout");
      message.success("Logout successfully!");
      logoutAndInWithBroadcast();
    })
    .catch((error) => {
      message.error(error?.response?.data?.message || "We encountered an issue while doing logout. Please try again later!");
    });
};
const LogoutSuccess = () => {
  return {
    type: userSignUpConstants.LOGOUT_ACTION,
    authLoading: true,
    username: null,
    userAuth: null,
  };
};

const resetError = () => {
  return {
    type: userSignUpConstants.RESET_ERROR,
  };
};
const resetLogout = () => {
  return {
    type: userSignUpConstants.RESET_LOGOUT,
  };
};

const getAssociationDataLoading = () => {
  return {
    type:userSignUpConstants.ASSOCIATION_LOADING,
  }
}

const getAssociationDataError = (err) => {
  return {
    type:userSignUpConstants.ASSOCIATION_ERROR,
    associationDataError : err,
  }
}

const getAssociationDataSuccess = (data) => {
  return {
    type:userSignUpConstants.ASSOCIATION_SUCCESS,
    associationData : data,
  }
}

const getAssociationData = () => {
  return (dispatch,getState) => {
    dispatch(getAssociationDataLoading())
    userDataApi.getAssociationData()
    .then((response) => {
      dispatch(getAssociationDataSuccess(response.data.info));
    })
    .catch((err) => dispatch(getAssociationDataError(err)))
  }
}

const getPriceDataLoading = () => {
  return {
    type:userSignUpConstants.GET_PRICES_LOADING,
  }
}

const getPricesDataError = (err) => {
  return {
    type:userSignUpConstants.GET_PRICES_ERROR,
    getPricesDataError: err,
  }
}

const getPricesDataSuccess = (data) => {
  return {
    type:userSignUpConstants.GET_PRICES_SUCCESS,
    getPricesData : data,
  }
}

const getPriceDetailsData = () => {
  return (dispatch,getState) => {
    dispatch(getPriceDataLoading())
    userDataApi.getPriceDetails()
    .then((res) => {
      dispatch(getPricesDataSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getPricesDataError(err))
    })
  }
}

export const userSignUpAction = {
  personSignUp,
  userLogin,
  logOut,
  userForgetPassword,
  userForgetPasswordCodeVerification,
  resetPasswordSaveNewPassword,
  resetPasswordCleanUp,
  resetError,
  resetLogout,
  resetAlert,
  LogoutSuccess,
  userForgetPasswordClear,
  getAssociationData,
  getPriceDetailsData,
};
