import React, { useState, useEffect} from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
// import { useLocation } from "react-router-dom";
import { Layout, Col, Row, Button, Modal, Form, Input, Select, Spin } from "antd";
import { TreeSelector } from "../DocumentSection/components/TreeSelector";
import { EachCard } from "./components/EachCard";
// import { breadcrumHandle } from "../Breadcrum/state/actions";
import { templateAction } from "./state/actions";
import { documentAction } from "../DocumentSection/state/actions";
// import { NodataFound } from "../Common/components/NodataFound";
import styles from "./styles/Template.module.css";
import { EmptyData } from "../Common/components/EmptyData";
import { Loading } from "../Common/components/Loading";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const { Content } = Layout;
const { Option } = Select;
// const { TabPane } = Tabs;

function Template({categoryRef}) {
  const [showModal, setshowModal] = useState(false);
  const [form] = Form.useForm();
  const { userId } = useSelector((state) => state.account);
  const location = useLocation();
  const { getCategoryData, getCategoryLoading } = useSelector(
    (state) => state.documentSection.categoryReducer,
    shallowEqual
  );
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 20,
      },
      sm: {
        span: 20,
      },
      lg: {
        span: 15
      },
      xl: {
        span: 15
      },
    },
  };

  const handleOk = (values) => {
    if (values?.propertyType) {
      values = {
        ...values,
        propertyType: JSON.parse(values?.propertyType),
      }
    }

    if (!values.propertyType || !values.propertyType.contractId) {
      return;
    }

    let buildData = {
      builtForId: userId,
      builtForSection: "TEMPLATE",
      signable: true,
      openFor: "BUYER_AGENT",
      contractType: values?.propertyType?.contractType,
      token: localStorage.getItem("userAuth"),
      contractId: values?.propertyType?.contractId,
      createOrEditTemplate: true,
      ...(values?.title?.length > 0 ? {templateName:values.title} : {}),
      ...(values?.description?.length > 0 ? {templateDescription:values.description} : {}),
    };
    dispatch(templateAction.templateOffer(buildData,location?.pathname, true));
    setshowModal(!showModal);
  };

  const handleCancel = () => {
    setshowModal(false);
    form.resetFields();
  };
  // let space = <br />;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!categoryRef.current) {
      dispatch(templateAction.gettemplateData());
      // dispatch(documentAction.getDocumentData());
      categoryRef.current = true;
    }
  }, []);


  const { templateData, templateLoading } = useSelector(
    (state) => state.template
  );
  return (
    <>
      <Layout style={{ width: "100%", height: "100%", padding: "0px" }}>
        <Content>
          <Row style={{ display: "flex" }}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{
                paddingLeft: "6px",
                paddingRight: "6px",
              }}
            >
              <Modal
                visible={showModal}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
                title={<p style={{fontSize:"22px", textAlign:"center", color:"#178DFA"}}>CREATE TEMPLATE</p>}
              >
                <Form onFinish={handleOk} {...formItemLayout} form={form}>
                  {/* <Row gutter={16}>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      style={{ marginTop: "2rem" }}
                    > */}
                      <Form.Item
                        className="lbl"
                        name="propertyType"
                        label="Property Type"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Property Type!",
                          },
                        ]}
                        onFocus={()=>{
                          dispatch(documentAction.getDocumentData());
                        }}
                      >
                        {/* <TreeSelector
                          setPropertyType={setPropertyType}
                          data={getCategoryData}
                          onChangeValue={(value) => {
                            if (value) {
                              setPropertyTypeError(false);
                            } else {
                              setPropertyTypeError(true);
                            }
                          }}
                          setPropertyTypeError={setPropertyTypeError}
                        /> */}
                        <Select
                          showSearch
                          placeholder="Select Property Type"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          allowClear
                        >
                          { getCategoryLoading || getCategoryLoading === null ?
                            <div style={{ minHeight: "20vh", display: "flex", alignItems: "center", textAlign: "center" }}>
                              <Spin size="medium" />
                            </div> : 
                            getCategoryData?.map((element, index) => (
                            <Option key={element.contractId} value={JSON.stringify(element)} disabled={element.isDisabled}>
                              {element.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    {/* </Col>
                  </Row>
                  <Row>
                  <Col
                      xs={20}
                      sm={20}
                      md={20}
                      lg={20}
                      xl={20}
                    > */}
                      <Form.Item
                        className="lbl"
                        name="title"
                        label="Title"
                        rules={[
                          {
                            required: true,
                            message: "Please add title!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Title" />
                      </Form.Item>
                      {/* </Col>
                  </Row>
                  <Row>
                  <Col
                      xs={22}
                      sm={22}
                      md={22}
                      lg={22}
                      xl={22}
                    > */}
                      <Form.Item
                        className="lbl"
                        name="description"
                        label="Description"
                        rules={[
                          {
                            required: true,
                            message: "Please add description!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Description" />
                      </Form.Item>
                      {/* </Col>
                  </Row> */}
                  <Row gutter={16} justify="end">
                    <Col>
                      <Button default onClick={handleCancel}>
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Create Template
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
              {templateLoading && (
                <div style={{ margin: "200px 500px" }}>
                  <Loading />
                </div>
              )}
              {/* Button to create templete */}
              <div
                className={styles.buttonContainer}
                style={{ display: templateLoading ? "none" : "contents" }}
              >
                <Button
                  onClick={() => setshowModal(true)}
                  type="primary"
                 className={styles.templatebutton}
                >
                  Create Template
                </Button>

                <Button
                  onClick={() => {}}
                  type="primary"
                  style={{
                    margin: "1rem",
                  }}
                  disabled
                >
                  Import Template
                </Button>
              </div>
              <div
                style={{
                  padding: "3px 3px 0 3px",
                  width: "100%",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  transition: "0.3s",
                  height: "calc(100vh - 11.6rem)",
                  msOverflowY: "auto",
                  display: templateLoading ? "none" : "contents",
                }}
              >
                {/* <Button
                onClick={() => setshowModal(true)}
                type="primary"
                style={{
                  margin: "1rem",
                  // position:"absolute",
                  // top:"4px",
                  // left:"100px"
                }}
              >
                Create Template
              </Button>

              <Button
                onClick={() => {}}
                type="primary"
                style={{
                  margin: "1rem",
                  // position:"absolute",
                  // top:"4px",
                  // left:"180px",
                }}
                disabled
              >
                Import Template
              </Button> */}

                {templateData.length > 0 ? (
                  <EachCard data={templateData} />
                ) : (
                  <EmptyData />
                )}
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}

export { Template };
