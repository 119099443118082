import React from "react";
import "../styles/main.css";
const Board = (props) => {
  const drop = (e) => {
    e.preventDefault(props);
    let element = e.dataTransfer.getData("text/plain");
    element = element.split("-");
    if (element[0] == "input") {
      if (element[0] !== "") {
        const node = document.createElement(element[0]);
        node.setAttribute("type", element[1]);
        const span = document.createElement("span");
        span.appendChild(node);
        e.target.appendChild(span);
      }
    } else if (element[0] == "div") {
      const node = document.createElement("div");
      node.innerText = "Signature";
      node.setAttribute("class", element[1]);
      node.style.top = e.pageX;
      node.style.left = e.pageY;
      const span = document.createElement("span");
      span.appendChild(node);
      e.target.appendChild(span);
    }
  };

  const dragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  return (
    <div
      id={props.id}
      className={props.className}
      style={props.style}
      onDrop={drop}
      onDragOver={dragOver}
      draggable="true"
    >
      {props.children}
    </div>
  );
};

export default Board;
