import { Avatar, Carousel, Modal, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { DocumentSign } from "./DocumentSign";
import { EmailFile } from "./EmailFile";
import { useDispatch } from "react-redux";
import { transactionLaneDataHandle } from "../state/actions";
import TabPane from "antd/lib/tabs/TabPane";
import rightArrow from "../../Assets/rightArrow.png";
import leftArrow from "../../Assets/leftArrow.png";
import { useMediaQuery } from "react-responsive";

const ClientPortaModal = ({
  showmodal,
  setshowModal,
  selectedData,
  Role,
  offerid,
  thirdpartyIdDoc,
  ertsId,
  personId
}) => {
  const [activeTabKey, setActiveTabKey] = useState("1");
  const dispatch = useDispatch();
  const [highlight, setHeighlight] = useState(firstSeller());

  const isMobile = useMediaQuery({maxWidth: 545});
  const isTab = useMediaQuery({minWidth: 546 ,maxWidth: 760});
  const isLarge = useMediaQuery({minWidth: 761 ,maxWidth: 990});

  const spot1 = useMediaQuery({minWidth: 420, maxWidth: 540});
  const spot2 = useMediaQuery({minWidth: 615, maxWidth: 759});
  const spot3 = useMediaQuery({minWidth: 870, maxWidth: 990});

  function firstSeller(){
    const firstSeller = selectedData?.[0];
    if(firstSeller?.isCorp){
      return {
        idx: "00",
        key: firstSeller?.key,
        ...(firstSeller?.signers?.[0]?.personId || {})
      }
    }
    else{
      return {
        idx: "0",
        ...(firstSeller?.personId || {})
      }
    }
  }

  const buildQuery = (highlight) => {
    return  {
      ...(offerid ? { offerId: offerid } : {}),
      ...(ertsId ? { ertsId: ertsId } : {}),
      ...(thirdpartyIdDoc ? { thirdpartyIdDoc: thirdpartyIdDoc } : {}),
      ...(Role ? { role: Role } : {}),
      ...(highlight?.key ? { key: highlight?.key } : { key: highlight?._id }),
      ...(highlight?._id ? { _id: highlight?._id } : {}),
    };
  }

  useEffect(() => {
    setActiveTabKey("1");
    let query = buildQuery(highlight);
    dispatch(transactionLaneDataHandle.ClientPortalData(query));
  }, [highlight]);

  const handleTabChange = (ele) => {
    setActiveTabKey(ele);
    if(ele === "1"){
      let query = buildQuery(highlight);
      dispatch(transactionLaneDataHandle.ClientPortalData(query));
    }
    else {
      let query = {
        ...(offerid ? { offerId: offerid } : {}),
        ...(ertsId ? { ertsId: ertsId } : {}),
        ...(thirdpartyIdDoc ? { thirdpartyIdDoc: thirdpartyIdDoc } : {}),
        ...(Role ? { role: Role } : {}),
        ...(highlight?._id ? { _id: highlight?._id } : {}),
      };
      const params = {
        ...(offerid ? { offerId: offerid } : {}),
        ...(ertsId ? { ertsId: ertsId } : {}),
        ...(thirdpartyIdDoc ? { thirdpartyIdDoc: thirdpartyIdDoc } : {}),
        openFor: Role,
        ...(highlight?._id ? { personId: highlight?._id } : {}),
      };
      dispatch(transactionLaneDataHandle.getPropertyDocsFun(params));
      dispatch(transactionLaneDataHandle.EmailFileData(query));
    }
  }

  let clientCount = isMobile ? 1 : isTab ? 2 : isLarge ? 3 : 4;
  let multipleFour = 0;
  const settings = {
    nextArrow: <img src={rightArrow}/>,
    prevArrow: <img src={leftArrow}/>
  };

  let arrayTemp = [];
  return (
    <Modal
      visible={showmodal}
      onOk={() => {}}
      footer={false}
      width={"960px"}
      style={{ marginTop: "-75px" }}
      onCancel={() => setshowModal(false)}
    >
      <div
        style={{
          textAlign: "center",
          color: "#888888",
          overFlow: "auto",
          height: "85vh",
          fontSize: "18px",
          padding: "5px",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
          borderBottomLeftRadius: "5px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>Client Portal</div>
        <div
          style={{
            padding: "0px 30px"
          }}
        >
          <Carousel dots={false} arrows {...settings}>
            {/* made rendering corp specific with added logic */}
            {
              selectedData?.map((seller, index) => {
                let endOfSeller = (index === (selectedData.length - 1))
                if(seller?.isCorp){
                  return seller?.signers?.map((signer, idx)=> {
                    let endOfSigner = (idx === (seller?.signers?.length - 1))
                    arrayTemp[multipleFour%clientCount] =  profileView({profile: signer, corpName: seller?.fullName, idx: (index + "" + idx), key: seller?.key, highlight, setHeighlight,isMobile,spot1,spot2,spot3})
                    ++multipleFour;
                    if((multipleFour%clientCount == 0) || (endOfSeller && endOfSigner && ((multipleFour%clientCount)>0))){
                      let renderArray = [...arrayTemp];
                      arrayTemp = [];
                      return <div><div style={{display: "flex", flexDirection: "row"}}>{renderArray.map((ele)=>ele)}</div></div>
                    }
                  })
                }
                else{
                  arrayTemp[multipleFour%clientCount] = profileView({profile: seller, idx: (index + ""), highlight, setHeighlight,isMobile,spot1,spot2,spot3});
                  ++multipleFour;
                  if((multipleFour%clientCount == 0) || (endOfSeller && ((multipleFour%clientCount)>0))){
                    let renderArray = [...arrayTemp];
                    arrayTemp = [];
                    return <div><div style={{display: "flex", flexDirection: "row"}}>{renderArray.map((ele)=>ele)}</div></div>
                  }
                }
              })
            }
          </Carousel>
        </div>
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", width: "100%", fontSize: "15px" }}>
            <Tabs
              activeKey={activeTabKey}
              onChange={handleTabChange}
              defaultActiveKey="1"
              style={{ width: "100%", height: "calc(100vh - 8.4rem)" }}
              className="centered-tabs"
              tabBarGutter={10}
            >
              <TabPane
                tab="Document Signing"
                key="1"
                style={{ width: "100%", height: "100%", paddingTop: "15px" }}
              >
                <DocumentSign
                  id={selectedData?.[0]?.personId?._id}
                  offerIds={offerid}
                  role={Role}
                  thirdpartyIdDoc={thirdpartyIdDoc}
                  ertsId={ertsId}
                />
              </TabPane>
              <TabPane
                tab="Email & Files"
                key="2"
                style={{ width: "100%", height: "100%", fontSize: "18px" }}
              >
                <EmailFile
                  id={selectedData?.[0]?.personId?._id}
                  offerIds={offerid}
                  role={Role}
                  thirdpartyIdDoc={thirdpartyIdDoc}
                  ertsId={ertsId}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const profileView = ({profile, corpName, idx, key, highlight, setHeighlight,isMobile,spot1,spot2,spot3}) => {
  const initials = (profile?.personId?.firstName ? (profile?.personId?.firstName?.[0])?.toUpperCase() : "") + (profile?.personId?.lastName ? (profile?.personId?.lastName?.[0])?.toUpperCase() : "");
  const handleclientPortal = () => {
    setHeighlight({
      idx,
      ...(profile?.personId || {}),
      ...(key ? {key} : {})
    })
  };

  return <div key={idx}>
    <div
      style={{
        width: spot3 ? "250px" : spot2 ? "250px" : spot1 ? "300px" : isMobile ? "230px" : "206px", // Adjust the width as needed
        height: "70px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        cursor: "pointer",
        padding: "8px", // Adjust the padding as needed
        margin: "2px", // Adjust the margin as needed
        display: "flex",
        alignItems: "center",
        backgroundColor: (highlight?.idx === idx) ? "#96c8f6" : "white"
      }}
      onClick={() => handleclientPortal()}
    >
      {profile?.personId?.profileImg?.original &&
      profile?.personId?.profileImg?.original !==''?
      <Avatar
        src={profile?.personId?.profileImg?.original}
        style={{
          backgroundColor: "#0078d4",
          borderRadius: "50%",
          objectFit:"cover"
        }}
        size={40}
      /> :
      <Avatar
        src={null}
        style={{
            backgroundColor: "#0078d4",
            borderRadius: "50%",
        }}
        size={40}
      >
        {initials}
      </Avatar>
      }
      <div style={{ flex: 1 }}>
        <div
          style={{
            overflow: "hidden",
            textAlign: "left",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "140px",
            fontWeight: "500",
            color: "#333",
            fontSize: "16px",
            marginLeft: "10px",
          }}
        >
          {profile?.fullName}
        </div>
        {
          corpName ?
          <div
            style={{
              overflow: "hidden",
              textAlign: "left",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "120px",
              fontSize: "16px",
              color: "#555",
              marginLeft: "10px",
            }}
         >
           {corpName}
         </div> : ""
        }
      </div>
    </div>
  </div>
}

export { ClientPortaModal };
