/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {  Collapse,  Spin, } from 'antd';
import { CaretRightOutlined } from "@ant-design/icons"
import ProfileDetails from './ProfileDetails';
import PanelComp from './PanelComp';
import { PropertyHandlers } from '../state/actions';
import { useDispatch, useSelector } from 'react-redux';
import styles from "../styles/profileDetails.module.css";
import PropertyNavbar from './PropertyNavbar';
import axios from 'axios';

import { useLocation, useHistory } from "react-router-dom";

// section ------>
// LIMITED_TRANSACTION -> DELAGATE LIMITED TRANSACTION SEARCH
// DELEGATE -> DELEGATE TRANSACTION SECTION

const  Transactions = ({section, setSelectedTransaction, selectedTransaction, readOnly}) => {
  const delegateAndLimited = (section === "DELEGATE") || (section === "LIMITED_TRANSACTION");
  const limited =  (section === "LIMITED_TRANSACTION");
  const delegate =  (section === "DELEGATE");

  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  const history = useHistory();
  const { Panel } = Collapse;
  const [activeKey, setActiveKey] = useState("0");
  const { propertyData, propertyDataLoading, propertyDataError, } = useSelector(state=>state.PropertyReducer);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [count, setCount] = useState({});
  const query = new URLSearchParams(window.location?.search);
  const status = query?.get("status");
  const {filterSort} = useSelector((state) => state.PropertyReducer);

  useEffect(() => {
    if ((status || state?.status) && !filterSort?.statusSort?.includes(status || state?.status)) {
      dispatch(PropertyHandlers.changeFilterSort({
        ...filterSort,
        statusSort: [...filterSort?.statusSort, status || state?.status]
      }));
    }
  }, [status, state])

  let unmounted = {
    value: false
  };
  let sourceAxios = axios.CancelToken.source();
  useEffect(() => {
    let category = query?.get("category");
    let categoryId = query?.get("categoryId");
    if(!(category && categoryId)){
      const highlightCheck = ((count?.categoryId !== state?.categoryId) || (count?.category !== state?.category));
      dispatch(PropertyHandlers.getPropertyData({type: delegate ? "DELEGATE_TRANSACTIONS" : "TRANSACTIONS", filterSort, unmounted, sourceAxios, category: highlightCheck ? state?.category : "", categoryId: highlightCheck ? state?.categoryId : "", readOnly}));
      if(state?.category || state?.categoryId){
        setCount({
          ...state
        });
      }
    }
    else{
      history.push({
        pathname: pathname,
        state: {
          category: category, categoryId: categoryId
        }
      })
    }

    setActiveKey("0");
    window.history.replaceState({}, document.title);
    return () => {
      unmounted.value = true;
      sourceAxios.cancel("Cancelling in cleanup");
    }
  }, [filterSort, state])

  const handleChange = (key) => {
    setActiveKey(key);
  };

  const handleCheckBox = (e, item) => {
    e.stopPropagation();
    let latestData = { ...selectedTransaction };
    if(item.isOffer || item.isDraftOffer){
      if(latestData?.buying?.includes(item.offerId)){
        latestData.buying =  latestData?.buying?.filter((ele)=> ele !== item.offerId)
      }
      else{
        latestData.buying = [ ...latestData.buying, item.offerId ]
      }
    }
    else if(item.isErts){
      if(latestData?.sellingErts?.includes(item.persistId)){
        latestData.sellingErts =  latestData?.sellingErts?.filter((ele)=> ele !== item.persistId)
      }
      else{
        latestData.sellingErts = [ ...latestData.sellingErts, item.persistId ]
      }
    }
    else if(item.isProperty){
      if(latestData?.sellingProperty?.includes(item.propertyId)){
        latestData.sellingProperty =  latestData?.sellingProperty?.filter((ele)=> ele !== item.propertyId)
      }
      else{
        latestData.sellingProperty = [ ...latestData.sellingProperty, item.propertyId ]
      }
    }
    setSelectedTransaction({
      ...latestData      
    })
  }

  return (
    <>
      <PropertyNavbar section={section} filterSort={filterSort}/>
      <div className={delegateAndLimited ? styles.delegateContainer : styles.transactionContainer}>
        { propertyDataLoading ? 
          <div style={{
            height: "60vh",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
          }}>
            <Spin size='large'/>
          </div> : propertyDataError ? "" :
          <div>
            <Collapse
              accordion
              ghost
              expandIcon={
                ({ isActive }) =>
                <CaretRightOutlined style={{display:"none"}} rotate={isActive ? 90 : 0} />
                }
              onChange={handleChange}
              activeKey={activeKey}
            >
              {propertyData?.map((el, idx)=>(
                <Panel
                key={`${el._id}-${idx}`}
                  className={`${styles.panelBox} ${!readOnly?.value && ((limited) && styles.allHighlightDiv)}`}
                  style={{
                    border:"1.5px solid #cacaca",
                    width:"100%",
                    margin:'auto',
                    borderRadius:"10px",
                    marginBottom: 10,
                    boxShadow: "3px 3px 3px #ccc",
                    background: (((activeKey === idx) && !limited) ? '#E7F5E4' : "#FFFFFF"),
                  }}
                  header={<ProfileDetails filterSort={filterSort} readOnly={readOnly} item={el} section={section} setSelectedPropertyId={setSelectedPropertyId} selectedTransaction={selectedTransaction} selectedPropertyId={selectedPropertyId}/>}
                  {...(!readOnly?.value ? ((limited) ? {onClick: (e)=>handleCheckBox(e, el)} : {}) : {})}
                >
                  { !limited && <PanelComp item={el} section={section} filterSort={filterSort}/> }
                </Panel>
              ))}
            </Collapse>
          </div>
        }
      </div>
    </>

  )
}

export default Transactions
