import React from "react";
import newSt from "../style/RtdWindow.module.css";
import { Avatar, Divider, Modal, Typography } from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import { BsTelephone } from "react-icons/bs";
import { BiUser } from "react-icons/bi";
import { formatPhoneNumber, generateInitialsFromObj } from "../../Common/utils/extraFunctions";
const { Text } = Typography;

const AgentDetailsModal = ({ userState, setUserState, isSmallScreen, isMediumScreen }) => {
  const { allAgentData, source, showDetailModal } = userState || {};

  const handleDetailOk = () => {
    setUserState({
      showDetailModal: false,
      allAgentData: [],
      source: "",
    });
  };

  const handleDetailCancel = () => {
    setUserState({
      showDetailModal: false,
      allAgentData: [],
      source: "",
    });
  };
  const modalTitle = `${source === "SELLER_AGENT" ? "SELLER AGENT" : "BUYER AGENT"}${allAgentData?.length > 1 ? "S" : ""} DETAILS`;

  return (
    <>
      <Modal width={650} title={<div style={{ textAlign: "center", fontSize: isSmallScreen ? "14px" : "18px", fontWeight: "bold", color: "#178DFA" }}>{modalTitle}</div>} footer={false} visible={showDetailModal} onOk={handleDetailOk} onCancel={handleDetailCancel}>
        {allAgentData?.map((agentData, index) => {
          const agentName = agentData?.fullName 
          const agentInitials = generateInitialsFromObj(agentData);
          const agentEmail = agentData?.email
          const agentPhone = agentData?.mobilePhoneNumber?.phoneNumber || agentData?.phoneNumber 
          return (
            <div key={agentData._id || index} className={newSt.agentDetailsModalContent}>
              <div>
              <Avatar key="5" src={agentData?.profileImg?.original} size={isSmallScreen || isMediumScreen ? 52 : 64} style={{ cursor: "pointer", margin: "auto", fontSize: "27px", border: "1px solid #085191", background: "#085191" }}>
                  {!agentData?.profileImg?.original && agentInitials ? agentInitials : <UserOutlined style={{ fontSize: isSmallScreen ? "25px" : isMediumScreen ? "45px" : "54px" }} />}
                </Avatar>
              </div>

              <div className={newSt.userDetails}>
                <div className={newSt.userDetailsFlex}>
                  {agentName && (
                    <>
                      <BiUser style={{ fontSize: isSmallScreen ? "20px" : isMediumScreen ? "25px" : "30px" }} />
                      <Text style={{ fontWeight: "bold", color: "grey", fontSize: isSmallScreen ? "12px" : isMediumScreen ? "16px" : "18px" }}>{agentName}</Text>
                    </>
                  )}
                </div>
                <div className={newSt.userDetailsFlex}>
                  {agentEmail && (
                    <>
                      <MailOutlined style={{ fontSize: isSmallScreen ? "20px" : isMediumScreen ? "25px" : "30px" }} />
                      <Text style={{ fontWeight: "bold", color: "grey", width: "90%", fontSize: isSmallScreen ? "12px" : isMediumScreen ? "16px" : "18px" }} ellipsis copyable>
                        {agentEmail}
                      </Text>
                    </>
                  )}
                </div>
                <div className={newSt.userDetailsFlex}>
                  {agentPhone && (
                    <>
                      <BsTelephone style={{ fontSize: isSmallScreen ? "20px" : isMediumScreen ? "25px" : "30px" }} />
                      <Text style={{ fontWeight: "bold", color: "grey", fontSize: isSmallScreen ? "12px" : isMediumScreen ? "16px" : "18px" }} ellipsis="rows" copyable>
                        {formatPhoneNumber(agentPhone)}
                      </Text>
                    </>
                  )}
                </div>
                {/* {index < allAgentData.length - 1 && <Divider />} */}
              </div>
            </div>
          );
        })}
      </Modal>
    </>
  );
};

export default AgentDetailsModal;
