

  export const filteredTransactionData = ({ele, selectedData, source}) => {
    let transactionData = selectedData?.newTransactionData || selectedData?.transactionData || {}
    // if (ele.prefillValues) {
    //   let allInputsToDelete = Object.keys(ele.prefillValues);
    //   console.log("allInputsToDelete", allInputsToDelete);
    //   allInputsToDelete?.forEach((element) => {
    //     delete transactionData[element];
    //   });
    // }
    if (source === "PROPERTY_DOCS") {
      delete transactionData["dateTable"];
      delete transactionData["addClauseData"];
      delete transactionData["addDocumentData"];
    }
    return selectedData;
  }
