import { Col, Form, Modal, Select } from "antd";
import { Option } from "antd/lib/mentions";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TemplateInput = ({
  handleOnfocusChange,
  setTemplateId,
  templateData,
}) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const handleSelectClick = () => {
    if (!templateData || templateData.length === 0) {
      setShowModal(true);
    }
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const handleModalOk = () => {
    setShowModal(false);
    history.push("/tools");
  };


  return (
    <Col xs={0} sm={0} md={24} lg={12} xl={12}>
      <Form.Item
        className="lbl"
        name="template"
        label="Template"
        // rules={[{ required: true }]}
        onFocus={() => handleOnfocusChange({ template: true })}
      >
        <Select
          showSearch
          // placeholder="Select a template"
          optionFilterProp="children"
          onChange={setTemplateId}
          filterOption={(input, option) =>
            option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
          }
          allowClear
          onClick={handleSelectClick}
          notFoundContent=""
        >
          {templateData &&
            templateData.map((ele, index) => {
              return (
                <Option key={index} value={ele._id}>
                  {ele.templateName}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
      
      {showModal && (
        <Modal
          title={<div style={{fontWeight: 'bold', textAlign: 'center', color: '#178DFA'}}>NO TEMPLATE FOUND</div>}
          open={showModal}
          onCancel={handleModalCancel}
          onOk={handleModalOk}
          okText="CREATE TEMPLATE"
          cancelText="CANCEL"
        >
          <p>
            Looks like you don't have any Template in your account. Please go to <span style={{fontWeight: 'bold'}}>Tools</span>, then click on <span style={{fontWeight: 'bold'}}>Template</span> menu.
            On the upper left hand corner click on the <span style={{fontWeight: 'bold'}}>Create Template</span> button.
          </p>
          <p>
            Or you can refer the link below to watch a tutorial video:- <br />
            <a href="https://www.loom.com/share/c70314f257584a0f9d68d8e2085edd32" target="_blank" rel="noopener noreferrer">Watch Video</a>
          </p>
        </Modal>
      )}
    </Col>
  );
};

export { TemplateInput };
