export const transactionLaneConstants = {
  GET_TRANSACTION_LANE_SUCCESS: "GET_TRANSACTION_LANE_SUCCESS",
  GET_TRANSACTION_LANE_FAILURE: "GET_TRANSACTION_LANE_FAILURE",
  GET_TRANSACTION_LANE_LOADING: "GET_TRANSACTION_LANE_LOADING",

  GET_INDIVIDUAL_TRANSACTION_SUCCESS: "GET_INDIVIDUAL_TRANSACTION_SUCCESS",
  GET_INDIVIDUAL_TRANSACTION_FAILURE: "GET_INDIVIDUAL_TRANSACTION_FAILURE",

  GET_TITLE_COMPANY_DATA_LOADING:"GET_TITLE_COMPANY_DATA_LOADING",
  GET_TITLE_COMPANY_DATA_SUCCESS:"GET_TITLE_COMPANY_DATA_SUCCESS",
  GET_TITLE_COMPANY_DATA_ERROR:"GET_TITLE_COMPANY_DATA_ERROR",

  DORADOCS_SHORTHAND_URL_LOADING: "DORADOCS_SHORTHAND_URL_LOADING",
  DORADOCS_SHORTHAND_URL_SUCCESS: "DORADOCS_SHORTHAND_URL_SUCCESS",
  DORADOCS_SHORTHAND_URL_FAILURE: "DORADOCS_SHORTHAND_URL_FAILURE",

  ADD_EMAIL_LOADING : "ADD_EMAIL_LOADING",
  ADD_EMAIL_SUCCESS : "ADD_EMAIL_SUCCESS",
  ADD_EMAIL_ERROR : "ADD_EMAIL_ERROR",

  GET_DORA_DOCUMENTS_LOADING: "GET_DORA_DOCUMENTS_LOADING",
  GET_DORA_DOCUMENTS_SUCCESS: "GET_DORA_DOCUMENTS_SUCCESS",
  GET_DORA_DOCUMENTS_FAILURE: "GET_DORA_DOCUMENTS_FAILURE",

  GET_PROPERTY_DOCS_LOADING: "GET_PROPERTY_DOCS_LOADING",
  GET_PROPERTY_DOCS_SUCCESS: "GET_PROPERTY_DOCS_SUCCESS",
  GET_PROPERTY_DOCS_FAILURE: "GET_PROPERTY_DOCS_FAILURE",

  DRAFT_DOCUMENTS_SUCCESS: "DRAFT_DOCUMENTS_SUCCESS",
  DRAFT_DOCUMENTS_FAILURE: "DRAFT_DOCUMENTS_FAILURE",

  // Email action Type
  SEND_EMAIL_LOADING: "SEND_EMAIL_LOADING",
  SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAILURE: "SEND_EMAIL_FAILURE",

  UPDATE_DOCUMENT_LOADING: "UPDATE_DOCUMENT_LOADING",
  UPDATE_DOCUMENT_SUCCESS: "UPDATE_DOCUMENT_SUCCESS",
  UPDATE_DOCUMENT_FAILURE: "UPDATE_DOCUMENT_FAILURE",

  CLIENT_PORTAL_LOADING: "CLIENT_PORTAL_LOADING",
  CLIENT_PORTAL_ERROR: "CLIENT_PORTAL_ERROR",
  CLIENT_PORTAL_SUCCESS: "CLIENT_PORTAL_SUCCESS",

  EMAIL_FILES_LOADING: " EMAIL_FILES_LOADING",
  EMAIL_FILES_ERROR: " EMAIL_FILES_ERROR",
  EMAIL_FILES_SUCCESS: " EMAIL_FILES_SUCCESS",

  UPLOAD_DOCUMENT_LOADING: "UPLOAD_DOCUMENT_LOADING",
  UPLOAD_DOCUMENT_SUCCESS: "UPLOAD_DOCUMENT_SUCCESS",
  UPLOAD_DOCUMENT_FAILURE: "UPLOAD_DOCUMENT_FAILURE",

  ADD_TITLE_OFFICE_LOADING: "ADD_TITLE_OFFICE_LOADING",
  ADD_TITLE_OFFICE_SUCCESS: "ADD_TITLE_OFFICE_SUCCESS",
  ADD_TITLE_OFFICE_ERROR: "ADD_TITLE_OFFICE_ERROR",

  GET_TITLE_COMPANY_LOADING: "GET_TITLE_COMPANY_LOADING",
  GET_TITLE_COMPANY_SUCCESS: "GET_TITLE_COMPANY_SUCCESS",
  GET_TITLE_COMPANY_ERROR: "GET_TITLE_COMPANY_ERROR",

  RESET_RTD_DATA: "RESET_RTD_DATA",
  REFRESH_TITLE_COMPANY: "REFRESH_TITLE_COMPANY",
  UPDATE_SUCCESS_RTD: "UPDATE_SUCCESS_RTD",

  RESET_TRANSACTION_LANE_DATA:"RESET_TRANSACTION_LANE_DATA",
  TRIGGER_INDIVIDUAL_TRANSACTION_FETCH:"TRIGGER_INDIVIDUAL_TRANSACTION_FETCH"
};
