import { Button, Col, Form, Modal } from "antd";
import React from "react";
import { documentAction } from "../state/actions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const BuildDocButton = ({ progress, buildData, delegate }) => {
  const dispatch = useDispatch();
  const location= useLocation();
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={5} xl={5}>
        <Form.Item>
          <Button
            style={{ width: "100%", minWidth: "130px" }}
            type="primary"
            onClick={() => {
              // console.log("buildData", buildData);
              dispatch(documentAction.buildContractOffer(buildData, location?.pathname));
            }}
            disabled={delegate ? progress !== 102 : progress !== 100}
          >
            Build Document
          </Button>
        </Form.Item>
      </Col>
    </>
  );
};

export { BuildDocButton };
