import { Avatar, Button, List, message } from "antd";
import React, { useEffect } from "react";
import { UserOutlined, DeleteTwoTone } from "@ant-design/icons";

function EachCorpSigner({ item, ele, intials, isMobile, clientsArray, handleDelete, handleDataChange }) {
  
  useEffect(() => {
    const corpData = clientsArray?.filter((el) => el?.isCorp);
    corpData?.forEach((corp) => {
      corp?.signers?.forEach((signer) => {
        if (corp?.key && !signer?.personId && signer?.key) {
          // manual signer which don't have personId
          let targetInput = document.getElementsByName(`title_${corp?.key}_${signer?.key}`)[0];
          if (targetInput) targetInput.value = signer?.title || "";
        } else {
          let targetInput = document.getElementsByName(`title_${corp?.key}_${signer?.personId}`)[0];
          if (targetInput) targetInput.value = signer?.title || "";
        }
      });
    });
  }, [clientsArray]);

  const handleKeyPressOnTitle = (e, obj) => {
    if (e.key === "Enter") {
      handleTileSave(e.target.value, obj);
    }
  };
  const handleTileSave = (value, obj) => {
    if (Array.isArray(clientsArray)) {
      const corpData = clientsArray.filter((el) => el.isCorp && el.key === obj?.corpKey)[0];
      let updatedCorpData = { ...(corpData ? corpData : {}) };
      let personIndex = -1;
      if (!obj?.personId && obj?.manualSignerKey) {
           // manual signer which don't have personId
        personIndex = corpData?.signers?.findIndex((person) => person?.key === obj?.manualSignerKey);
      } 
      if (personIndex !== -1) {
        updatedCorpData.signers[personIndex][`title`] = value;
        clientsArray = clientsArray?.map((data) => {
          if (data?.isCorp && data?.key === obj?.corpKey) {
            return updatedCorpData;
          } else {
            return data;
          }
        });
      }
      handleDataChange({Buyers: clientsArray});
      message.success("Title Added Successfully!");
    }
  };

  return (
    <div>
      <List.Item
        style={{
          margin: isMobile ? "-5px 20px" : "-10px 0%",
          width: isMobile ? "93%" : "99%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <List.Item.Meta
          avatar={
            intials ? (
              <Avatar size={isMobile ? 35 : 45} style={{ background: "#085191", marginTop: isMobile ? "20px" : "18px" }}>
                {intials}
              </Avatar>
            ) : (
              <Avatar size={isMobile ? 35 : 45} icon={<UserOutlined />} style={{ background: "#085191", marginTop: isMobile ? "5px" : 0 }} />
            )
          }
          title={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  fontSize: isMobile ? "13px" : "15px",
                  verticalAlign: "middle",
                  lineHeight: "45px",
                  whiteSpace: "nowrap",
                  maxWidth: isMobile ? "160px" : "auto",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "capitalize",
                }}
              >
                {item?.fullName || ""}
              </div>
              <div style={{ fontSize: isMobile ? "10px" : "12px", display: "flex", gap: "5px", alignItems: "center" }}>
                <input placeholder="Enter Title" onKeyPress={(e) => handleKeyPressOnTitle(e, { corpKey: ele?.key, personId: item?.personId, manualSignerKey: item?.key })} name={ele?.key && item?.personId ? `title_${ele?.key}_${item?.personId}` : ele?.key && !item?.personId && item?.key ? `title_${ele?.key}_${item?.key}` : ""} style={{ border: "1px solid silver", width: "100%", minWidth: "50px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} />
                <Button
                  key="save"
                  type="primary"
                  onClick={(e) => {
                    let inputValue = e?.target?.parentElement?.parentElement?.querySelector("input").value;
                    handleTileSave(inputValue, { corpKey: ele?.key, personId: item.personId, manualSignerKey: item?.key });
                  }}
                  size={"small"}
                  style={{ fontSize: "10px" }}
                >
                  Save
                </Button>
              </div>
            </div>
          }
        />
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "20%" }}>
          <span onClick={() => handleDelete(ele, "SIGNERS", item)} style={{ cursor: "pointer" }}>
            <DeleteTwoTone style={{ fontSize: "medium" }} />
          </span>
        </div>
      </List.Item>
    </div>
  );
}

export default EachCorpSigner;
