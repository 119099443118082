export const clientConstants = {
  DRAFT_TOGGLE: "DRAFT_TOGGLE",
  CURRENT_TOGGLE: "CURRENT_TOGGLE",
  PAST_TOGGLE: "PAST_TOGGLE",

  CLIENT_LIST_LOADING: "CLIENT_LIST_LOADING",
  CLIENT_LIST_FAILURE: "CLIENT_LIST_FAILURE",
  CLIENT_LIST_SUCCESS: "CLIENT_LIST_SUCCESS",
  CLIENT_LIST_CLEAR: "CLIENT_LIST_CLEAR",

  FILTER_CLIENT_LIST_LOADING: "FILTER_CLIENT_LIST_LOADING",
  FILTER_CLIENT_LIST_FAILURE: "FILTER_CLIENT_LIST_FAILURE",
  FILTER_CLIENT_LIST_SUCCESS: "FILTER_CLIENT_LIST_SUCCESS",

  SEARCH_CLIENT_LIST_LOADING: "SEARCH_CLIENT_LIST_LOADING",
  SEARCH_CLIENT_LIST_FAILURE: "SEARCH_CLIENT_LIST_FAILURE",
  SEARCH_CLIENT_LIST_SUCCESS: "SEARCH_CLIENT_LIST_SUCCESS",

  POST_IMAGE_LOADING: "POST_IMAGE_LOADING",
  POST_IMAGE_FAILURE: "POST_IMAGE_FAILURE",
  POST_IMAGE_SUCCESS: "POST_IMAGE_SUCCESS",

  CLIENT_DETAILS_LOADING: "CLIENT_DETAILS_LOADING",
  CLIENT_DETAILS_FAILURE: "CLIENT_DETAILS_FAILURE",
  CLIENT_DETAILS_SUCCESS: "CLIENT_DETAILS_SUCCESS",

  CURRENT_CLIENTS_LOADING: "CURRENT_CLIENTS_LOADING",
  CURRENT_CLIENTS_FAILURE: "CURRENT_CLIENTS_FAILURE",
  CURRENT_CLIENTS_SUCCESS: "CURRENT_CLIENTS_SUCCESS",

  PAST_CLIENTS_LOADING: "PAST_CLIENTS_LOADING",
  PAST_CLIENTS_FAILURE: "PAST_CLIENTS_FAILURE",
  PAST_CLIENTS_SUCCESS: "PAST_CLIENTS_SUCCESS",

  DOCUMENTRY_LIST_LOADING: "DOCUMENTRY_LIST_LOADING",
  DOCUMENTRY_LIST_FAILURE: "DOCUMENTRY_LIST_FAILURE",
  DOCUMENTRY_LIST_SUCCESS: "DOCUMENTRY_LIST_SUCCESS",

  POST_EVENT_LOADING: "POST_EVENT_LOADING",
  POST_EVENT_FAILURE: "POST_EVENT_FAILURE",
  POST_EVENT_SUCCESS: "POST_EVENT_SUCCESS",

  DELETE_EVENT_LOADING: "DELETE_EVENT_LOADING",
  DELETE_EVENT_FAILURE: "DELETE_EVENT_FAILURE",
  DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",

  EDIT_EVENT_LOADING: "EDIT_EVENT_LOADING",
  EDIT_EVENT_FAILURE: "EDIT_EVENT_FAILURE",
  EDIT_EVENT_SUCCESS: "EDIT_EVENT_SUCCESS",

  EDIT_NAME_LOADING: "EDIT_NAME_LOADING",
  EDIT_NAME_FAILURE: "EDIT_NAME_FAILURE",
  EDIT_NAME_SUCCESS: "EDIT_NAME_SUCCESS",

  UPDATE_ADDRESS_LOADING: "UPDATE_ADDRESS_LOADING",
  UPDATE_ADDRESS_FAILURE: "UPDATE_ADDRESS_FAILURE",
  UPDATE_ADDRESS_SUCCESS: "UPDATE_ADDRESS_SUCCESS",

  UPDATE_PHONE_LOADING: "UPDATE_PHONE_LOADING",
  UPDATE_PHONE_FAILURE: "UPDATE_PHONE_FAILURE",
  UPDATE_PHONE_SUCCESS: "UPDATE_PHONE_SUCCESS",

  UPDATE_EMAIL_LOADING: "UPDATE_EMAIL_LOADING",
  UPDATE_EMAIL_FAILURE: "UPDATE_EMAIL_FAILURE",
  UPDATE_EMAIL_SUCCESS: "UPDATE_EMAIL_SUCCESS",

  UPDATE_TITLE_LOADING: "UPDATE_TITLE_LOADING",
  UPDATE_TITLE_FAILURE: "UPDATE_TITLE_FAILURE",
  UPDATE_TITLE_SUCCESS: "UPDATE_TITLE_SUCCESS",

  UPDATE_NOTES_LOADING: "UPDATE_NOTES_LOADING",
  UPDATE_NOTES_FAILURE: "UPDATE_NOTES_FAILURE",
  UPDATE_NOTES_SUCCESS: "UPDATE_NOTES_SUCCESS",

  UPDATE_BIRTHDAY_LOADING: "UPDATE_BIRTHDAY_LOADING",
  UPDATE_BIRTHDAY_FAILURE: "UPDATE_BIRTHDAY_FAILURE",
  UPDATE_BIRTHDAY_SUCCESS: "UPDATE_BIRTHDAY_SUCCESS",

  UPDATE_ROLEHASHTAG_LOADING: "UPDATE_ROLEHASHTAG_LOADING",
  UPDATE_ROLEHASHTAG_FAILURE: "UPDATE_ROLEHASHTAG_FAILURE",
  UPDATE_ROLEHASHTAG_SUCCESS: "UPDATE_ROLEHASHTAG_SUCCESS",

  POST_PROFILE_IMAGE_LOADING: "POST_PROFILE_IMAGE_LOADING",
  POST_PROFILE_IMAGE_SUCCESS: "POST_PROFILE_IMAGE_SUCCESS",
  POST_PROFILE_IMAGE_FAILURE: "POST_PROFILE_IMAGE_FAILURE",

  PERSONALDATA_RESET: "PERSONALDATA_RESET",
  CLIENT_NAME_RESET: "CLIENT_NAME_RESET",
  CLIENT_ADDRESS_RESET: "CLIENT_ADDRESS_RESET",
  PHONE_RESET: "PHONE_RESET",
  EMAIL_RESET: "EMAIL_RESET",
  TITLE_RESET: "TITLE_RESET",
  NOTES_RESET: "NOTES_RESET",
  BIRTHDAY_RESET: "BIRTHDAY_RESET",
  PROFILE_IMAGE_RESET: "PROFILE_IMAGE_RESET",
  PERSON_DOCS_RESET: "PERSON_DOCS_RESET",

  DRAFT_DOCUMENTS_LOADING: "DRAFT_DOCUMENTS_LOADING",
  DRAFT_DOCUMENTS_SUCCESS: "DRAFT_DOCUMENTS_SUCCESS",
  DRAFT_DOCUMENTS_FAILURE: "DRAFT_DOCUMENTS_FAILURE",

  URL_SHORTNING_DRAFT_DOCUMENT_LOADING: "URL_SHORTNING_DRAFT_DOCUMENT_LOADING",
  URL_SHORTNING_DRAFT_DOCUMENT_SUCCESS: "URL_SHORTNING_DRAFT_DOCUMENT_SUCCESS",
  URL_SHORTNING_DRAFT_DOCUMENT_FAILURE: "URL_SHORTNING_DRAFT_DOCUMENT_FAILURE",

  URL_SHORTNING_CALENDAR_LOADING: "URL_SHORTNING_CALENDAR_LOADING",
  URL_SHORTNING_CALENDAR_SUCCESS: "URL_SHORTNING_CALENDAR_SUCCESS",
  URL_SHORTNING_CALENDAR_FAILURE: "URL_SHORTNING_CALENDAR_FAILURE",

  PERSON_DOCS_LOADING: "PERSON_DOCS_LOADING",
  PERSON_DOCS_FAILURE: "PERSON_DOCS_FAILURE",
  PERSON_DOCS_SUCCESS: "PERSON_DOCS_SUCCESS",

  MEMORY_DETAILS_LOADING: "MEMORY_DETAILS_LOADING",
  MEMORY_DETAILS_FAILURE: "MEMORY_DETAILS_FAILURE",
  MEMORY_DETAILS_SUCCESS: "MEMORY_DETAILS_SUCCESS",

  DELETE_MEMORIES_LOADING: "DELETE_MEMORIES_LOADING",
  DELETE_MEMORIES_FAILURE: "DELETE_MEMORIES_FAILURE",
  DELETE_MEMORIES_SUCCESS: "DELETE_MEMORIES_SUCCESS",

  UPDATE_MEMORY_LOADING: "UPDATE_MEMORY_LOADING",
  UPDATE_MEMORY_SUCCESS: "UPDATE_MEMORY_SUCCESS",
  UPDATE_MEMORY_FAILURE: "UPDATE_MEMORY_FAILURE",

  UPLOAD_IMAGE_LOADING: "UPLOAD_IMAGE_LOADING",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",

  RENDER_IMAGE: "RENDER_IMAGE",
  RENDER_PERSON_DETAIL: "RENDER_PERSON_DETAIL"
};
