import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Modal, Button, Typography, Checkbox, Row, message } from "antd";
import { UserOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { userSignUpAction } from "../state/actions";
import styles from "../styles/User.module.css";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import dotEnv from "dotenv";
import { useMediaQuery } from "react-responsive";
dotEnv.config();
const adminURL = process.env.REACT_APP_ADMIN_BASE_URL;

const { Text } = Typography;

/* eslint-enable no-template-curly-in-string */
function validateMessages(label) {
  return {
    required: `${label} is required!`,
    types: {
      email: `${label} is not a valid email!`,
      number: `${label} is not a valid number!`,
    },
  };
}
/* eslint-enable no-template-curly-in-string */
function UserLogin(props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [emailVal, setemailval] = useState("");
  let urlParams = new URLSearchParams(window.location.search);
  let urlId = urlParams.get("urlId");
  let onFinish = (event) => {
    let events = {
      email: event.email,
      password: event.password,
    };
    dispatch(userSignUpAction.userLogin(events));
  };
  const [isModalVisible, setIsModalVisible] = useState(true);

  const { userLogInError, userLogInLoading } = useSelector((state) => state.userSignup);
  const isMobile = useMediaQuery({ maxWidth: 540 });

  let handleOk = () => {
    setIsModalVisible(false);
    history.replace({});
  };
  let handleCancel = () => {
    setIsModalVisible(false);
    history.replace({});
  };
  let destination = location?.state?.dest;
  let searchQuery = location?.state?.searchQuery;
  let accessToken = localStorage.getItem("userAuth");

  const { userSessionId } = useSelector((state) => state.userSignup);
  let redirectpathname;
  if (String(userSessionId) === location?.state?.sessionId) {
    redirectpathname = location?.state?.redirectpathname;
  }

  if ((destination === "offerTable" || destination==="PRE_RTD") && accessToken && String(userSessionId) === location?.state?.sessionId) {
    window.location.href = `${redirectpathname}`
    return <></>
  } else if (urlId && accessToken) {
    window.location.href=`${adminURL}/contractDocument/${urlId}`
    return <></>
  }
  
  return accessToken ? (
    <Redirect
      to={{
        pathname: redirectpathname ? redirectpathname : "/transactions",
        ...(redirectpathname && searchQuery ? { search: searchQuery } : {}),
        state: { role: "user" },
      }}
    />
  ) : (
    <div style={{ padding: isMobile ? "5rem 2rem" : "5rem 5rem" }}>
      <div>
        <Modal title="RE-Sure" onOk={handleOk} onCancel={handleCancel} visible={!destination && location && location.state && location.state.email && isModalVisible ? true : false}>
          <p>Email with verification link has been sent to your email id please verify the link to proceed with RE-Sure.</p>
        </Modal>
      </div>
      <div style={{ paddingBottom: "1rem" }}>
        <h1 className={styles.custFormH1}>Sign In</h1>
        <h2 className={styles.custFormH2}>Welcome to RE-Sure</h2>
      </div>
      <Form
        layout="vertical"
        name="normal_login"
        className={styles.loginForm}
        initialValues={{
          remember: true,
          email: location && location.state && location.state.email,
        }}
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item
          label="Email"
          name="email"
          hasFeedback
          rules={[
            {
              type: "email",
              message: "Not a valid Email!",
            },
            {
              required: true,
              message: "Please Enter Email!",
            },
          ]}
        >
          <Input
            value={emailVal}
            onChange={(e) => {
              setemailval(e.target.value);
            }}
            prefix={<UserOutlined className="site-form-item-icon" style={{ marginRight: "15px" }} />}
            placeholder="Enter Email"
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          hasFeedback
          rules={[
            {
              required: true,
              message: "",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value?.length > 5 && value?.includes(" ") === false) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error("Password cannot be empty !"));
              },
            }),
          ]}
        >
          <Input.Password prefix={<LockOutlined className="site-form-item-icon" style={{ marginRight: "15px" }} />} type="password" placeholder="Enter Password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} allowClear />
        </Form.Item>
        <Form.Item>
          <Row justify="space-between">
            <Form.Item>
              <Checkbox style={{ color: "#7f8082" }} checked={true}>
                Keep me logged in
              </Checkbox>
            </Form.Item>
            <Link
              to={{
                pathname: "/forgetpassword",
                state: { email: emailVal && emailVal },
              }}
            >
              {" "}
              Forgot Password?
            </Link>
          </Row>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" className={styles.btnPrimary} loading={userLogInLoading}>
            Log in
          </Button>
          <Row justify="center">
            <Text type="secondary">
              Not yet a member ?
              <Link to="/signup">
                {" "}
                Sign up now
              </Link>
            </Text>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
}
export { UserLogin };
