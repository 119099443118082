import { Col, Form, Button } from "antd";
import React from "react";

const PropertySearchBtn = ({
  handleOnfocusChange,
  handleOnBlurChange,
  handlePropSearchModel,
  fieldEmpty,
}) => {
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={5} xl={5}>
        <Form.Item
          onFocus={() =>
            handleOnfocusChange({
              propertyType: true,
              propertyTypeColor: "yellow",
            })
          }
          onBlur={() =>
            handleOnBlurChange({
              propertyType: fieldEmpty.propertyType === "" ? false : true,
              propertyTypeTypeColor: "green",
            })
          }
        >
          <Button type="primary" onClick={handlePropSearchModel} style={{width: "100%",minWidth:"130px" }}>
            Property Search
          </Button>
        </Form.Item>
      </Col>
    </>
  );
};

export { PropertySearchBtn };
