import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Form, Button } from "antd";
import { clientAction } from "../state/actions";
import { useRef } from "react";
import ReactQuill from "react-quill";
import EditorToolbar from "../../Landing/components/EditorToolbar";
import { modules, formats } from "../../Landing/components/EditorToolbar";
import { CaretRightOutlined } from "@ant-design/icons";
import AvatarImage from "../../Landing/components/NotesImageUpload";
import "../styles/client.css";
import { Loading } from "../../Common/components/Loading";
import ImagePreviewModal from "../../Landing/components/ImagePreviewModal";

function NotesDetails({ data, selectedClient }) {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const timer = useRef();
  let imageId = useRef(null);
  const hasChangedNotes = useRef(false);
  const [currentNotes, setCurrentNotes] = useState(data?.notes || "");
  const [componantDidMount, setcomponantDidMount] = useState(false);
  const [notesState, setNotesState] = useState({
    showImagePreview: false,
    imgPreviewUrl: null,
    isPanelCollapsed: false,
  });
  const { personDetails } = useSelector((state) => state.client.personDetailReducer, shallowEqual);
  const { updateNotesLoading } = useSelector((state) => state.client.notesReducer);
 
  

  useEffect(() => {
    if (personDetails && Object.keys(personDetails)?.length) {
      setCurrentNotes(personDetails?.notes);
    }
  }, [personDetails]);

  const onFinish = () => {
    let values = { notes: currentNotes };
    dispatch(clientAction.updateNotes(values, data, selectedClient));
    hasChangedNotes.current = false;
  };

  const handleButtonClick = () => {
    setNotesState((prevState) => ({ ...prevState, isPanelCollapsed: !prevState.isPanelCollapsed }));
  };

  const handleInputNotes = ({ content, source }) => {
    if (source === "user") {
      clearTimeout(timer.current);
      let _notes = String(content).replace(/<img(?![^>]*id="|[^>]*width="|[^>]*height=")/g, (match) => {
        const randomId = Math.floor(10000 + Math.random() * 90000);
        return `<img alt="${randomId}" height="25px" width="25px" style="margin-right: 20px;"`;
      });
      hasChangedNotes.current = true;
      setCurrentNotes(_notes);
    }
  };

  const EditImageOFNotes = (fileData) => {
    let id = imageId.current;
    const regex = new RegExp(`<img src="([^"]*)" alt="${id}"([^>]*)>`, "g");

    function blobToDataURL(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }

    blobToDataURL(fileData)
      .then((dataUrl) => {
        let updatedHtml = currentNotes?.replace(regex, `<img src="${dataUrl}" alt="${id}"$2>`);
        let values = { notes: updatedHtml };
        dispatch(clientAction.updateNotes(values, data, selectedClient));
        setNotesState((prevState) => ({ ...prevState, showImagePreview: false }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteImage = (e) => {
    let ide = imageId.current;
    let pattern = new RegExp(`(<img[^>]*alt="${ide}"[^>]*>)`, "g");
    let newText = currentNotes?.replace(pattern, "");
    dispatch(clientAction.updateNotes({ notes: newText }, data, selectedClient));
    setNotesState((prevState) => ({ ...prevState, showImagePreview: false }));
  };

  function handleImageClick(e) {
    const id = e.target.alt;
    const src = e.target.src;
    const width = e.target.width;
    imageId.current = id;
    if (width === 25) {
      setNotesState((prevState) => ({ ...prevState, imgPreviewUrl: src, showImagePreview: true }));
    }
  }

  const getUniqueIdForNotesImage = (blob) => {
    if (!blob || !blob.lastModifiedDate) {
      return null;
    }

    let alt = blob.lastModifiedDate.toString();
    alt = alt.replace(/\s/g, "");
    return alt;
  };

  function UploadImageForNotes(fileData) {
    let id = getUniqueIdForNotesImage(fileData);
    let notesText = currentNotes || "";
    let props = {
      source: "CLIENT",
    };
    dispatch(clientAction.postImage({ fileData, id, notesText, props, functionalityFor: "imageUpload", clientData: data, selectedClient }));
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setcomponantDidMount(true);
    return () => {
      setcomponantDidMount(false);
    };
  }, []);

  // adding event on image for showing in modal
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const quilEditor = document.getElementById("quillEditorDiv");
      if (quilEditor) {
        quilEditor.addEventListener("click", handleImageClick);
      }
    }, 1000);
  
    return () => {
      clearTimeout(timeoutId);
      const quilEditor = document.getElementById("quillEditorDiv");
      if (quilEditor) {
        quilEditor.removeEventListener("click", handleImageClick);
      }
    };
  }, [componantDidMount]);
  

  return (
    <>
      <Form
        labelCol={{ xs: 10, sm: 18, md: 18, lg: 18, xl: 18 }}
        wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
        initialValues={{
          notes: data?.notes,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout={"vertical"}
      >
        <Form.Item label="Notes" name="notes" rules={[{ required: false, message: "Please Enter Notes!" }]}>
          {updateNotesLoading || !componantDidMount ? (
            <Loading />
          ) : (
            <>
              <div>
                <div style={{ position: "absolute", top: "-40px", right: "10px", transition: "top 0.10s ease-in-out", display: "flex", gap: "10px" }}>
                  <Button style={{ border: "none", marginRight: "10px" }} icon={<CaretRightOutlined style={{ fontSize: "25px", color: "#085191" }} rotate={notesState.isPanelCollapsed ? 90 : 0} />} onClick={handleButtonClick} />
                  <AvatarImage id={null} UploadImageForNotes={UploadImageForNotes} EditImageOFNotes={EditImageOFNotes} />
                </div>
                <div style={notesState.isPanelCollapsed ? { display: "block" } : { display: "none" }}>
                  <EditorToolbar />
                </div>
              </div>
              <ReactQuill
                style={{
                  overflowx: "none",
                  overflowY: "none",
                  overflowWrap: "break-word",
                  height: "calc(100vh - 35rem)",
                  background: "lightgoldenrodyellow",
                  color: "black",
                  fontFamily: "monospace",
                  lineHeight: "31px !important",
                  width: "100%",
                }}
                id="quillEditorDiv"
                modules={modules}
                formats={formats}
                value={currentNotes}
                onChange={(content, delta, source) => {
                  handleInputNotes({ content, source });
                }}
                ref={inputRef}
              />
            </>
          )}
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 10, span: 12 }}>
          <Button type="primary" htmlType="submit" disabled={!hasChangedNotes.current}>
            Save
          </Button>
        </Form.Item>
      </Form>
      {/* Modal will open when clicked on any image in Notes */}
      {notesState?.showImagePreview && <ImagePreviewModal UploadImageForNotes={null} EditImageOFNotes={EditImageOFNotes} handleDeleteImage={handleDeleteImage} notesState={notesState} setNotesState={setNotesState} />}
    </>
  );
}
export { NotesDetails };
