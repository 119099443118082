import { Badge, Button,Empty,Input, List,Modal,Typography, message,Dropdown,Menu,Popconfirm,Form,Select,Pagination, Avatar, Checkbox } from "antd";
import React, { useState,useRef } from "react";
import IconText from "./IconText";
import { useDispatch, useSelector } from "react-redux";
import { bugReportHandle } from "../state/actions";
import { Card, Col, Image, Row } from "antd";
import { LikeOutlined, StarOutlined ,DeleteOutlined,MessageOutlined,PlusCircleFilled,MoreOutlined,InboxOutlined,CloseCircleOutlined,SearchOutlined,ExclamationCircleOutlined} from "@ant-design/icons";
import styles from "../styles/Listing.module.css";
import moment from "moment";
import { useEffect } from "react";
import { GoVerified } from "react-icons/go"
import Dragger from "antd/lib/upload/Dragger";
import { capitalizeStr } from '../../Common/utils/extraFunctions';
import { Loading } from '../../Common/components/Loading';
import ReactQuill from "react-quill";
import { useMediaQuery } from "react-responsive";
import { UserModal } from "./UserModal";
import imageCompression from 'browser-image-compression';
import { IconTextStatus } from "./IconTextStatus";

const EachCard = ({ item,page }) => {
  const [likesCount, setLikesCount] = useState(item.likeCounts);
  const dispatch = useDispatch();
  const { bugPostData,bugCommentsLoading,bugCommentsData,renderComp,usersData,usersDataLoading,totalUsersRecords,bugPostLoading, searchbugdata, filteredData, status, like, priority, startDate, endDate } = useSelector((state) => state.bugReport);
  const inputRef = useRef()
  const [commentsCount, setCommentsCount] = useState(item?.totalComments?.comment);
  const [showcmnt, setShowcmnt] = useState({
    showAllComments:false,
    openCommentInput:false
  })
  const formRef = useRef(null);
  // const [comment,setComment]=useState("")
  const [visibleComments, setVisibleComments] = useState(3);
  const [role,setRole] = useState(false);
  const [modalVisible,setModalVisible] = useState(false)
  const [handleData, setHandleData] = useState([]);
  const [UpdateImagedata,setUpdateimagedata]=useState([])
  const [input, setInput] = useState("");
  const [userDataPage, setUserDataPage] = useState(1);
  const [searchUserdata,setSearchUserData] = useState("");
  const [userModalVisible,setUserModalVisible] = useState(false)
  const [clientID,setClientID] = useState("")
  const [form] = Form.useForm();
  const [commentForm] = Form.useForm();
  const imageFormdataRef = useRef([]);
  const [typeChanged, setTypeChanged] = useState(false);
  const quillRef = useRef();
  const visibleCommentsData = bugCommentsData && bugCommentsData?.filter((el) => el.id === item?._id).map((item) => item?.data?.slice(0, visibleComments));
//   const [cmntscount] = bugCommentsData && bugCommentsData.filter((el) => el.id === item._id).map((item) => item.data.length);
const { getUserDetailsData } = useSelector((state) => state.account);
const { Text } = Typography;
const isMobile = useMediaQuery({ maxWidth: 480 });
const isTab = useMediaQuery({ maxWidth: 1399 });
const isTab1 = useMediaQuery({ maxWidth: 991 });
const spot = useMediaQuery({ minWidth:451,maxWidth: 480 });
const spot2 = useMediaQuery({ minWidth:986,maxWidth: 1350 });
const spot3 = useMediaQuery({ maxWidth: 1349 });
const spot4 = useMediaQuery({ minWidth:810,maxWidth: 985 });
const spot5 = useMediaQuery({ minWidth:1351,maxWidth: 1399 });
const spot6 = useMediaQuery({ minWidth:481,maxWidth: 775 });
const spot7 = useMediaQuery({ minWidth:481,maxWidth: 775 });
const spot8 = useMediaQuery({ minWidth:980});
const spot9 = useMediaQuery({ minWidth:986,maxWidth: 1160 });
const [isliked, setIsLiked] = useState(((getUserDetailsData?.adminRole?.includes("SUPER_ADMIN") || getUserDetailsData?.adminRole?.includes("RS_SUPPORT")) || role) ? item.liked : item.likeCounts > 0 ? true : false);
const isCreated = item?.createdBy?._id === getUserDetailsData?._id;

const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
      md:{
        span:24,
      },
      lg:{
        span:24
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
      md:{
        span:24,
      },
      lg:{
        span:24,
      },
    },
  };

const tailFormItemLayout = {
  wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
      md:{
        span: 16,
        offset: 8,
      },
      lg:{
        span: 16,
        offset: 8,
      }
    },
};

const handleSeeMoreClick = () => {
    setVisibleComments(prevVisibleComments => prevVisibleComments + 3);
  };

  const handleSeeLessClick = () => {
    setVisibleComments(3);
  };


  if (bugPostData && Object.keys(bugPostData).length > 0) {
    message.success("Bug Created!");
    dispatch(bugReportHandle.resetBugPostData());
  }
  const {Paragraph} = Typography;

  let handleStatus = (item) => {};
  let handleLike = (item) => {
    dispatch(bugReportHandle.updateLike(item._id, isliked ? false : true));
    setIsLiked((prev) => !prev);
    isliked
      ? setLikesCount((prev) => prev - 1)
      : setLikesCount((prev) => prev + 1);
  };
  const handleDelete=(id,page)=>{
    if(renderComp==='listings'){
        dispatch(bugReportHandle.bugDelete(id,"BUGS",true,searchbugdata))
    }else{
        if(renderComp === "suggestions"){
          dispatch(bugReportHandle.bugDelete(id,"SUGGESTIONS",true,searchbugdata));
        }else{
          dispatch(bugReportHandle.bugDelete(id,"SUPPORT",true,searchbugdata));
        }
    }
  }

const handleShowDeleteModal=(id)=>{
  Modal.confirm({
    title: "Are you sure you want to delete this item?",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk:()=>handleDelete(id,page)
  });
}


// const handleAddComment=(id,comment)=>{
//   if(comment.length > 0){
//     setCommentsCount((prevCommentCount) => prevCommentCount + 1);
//     dispatch(bugReportHandle.resetCmntsData(id));
//     dispatch(bugReportHandle.bugReportComment(id,comment))
//     setComment("");
//   }else{
//     message.error("Enter your comment!")
//   }
// }


const handleComments=(id)=>{
    setShowcmnt({...showcmnt,showAllComments:!showcmnt.showAllComments});
    if(showcmnt.showAllComments){
      dispatch(bugReportHandle.resetCmntsData(id));
    }else{
      dispatch(bugReportHandle.resetCmntsData(id));
      dispatch(bugReportHandle.getBugComments(id));
    }
}

const priorityOptions = [
  {value:"LOW",label:"Low"},
  {value:"MEDIUM",label:"Medium"},
  {value:"HIGH",label:"High"},
  {value:"CRITICAL",label:"Critical"},
]
const typeOptions = [
  {value:"BUGS",label:"BUGS"},
  {value:"SUGGESTIONS",label:"SUGGESTIONS"},
  {value:"SUPPORT",label:"SUPPORT"},
]
const statusOptions = [
  {value:"ACTIVE",label:"Active"},
  {value:"IN_PROGRESS",label:"In-progress"},
  {value:"AWAITING_INPUT",label:"Awaiting Input"},
  {value:"INPUT_RECEIVED",label:"Input Received"},
  {value:"NEEDS_TESTING",label:"Completed/Needs Testing"},
  {value:"COMPLETED",label:"Completed"},
  {value:"CLOSED",label:"Closed"},
]

const MenuItem = (item) => {
  return (
    <Menu size="large">
      {(getUserDetailsData?.adminRole?.includes("SUPER_ADMIN") || getUserDetailsData?.adminRole?.includes("RS_SUPPORT") || role || isCreated) && <Menu.Item key="1" onClick={()=>{
          handleModalData([item?.item]);
          setModalVisible(true);
          }}>
        <span style={{ color: "#04396B" }}>{renderComp === "listings" ? "EDIT BUG" : renderComp === "suggestions" ? "EDIT SUGGESTION" : "EDIT TICKET"}</span>
      </Menu.Item>}
      {(getUserDetailsData?.adminRole?.includes("SUPER_ADMIN") || isCreated) && <Menu.Item key="2" onClick={()=>handleShowDeleteModal(item?.item?._id)}>
        <span style={{ color: "#04396B" }}>{renderComp === "listings" ? "DELETE BUG" : renderComp === "suggestions" ? "DELETE SUGGESTION" : "DELETE TICKET"}</span>
      </Menu.Item>}
      {((getUserDetailsData?.adminRole?.includes("SUPER_ADMIN") || getUserDetailsData?.adminRole?.includes("RS_SUPPORT") || role || isCreated) && item?.item?.status === "ACTIVE") ? <Menu.Item key="3" onClick={() => handleClose(item?.item?._id)}>
        <span style={{ color: "#04396B" }}>{renderComp === "listings" ? "CLOSE BUG" : renderComp === "suggestions" ? "CLOSE SUGGESTION" : "CLOSE TICKET"}</span>
      </Menu.Item> : null}
    </Menu>
  );
};

const handleClose = (id,statusValue) => {
  if(renderComp==='listings'){
      dispatch(bugReportHandle.changeStatus(id,"BUGS",true,searchbugdata,statusValue, filteredData, status, like, priority, startDate, endDate))
  }else{
      if(renderComp === "suggestions"){
        dispatch(bugReportHandle.changeStatus(id,"SUGGESTIONS",true,searchbugdata,statusValue, filteredData, status, like, priority, startDate, endDate));
      }else{
        dispatch(bugReportHandle.changeStatus(id,"SUPPORT",true,searchbugdata,statusValue, filteredData, status, like, priority, startDate, endDate));
      }
  }
}

let handleModalData = (item) => {
  setHandleData(item);
};

function bytesToSize(bytes) {
  let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

const onFinish = (values) => {
  let sendData = {
    ...(values?.content?.length > 0 ? {content:values?.content} : {}),
    ...(values?.platform?.length > 0 ? {platform:values?.platform} : {}),
    ...(values?.priority?.length > 0 ? {priority:values?.priority} : {}),
    ...(values?.assignedTo?.length > 0 ? {assignedTo:clientID} : {}),
    ...(typeChanged && values?.type?.length > 0 ? {type:values?.type} : {}),
    _id:item?._id,
  }
  setTypeChanged(false);
  if(input.length > 0){
    sendData = {...sendData,assignedTo:clientID}
  }
      if(renderComp=== "listings"){
          dispatch(bugReportHandle.updateImageforBugNew(sendData,imageFormdataRef.current, "notBase64","BUGS",true,handleData,searchbugdata));
      }else if(renderComp === "suggestions"){
          dispatch(bugReportHandle.updateImageforBugNew(sendData,imageFormdataRef.current, "notBase64","SUGGESTIONS",true,handleData,searchbugdata));
      }else{
          dispatch(bugReportHandle.updateImageforBugNew(sendData,imageFormdataRef.current, "notBase64","SUPPORT",true,handleData,searchbugdata));
      }
    handleCancel("UPDATING");
  };

  let clearForm = (form) => {
    form.resetFields();
    setHandleData([])
};

  const handleCancel=(source)=>{
    if(source?.length){
      clearForm(form);
      setModalVisible(false)
      setUpdateimagedata([])
      imageFormdataRef.current = [];
    }else{
      Modal.confirm({
        title:`Are you sure you want to discard updating this ${renderComp === "suggestions"
        ? "suggestion"
        : renderComp === "listings"
        ? "bug"
        : "ticket"}?`,
        okText:"Yes",
        cancelText:"No",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          clearForm(form);
          setModalVisible(false)
          setUpdateimagedata([])
          imageFormdataRef.current = [];
        },
        onCancel() {},
      })
    }
  }

  const handleUserModalCancel = () => {
    setUserModalVisible(false)
  }

  const handleChangeImage = (urlToDelete,index) => {
    const updatedImageData = handleData.filter((element) => {
      if (element.imageUrls && element.imageUrls.includes(urlToDelete)) {
        element.imageUrls = element.imageUrls.filter((url) => url !== urlToDelete);
        const imageFormdataCopy = [...imageFormdataRef.current];
        imageFormdataCopy.splice(index, 1);
        imageFormdataRef.current = imageFormdataCopy;
      }
      return true;
    });

    setHandleData(updatedImageData)
  };

  function deleteImg(index) {
    let filterData = UpdateImagedata.filter((el, i) => i !== index);
    setUpdateimagedata(filterData);
    const imageFormdataCopy = [...imageFormdataRef.current]; // Create a shallow copy of the array
    imageFormdataCopy.splice(index, 1); // Remove one element at the specified index
    imageFormdataRef.current = imageFormdataCopy;
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      throw new Error("You can only upload JPG/PNG file!");
    }
    return isJpgOrPng;
  }

  async function compressImage(file) {
    const maxSizeMB = 3;
    const maxSizeBytes = maxSizeMB * 1024 * 1024;
    if (file.size <= maxSizeBytes) {
      return file;
    }

    const initialQuality = 0.7;
    let currentQuality = initialQuality;

    while (true) {
      try {
        const compressedFile = await imageCompression(file, { quality: currentQuality });
        if (compressedFile.size <= maxSizeBytes || currentQuality <= 0.1) {
          return compressedFile;
        }
        currentQuality -= 0.1;
      } catch (error) {
        throw error;
      }
    }
  }

  let handleCustome = async (data) => {
    const image = await compressImage(data.file)
    const reader = new FileReader();
    reader.readAsDataURL(data.file);
    reader.onload = () => {
      const newFile = {
        name: data.file.name,
        size: bytesToSize(image.size),
        file: reader.result,
        origin: "notBase64",
      };
      setUpdateimagedata((prevFiles) =>{
        if (prevFiles?.length > 0) {
            return [...prevFiles, newFile];
          } else {
            return [newFile];
          }
    });
    };
    reader.onerror = (error) => {
      return error;
    };
    imageFormdataRef.current = [...imageFormdataRef.current,image]
  };

  let handleAddFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = true;
    input.addEventListener("change", handleFilesSelected);
    input.click();
  };

  let handleFilesSelected = (event) => {
    const selectedFiles = Array.from(event.target.files);
    selectedFiles.forEach(async (file) => {
      const image = await compressImage(file)
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const newFile = {
          name: file.name,
          size: bytesToSize(image.size),
          file: reader.result,
          origin: "notBase64",
        };
        setUpdateimagedata((prevFiles) => [...prevFiles, newFile]);
      };
      reader.onerror = (error) => {
        return error;
      };
      imageFormdataRef.current = [...imageFormdataRef.current,image]
    });
  };

  // function deleteImg(ele){
  //   const updatedImageData = UpdateImagedata && UpdateImagedata.filter((element) => ele !== element);
  //  setUpdateimagedata(updatedImageData);
  //   }

  const handleQuillPaste = (e) => {
    const clipboardData = e.clipboardData;
    const types = clipboardData.types || [];
    if (types.includes('Files')) {
      e.preventDefault();
    }
  }

  const handleCancelComment = () => {
    imageFormdataRef.current = [];
      setUpdateimagedata([]);
      commentForm.resetFields()
      commentForm.setFieldsValue({comment: ""})
      setShowcmnt({...showcmnt,openCommentInput:false})
  }

  const handleAddCommentClick = () => {
    showcmnt.openCommentInput ? handleCancelComment() : setShowcmnt({...showcmnt,openCommentInput:true})
    if (formRef.current) {
      if(!showcmnt.openCommentInput){
        formRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  const handleCommentForm = (values) => {
    if(values?.comment?.length){
      setCommentsCount((prevCommentCount) => prevCommentCount + 1);
      let urlData = {
        ...(values?.comment?.length > 0 ? {comment:values?.comment} : {}),
      }
      dispatch(bugReportHandle.resetCmntsData(item?._id));
      dispatch(bugReportHandle.bugReportComment(item?._id,urlData,imageFormdataRef.current))
      handleCancelComment()
    }else{
      message.error("Enter your comment!")
    }
  }

    useEffect(() => {
      let handlePasteEvent = async (event) => {
        let count = 0;
        var items = (event.clipboardData || event.originalEvent.clipboardData)
          .items;
        for (let index in items) {
          var item = items[index];
          if (item.kind === "file" && count === 0) {
            var blob = item.getAsFile();
            var reader = new FileReader();
            const ide = Math.floor(10000 + Math.random() * 90000);
            const imageName = "pastedImage" + ide;
            var updatedBlob = new File([blob], imageName, { type: blob.type });
            const image = await compressImage(updatedBlob)
            let checkImage = beforeUpload(updatedBlob);
            if (checkImage) {
              reader.onload = () => {
                const newFile = {
                  name: updatedBlob.name,
                  size: bytesToSize(image.size),
                  file: reader.result,
                  origin: "notBase64",
                };
                setUpdateimagedata((prevFiles) =>{

                  if (prevFiles.length) {
                      return [...prevFiles, newFile];
                    } else {
                      return [newFile];
                    }
              });
              };
              imageFormdataRef.current = [...imageFormdataRef.current,image]
            }
            reader.readAsDataURL(image);
            count = +count + 1;
          }
        }
      };
      let getModal = document;
      getModal.addEventListener("paste", handlePasteEvent);
      return () => {
        getModal.removeEventListener("paste", handlePasteEvent);
      };
    }, []);

  useEffect(() => {
    setCommentsCount(item?.totalComments?.comment)
  },[item])

    // useEffect(() => {
    //   if (showcmnt.openCommentInput) {
    //     if (inputRef.current) {
    //       inputRef.current.focus();
    //     }
    //   }
    // }, [showcmnt]);

    useEffect(() => {
      for(let key in getUserDetailsData?.roles){
        if(getUserDetailsData?.roles[key] === "RESURE_SUPPORT"){
          setRole(true);
          break;
        }else{
          setRole(false);
        }
      }
    },[getUserDetailsData?.roles])

    useEffect(() => {
      if(userModalVisible){
        if(userDataPage > 1 && searchUserdata.length <= 0){
          dispatch(bugReportHandle.getUsersData(userDataPage))
        }else if (searchUserdata.length > 0) {
          const timeoutId = setTimeout(() => {
            dispatch(bugReportHandle.getUsersData(userDataPage,searchUserdata))
          }, 1000);
          return () => clearTimeout(timeoutId);
        }
        if (userDataPage === 1) {
          dispatch(bugReportHandle.getUsersData(userDataPage))
        }
      }
    },[userDataPage,searchUserdata])


  useEffect(() => {
    if(modalVisible || showcmnt.openCommentInput){
      const focusTimeout = setTimeout(() => {
        if (quillRef.current) {
          quillRef.current.focus();
        }
      }, 100);
      return () => {
        clearTimeout(focusTimeout);
      };
    }
  }, [modalVisible,showcmnt]);

  return (
    <>
    <div>
    <Card size={(getUserDetailsData?.adminRole?.includes("SUPER_ADMIN") || getUserDetailsData?.adminRole?.includes("RS_SUPPORT")) || role ? "small" : "default"} style={{ marginBottom: "1rem",width:"100%",height:"100%",overflowX:"hidden", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",}}>
        <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
      <List.Item
      style={{ cursor: "default",padding:"0px 20px" }}
        key={item.title}
        actions={[
          // <IconText
          //   icon={StarOutlined}
          //   text={item.status}
          //   key="list-vertical-star-o"
          //   item={item}
          //   likedStatus={isliked}
          //   handle={handleStatus}
          // />,
          <IconTextStatus
            icon={StarOutlined}
            form={form}
            text={item.status}
            key="list-vertical-star-o"
            item={item}
            statusOptions={statusOptions}
            handleStatus={handleClose}
          />,
          <IconText
            icon={LikeOutlined}
            text={likesCount}
            key="list-vertical-like-o"
            item={item}
            likedStatus={!isliked}
            handle={handleLike}
          />,
        //   <Badge placement={'end'} count={item?.totalComments?.comment} showZero>
          <IconText
            icon={MessageOutlined}
            key="list-vertical-message"
            text={commentsCount}
            comments={item?.totalComments?.comment}
            // item={item}
            style={{
                color: commentsCount > 0 ? "green" : "grey",
                cursor: item.length > 0 ? "pointer" : "default",
              }}
            handle={()=>{handleComments(item._id)}}
          />,
          showcmnt.showAllComments && <IconText
            icon={PlusCircleFilled}
            key="list-vertical-message"
            text={"Add Comment"}
            style={{color: "#1990ff",cursor:"pointer"}}
            handle={handleAddCommentClick}
          />,
          // <Button style={{marginLeft: isMobile ? "35%" : spot9 ? "35%" : spot6 ? "70%" : spot2 ? "72%" : "80%",marginBottom:"5px"}} onClick={() => showcmnt.openCommentInput ? handleCancelComment() : setShowcmnt({...showcmnt,openCommentInput:true})}><PlusCircleFilled style={{color:"#1990ff"}}/>Add Comment</Button>,
        //   </Badge>,
          item.canDelete?(<DeleteOutlined
          style={{color:"cornflowerblue", marginLeft: !showcmnt.showAllComments ? "-40px" : ""}}
          onClick={()=>handleShowDeleteModal(item._id)
         }
          />):"",
        //   item.canDelete?(<EditOutlined onClick={"handleupdatemodal"} />):"",
            // text={item.comments},
            // key="list-vertical-message",
            // item={item},
          //   handle={handleComments},

        ]}
      >
        {(getUserDetailsData?.adminRole?.includes("SUPER_ADMIN") || getUserDetailsData?.adminRole?.includes("RS_SUPPORT") || role || isCreated) && <div style={{marginLeft:"95%",marginTop:"5px"}}>
            <span>
              <Dropdown overlay={<MenuItem item={item} />} trigger={['hover','click']}>
                <MoreOutlined
                  key="ellipsis"
                  style={{
                    fontSize: "26px",
                    color: "#08c",
                    marginTop: "-30px",
                  }}
                />
              </Dropdown>
            </span>
          </div>}
        <List.Item.Meta
              style={{cursor: "default",}}
          avatar={

            <Avatar
            size={65}
              className={styles.userAvatar}
              style={{
                background: "#085191",
                width:isMobile ? "55px" : "65px",
                height:isMobile ? "55px" : "65px",
                borderRadius:"50%",
                objectFit:"cover",
                }}
              src={item?.createdBy?.profileImg?.original}
            >
              {item && item?.createdBy && item?.createdBy?.firstName?.[0] && item?.createdBy?.firstName?.[0]?.toUpperCase()} {item && item.createdBy && item?.createdBy?.lastName?.[0] && item?.createdBy?.lastName?.[0]?.toUpperCase()}
            </Avatar>

          }
          title={<span className={styles.bugTitle} style={{minWidth:"400px",}} >{item?.title?item.title?.toUpperCase():""}</span>}
          description={
              <div className={styles.bugDescription} style={{marginTop: spot ? "45px" : "-15px",width:spot4 ? "100%" : (getUserDetailsData?.adminRole?.includes("SUPER_ADMIN") || getUserDetailsData?.adminRole?.includes("RS_SUPPORT")) || role ? "600px" : "100%",gap: (getUserDetailsData?.adminRole?.includes("SUPER_ADMIN") || getUserDetailsData?.adminRole?.includes("RS_SUPPORT")) || role ? "90px" : "30%",flexDirection: spot4 ? "row" : (spot2 && ((getUserDetailsData?.adminRole?.includes("RS_SUPPORT") || getUserDetailsData?.adminRole?.includes("SUPER_ADMIN")) || role)) ? "column" : spot2 ? "row" : ""}}>
                <div>
                <p  style={{marginBottom: "0px",}}>
              {(item?.createdBy?.firstName ? item?.createdBy?.firstName : "") + " " + (item?.createdBy?.lastName ? item?.createdBy?.lastName : "")}
                </p>
              <Paragraph ellipsis="rows" style={{color:"grey"}} copyable>{item?.createdBy?.email}</Paragraph>
                </div>

              <div style={{marginTop:spot5 ? "10px" : spot4 ? "5px" : spot3 && ((getUserDetailsData?.adminRole?.includes("RS_SUPPORT") || getUserDetailsData?.adminRole?.includes("SUPER_ADMIN") || role)) ? "-75px" : isTab? "-10px" : "5px", width:"100%"}}>
              <p>
               {item?.type === "BUGS" ? "Bug Id" : item?.type === "SUGGESTIONS" ? "Suggestion Id" : "Support Id"}: {item?._id}
              </p>
              <p style={{marginTop:"-15px"}}>
               Created Date: {moment(item?.createdAt).format("MM/DD/YYYY hh:mm A")}
              </p>
              <p style={{marginTop:"-15px"}}>
               Last Updated Date: {moment(item?.updatedAt).format("MM/DD/YYYY hh:mm A")}
              </p>
              {item?.assignedBy?.fullName ? <p style={{color:'#2875be',marginTop:'-15px'}}> Assigned By: {item?.assignedBy?.fullName}</p> : null}
              {Array.isArray(item?.platform)&& item?.platform?.length ? <p style={{color:'#2875be',marginTop:'-15px'}}> Platform: { item?.platform?.join(", ")}</p> : null}
              </div>
            </div>

          }
        />
        <div style={{marginTop:"-27px",}}>
         <Typography.Text style={{fontWeight:"bold",color:"darkgrey",}}>Description</Typography.Text>
        </div>
        <Row gutter={[4, 4]}>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <span style={{padding:"10px",maxWidth:"100%"}}>
            {item?.content?.map((el,ind)=>(<div style={{display:"flex",flexDirection:"column"}}>
                {el?.description &&
                <>
                {ind !== 0 &&
                <div style={{color:"#2976be"}}>
                   {`${moment(el?.addedDate).format('MMMM D, YYYY hh:mm A')} by ${el?.addedBy?.fullName}`}
                </div>
                }
                <div dangerouslySetInnerHTML={{__html:el?.description}}>
                </div>
                </>
                }
            </div>))}
            </span>
          </Col>
          </Row>
          <Row style={{marginTop:"10px"}}>
            <Typography.Text style={{fontWeight:"bold",color:"darkgrey"}}>Priority :</Typography.Text>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <span className={styles.content} style={{padding:"10px"}}>
              {item?.priority}
              </span>
          </Col>
          </Row>
          <Row >
          <Col style={{marginTop:"20px"}}>
        <Typography.Text style={{fontWeight:"bold",color:"darkgrey",marginTop:"20px",padding:"0px"}}>Attachments</Typography.Text>
          </Col>
          </Row>
          <Row>
          <div className={styles.imgsBox} style={{ overflowY: 'auto', maxHeight: '150px',marginTop:"10px"}}>
          <Col>
          <Image.PreviewGroup items={item?.imageUrls}>
              {item?.imageUrls?item.imageUrls?.map((ele,index)=>(
              <Image
               key={index}
               style={{padding:"20px",height:"120px"}}
               width={150}
               alt="logo"
               src={ele}
            />)):(<Image   style={{padding:"20px"}}
              width={180}
              alt="logo" src={"https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"}/>
              )}
              </Image.PreviewGroup>
          </Col>
          </div>
          </Row>
      </List.Item>
      <Col>
      {showcmnt.showAllComments && <>
      <div className={styles.cmntlistDiv}>

     <List
      className="demo-loadmore-list"
      loading={bugCommentsLoading}
      itemLayout="horizontal"
      size='small'
      dataSource={visibleCommentsData}
    //   dataSource={bugCommentsData && bugCommentsData?.filter((el) => el.id === item._id).map((item) => item.data.slice(0, visibleComments))}
      style={{width: '85%',marginBottom:"5px",}}
      renderItem={(item) => (
        <div style={{width:"100%",margin:"auto"}} ref={formRef}>
          {showcmnt.openCommentInput && <div className={styles.cmntInputBox} style={{marginTop:"10px"}}>
            {/* <Input ref={inputRef} autoFocus value={comment} style={{marginLeft:"48px",}} placeholder="Enter your comment here..."  onChange={(e)=>setComment(e.target.value)} /> */}
            <Form form={commentForm} onFinish={handleCommentForm} {...formItemLayout}>
              <Form.Item name="comment" onPaste={handleQuillPaste}>
                <ReactQuill
                  ref={quillRef}
                  style={{ minHeight: "120px", maxWidth: "100%",resize:"vertical",boxSizing: "border-box" }}
                  placeholder="Enter your comment here..."
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"], // toggled buttons
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }], // custom button values
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }], // superscript/subscript
                      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                      [{ direction: "rtl" }], // text direction
                      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                      [{ font: [] }],
                      [{ align: [] }],
                      ["clean"],// remove formatting button
                    ],
                  }}
                />
              </Form.Item>
              <Form.Item label={isMobile ? <div style={{display:"flex",marginTop:"5px"}}><span style={{color:"#1990ff" ,fontSize:"24px" ,cursor:"pointer"}} onClick={handleAddFile}><PlusCircleFilled /></span> <span style={{marginLeft:"5px",marginTop:"5px"}}>Images or Ctrl-V</span></div> : <div style={{marginTop:"5px",display:"flex"}}><div><span style={{color:"#1990ff" ,fontSize:"24px" ,cursor:"pointer"}} onClick={handleAddFile}><PlusCircleFilled /></span> <span style={{marginLeft:"5px"}}>Images or Ctrl-V</span></div></div>}>
              {UpdateImagedata && UpdateImagedata?.length > 0 ? (
                <div style={{display:"flex",}}>
                  {UpdateImagedata?.map((file, index) => (

                    <div key={index} style={{margin:"10px"}} >
                      <Image width={100} src={file.file} preview={{
                            visible:false,
                            mask: (
                              <div
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  zIndex: 21,
                                  top: "0px",
                                  right: "0px",
                                }}
                              >
                                <div
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                >
                                  <Popconfirm
                                    title="Are you sure you want to delete this image?"
                                    onConfirm={() => {
                                      deleteImg(index)
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <CloseCircleOutlined style={{ fontSize: "30px" }} />
                                  </Popconfirm>
                                </div>
                              </div>
                            ),
                          }}/>
                          <br />
                      <Text type="secondary">{file.name}</Text>
                      <br />
                      <Text type="secondary">Size: {file.size}</Text>
                    </div>
                  ))}
                  </div>
                ) : ""
              //   (
              //   <Dragger
              //     accept="image/*"
              //     showUploadList={false}
              //     customRequest={handleCustome}
              //     beforeUpload={beforeUpload}
              //     multiple
              //   />
              // )
              }
              </Form.Item>
              <Form.Item>
                <div style={{display:"flex",justifyContent:"center",gap:"20px",width:"80%",marginLeft:"10%"}}>
                <Button onClick={handleCancelComment} style={{width:"40%"}}>Cancel</Button>
                <Button type="primary" htmlType="submit" style={{width:isMobile ? "55%" : "40%"}}>Add Comment</Button>
                </div>
              </Form.Item>
            </Form>
       </div>}
          {item?.map((ele,ind) => {
            let tempElement = document.createElement('div');
            tempElement.innerHTML = ele?.comment || '';
            let pTags = tempElement.querySelectorAll('p');
            pTags.forEach(p => {
                p.style.margin = '1px';
                p.style.padding = '0px';
            });
            let modifiedComment = tempElement.innerHTML;
              return <List.Item key={ind}>
                <div style={{fontSize:"15px",}}>
                   <div>
                    <div style={{display:"flex"}}>
                    <span style={{fontWeight:"bold"}}>#{ind+1}.</span>
                    <span style={{color:"black",marginLeft:"8px"}} dangerouslySetInnerHTML={{ __html:modifiedComment }}></span>
                    </div>
                  </div>
                {ele?.imageUrls?.length > 0 && <div style={{marginLeft:"1.2rem",marginTop:"5px"}}>
                  <h4 style={{marginBottom:"-4px"}}>Attachments</h4>
                  <Image.PreviewGroup items={ele?.imageUrls}>
                    {ele?.imageUrls ? ele?.imageUrls?.map((ele,index)=>(
                    <Image
                    key={index}
                    style={{padding:"5px",height:"80px"}}
                    width={100}
                    alt="logo"
                    src={ele}
                  />)):(<Image   style={{padding:"20px"}}
                    width={180}
                    alt="logo" src={"https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"}/>
                    )}
                    </Image.PreviewGroup>
                </div>}
                <span style={{marginLeft:"1.4rem"}}> - </span>
                    <span style={{color:"#2976be",marginLeft:"5px"}}> {ele?.createdBy?.fullName}</span>
                    <span style={{marginLeft:"5px"}}>{moment(ele?.updatedAt).format("MMM DD, YYYY [at] HH:mm")}</span>
                    {ele?.createdBy?.email ==="support@resure.realestate" && ele?.createdBy?.fullName ==="RE-Sure Support" ?
                    <span>
                        <GoVerified style={{color:"green", marginLeft:"10px",}} />
                    </span> : null}
              </div>
            </List.Item>
      })}
          </div>
      )}
    />
    {bugCommentsData?.find((el) => el.id === item._id)?.data?.length === 0 ? <Empty style={{ width:"90%" }} image={Empty?.PRESENTED_IMAGE_SIMPLE} description={null}/> :<>
    {visibleComments < bugCommentsData?.find((el) => el.id === item._id)?.data?.length ? (
        <div style={{ textAlign: 'center', width:"90%" }}>
          <Button onClick={handleSeeMoreClick}>See More</Button>
        </div>
      ) : (
          <div style={{ textAlign: 'center', width:"90%" }}>
            {bugCommentsData?.find((el) => el.id === item._id)?.data?.length >3 ?
          <Button onClick={handleSeeLessClick}>See Less</Button> : null }
        </div>
      )}</>}
    </div>
    </>
      }
      </Col>
      </div>
    </Card>

    </div>
    <Modal
        title={<span style={{display:"flex",justifyContent:"center",fontSize:"17px",color:"grey",position: "sticky", top: 0, background: "#fff", zIndex: 1}}>UPDATE {renderComp === "listings" ? "BUG" : renderComp === "suggestions" ? "SUGGESTION" : "TICKET"} DETAILS</span>}
        visible={modalVisible}
        width={spot6 ? "80%" : isMobile ? "" : "60%"}
        onCancel={handleCancel}
        footer={false}
        style={{top:"5%"}}
      >
      <div style={{overflowY: "auto", maxHeight: "600px", padding: "0 10px"}}>
      <Form
          {...formItemLayout}
        onFinish={onFinish}
        form={form}
        name="register"
        scrollToFirstError
      >
        <Form.Item
          name="title"
          label="Title"
          initialValue={handleData?.[0]?.title}
          >
          <Input name="title" disabled/>
        </Form.Item>

        <Form.Item
          name="content"
          label="Add To Description"
          onPaste={handleQuillPaste}
          rules={[
              {
                  required: false,
                  message: "Please input Bug Description",
                },
            ]}
          >
          <ReactQuill
            ref={quillRef}
            style={{ height: "200px",  marginBottom: spot8 ? "82px":spot7 ? "100px":"120px", maxWidth: "100%" }}
            placeholder="Description"
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],
                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ direction: "rtl" }], // text direction
                [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],
                ["clean"],// remove formatting button
              ],
            }}
          />
        </Form.Item>

        <Form.Item name="platform" label="Platform" initialValue={handleData?.[0]?.platform}
         rules={[
          {
              required: true,
              message: "Please select a platform!",
            },
        ]}
        >
        <Checkbox.Group>
          <Checkbox value="MOBILE">Mobile</Checkbox>
          <Checkbox value="WEB">Web</Checkbox>
        </Checkbox.Group>
        </Form.Item>

        {(getUserDetailsData?.adminRole?.includes("SUPER_ADMIN") || getUserDetailsData?.adminRole?.includes("RS_SUPPORT")) || role ? <Form.Item
        name="assignedTo"
        label="Re-Assign To"
       >
        <Row>
          <Col span={20} style={{ marginRight: '15px' }}>
            <Input value={input} placeholder="Click on search button for list of users" disabled></Input>
          </Col>
          <Col span={2} style={{ alignSelf: "center", textAlign: "end" }}>
            <Button
              onClick={() => {
                if (usersData?.length === 0 || !input) {
                  dispatch(bugReportHandle.getUsersData(userDataPage));
                }
                setInput("")
                setSearchUserData("")
                setUserModalVisible(true)
              }}
              style={{ padding: "0px 6px" }}
            >
              <SearchOutlined style={{ fontSize: "18px" }} />
            </Button>
          </Col>
          </Row>
       </Form.Item> : null}

        <Form.Item name="priority" label="Priority" initialValue={handleData?.[0]?.priority}>
          <Select
            placeholder="Select Priority"
            showArrow
            mode="single"
            options={priorityOptions}
            size="medium"
          />
        </Form.Item>

        <Form.Item name="type" label="Move Ticket To" initialValue={handleData?.[0]?.type}>
          <Select
            placeholder="Select type"
            showArrow
            mode="single"
            options={typeOptions}
            size="medium"
            onChange={() => setTypeChanged(true)}
          />
        </Form.Item>

        <Form.Item label={isMobile ? <div><span style={{color:"#1990ff" ,fontSize:"27px" ,cursor:"pointer"}} onClick={handleAddFile}><PlusCircleFilled /></span> <span style={{marginLeft:"5px"}}>Images or Ctrl-V</span></div> : <div style={{marginTop:"30px"}}><div><span style={{color:"#1990ff" ,fontSize:"27px" ,cursor:"pointer"}} onClick={handleAddFile}><PlusCircleFilled /></span> <span style={{marginLeft:"5px"}}>Images</span></div><div style={{fontSize:"16px",marginLeft:"20px"}}>or Ctrl-V</div></div>}>
       {/*  */}
  {handleData?.[0]?.imageUrls?.length > 0 || UpdateImagedata && UpdateImagedata?.length>0 ? (
    <Form.Item>
    <div style={{display:"flex",maxWidth:isTab1 ? "100%" : "40vw",minHeight:"200px",overflow:"auto",boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"}}>
      {handleData && handleData[0]?.imageUrls?.map((file, index) => (
         <div key={index} style={{margin:"10px"}} >
            <Image width={100} src={file} preview={{
                visible:false,
                mask: (
                  <div
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      zIndex: 21,
                      top: "0px",
                      right: "0px",
                    }}
                  >
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <Popconfirm
                        title="Are you sure you want to delete this image?"
                        onConfirm={() => {
                          handleChangeImage(file,index);
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <CloseCircleOutlined style={{ fontSize: "30px" }} />
                      </Popconfirm>
                    </div>
                  </div>
                ),
              }}/>
        </div>
      ))}



{UpdateImagedata && UpdateImagedata?.length>0 ?(
    <Form.Item>
    <div style={{display:"flex",}}>
      {UpdateImagedata?.map((file, index) => (

        <div key={index} style={{margin:"10px"}} >
          <Image width={100} src={file.file} preview={{
                visible:false,
                mask: (
                  <div
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      zIndex: 21,
                      top: "0px",
                      right: "0px",
                    }}
                  >
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <Popconfirm
                        title="Are you sure you want to delete this image?"
                        onConfirm={() => {
                          deleteImg(index)
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <CloseCircleOutlined style={{ fontSize: "30px" }} />
                      </Popconfirm>
                    </div>
                  </div>
                ),
              }}/>
              <br />
          <Text type="secondary">{file.name}</Text>
          <br />
          <Text type="secondary">Size: {file.size}</Text>
        </div>
      ))}
      </div>

    </Form.Item>
  ): null}
      </div>
    </Form.Item>


  ) : (
    <Dragger
      accept="image/*"
      showUploadList={false}
      customRequest={handleCustome}
      beforeUpload={beforeUpload}
      multiple
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
       </p>
      <p className="ant-upload-text">
        Click or drag or paste file to this area to upload
      </p>
      <p className="ant-upload-hint">
        Support for multiple image uploads. Strictly prohibit from
        uploading company data or other banned files
      </p>
    </Dragger>
  )}
</Form.Item>
<Form.Item>
          {/* <Button type="primary" {...tailFormItemLayout} style={{width:isMobile ? "100%" : "90%",marginLeft:isMobile ? "" : "29%"}} loading={bugPostLoading} htmlType="submit">
            Save
          </Button> */}
          <div style={{display:"flex",justifyContent:"center",}}>
          <Button
                type="primary"
                style={{
                  width: isMobile ? "100%" : "55%",
                  textAlign:"center"
                }}
                htmlType="submit"
                loading={bugPostLoading}
              >
                Save
              </Button>
            </div>
        </Form.Item>
      </Form>
    </div>
      </Modal>

      {/* User Modal */}
      <UserModal userModalVisible={userModalVisible} setUserModalVisible={setUserModalVisible} searchUserdata={searchUserdata} setSearchUserData={setSearchUserData} setClientID={setClientID} setInput={setInput} userDataPage={userDataPage} setUserDataPage={setUserDataPage}/>
    </>
  );
};

export default EachCard;
