import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Menu, Col, Row } from "antd";
import { Genral } from "./components/Genral";
import {
  UserOutlined,
  UsergroupAddOutlined,
  LoginOutlined,
  DollarCircleOutlined,
  IdcardOutlined,
  RightCircleFilled,
  LeftCircleFilled,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from "@ant-design/icons";
import { useLocation } from "react-router";
import { breadcrumHandle } from "../Breadcrum/state/actions";
import Delegate from "./components/Delegate";
import Pricing from "../Pricing/Pricing";
import Logs from "./components/Logs";
import styles from "../Account/styles/Accounts.module.css";
import "./styles/Account.css";
import { Switch, Route, useHistory } from 'react-router-dom';
import EmailSignature from "./components/EmailSignature";
const { Content, Sider } = Layout;

function Account(props) {
  const history = useHistory();

  //no access to user account
  const { getUserDetailsData } = useSelector((state) => state.account);
  useEffect(() => {
    if (getUserDetailsData?.delegateId?._id && !getUserDetailsData?.delegateId?.adminRole?.includes("SUPER_ADMIN")) {
      history.push("/error");
    }
  }, [getUserDetailsData])

  useEffect(() => {
    if (props.networkConnection === false) {
      window.location.reload();
    }
  }, [props]);
  const dispatch = useDispatch();

  const { pathname, state } = useLocation();
  const [comingFrom, setcomingfrom] = useState(state?.flag || false);
  const [collapsed, setCollapsed] = useState(false);
  useEffect(() => {
    dispatch(breadcrumHandle.updateBreadCrum(pathname));
    if (pathname.includes("/account/delegates/transactions")) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [pathname]);

  let handleSubMenuChange = (e) => {
    if (e.key === "1") {
      history.push("/account");
    }
    else if (e.key === "2") {
      history.push("/account/delegates");
    }
    else if (e.key === "3") {
      history.push("/account/delegateLogs");
    }
    else if (e.key === "4") {
      history.push("/account/pricing");
    }
    else if (e.key === "5") {
      history.push("/account/emailSignature");
    }
  };

  return (
    <Layout className={styles.account}>
      <Content>
        <div>
          <Row style={{ padding: "0rem 0.5rem", display: "flex" }}>
            <Col>
              <div
                className="mozScrollbar"
                style={{
                  padding: "3px",
                  border: "1px solid  rgba(0,0,0,0.2)",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  transition: "0.3s",
                  height: "calc(100vh - 110px)",
                }}
              >
                <Sider className="account-site-layout-background"
                  width={"100%"}
                  onCollapse={(collapse) => setCollapsed(collapse)}
                  collapsed={collapsed}
                  collapsible={pathname.includes("/account/delegates/transactions") ? true : false}
                  style={{height: "20px"}}
                  // trigger={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  trigger={collapsed ? <RightCircleFilled style={{fontSize: "20px"}} /> : <LeftCircleFilled style={{fontSize: "20px"}} />}
                >
                  <Menu
                    mode="inline"
                    selectedKeys={(pathname.startsWith("/account/delegates")) ? ["2"] : ((pathname.startsWith("/account/delegateLogs")) ? ["3"] : ((pathname.startsWith("/account/pricing")) ? ["4"] : (pathname.startsWith("/account/emailSignature")) ? ["5"] : ["1"]))}
                    style={{ minHeight: "30vh", fontSize: "22px", }}
                    onClick={handleSubMenuChange}
                  >
                    <Menu.Item
                      key="1"
                      icon={<UserOutlined style={{ fontSize: "20px" }} />}
                    >
                      General
                    </Menu.Item>
                    <Menu.Item
                      key="2"
                      icon={<UsergroupAddOutlined style={{ fontSize: "22px" }} />}
                    >
                      Delegates
                    </Menu.Item>
                    <Menu.Item
                      key="3"
                      icon={<LoginOutlined style={{ fontSize: "22px" }} />}
                    >
                      Delegate Logs
                    </Menu.Item>
                    <Menu.Item
                      key="4"
                      icon={<DollarCircleOutlined style={{ fontSize: "22px" }} />}
                    >
                      Plans
                    </Menu.Item>
                    <Menu.Item
                      key="5"
                      icon={<IdcardOutlined style={{ fontSize: "22px" }} />}
                    >
                      Email Signature
                    </Menu.Item>
                  </Menu>
                  {/* <div style={{}}>
                  <MenuFoldOutlined style={{ background: "white", fontSize: "19px" }} />
                </div> */}
                </Sider>
              </div>
            </Col>
            <Col xs={20} sm={19} md={collapsed ? 22 : 20} lg={collapsed ? 22 : 20} xl={collapsed ? 22 : 20}>
              <div
                onClick={() => setcomingfrom(false)}
                style={{
                  border: "1px solid  rgba(0,0,0,0.2)",
                  marginLeft: "5px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  transition: "0.3s",
                  height: "calc(100vh - 110px)",
                  width: "100%"
                }}
              >
                <Switch>
                  <Route
                    exact
                    path="/account/emailSignature"
                    component={EmailSignature}
                  />
                  <Route
                    exact
                    path="/account/pricing"
                    component={Pricing}
                  />
                  <Route
                    exact
                    path="/account/delegateLogs"
                    component={Logs}
                  />
                  <Route
                    path="/account/delegates"
                    component={Delegate}
                  />
                  <Route
                    exact
                    path="/account"
                  >
                    <Genral comingFrom={comingFrom} />
                  </Route>
                </Switch>
              </div>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}

export { Account };