/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect,useState } from "react";
import { EmptyData } from "../../Common/components/EmptyData";
import { Button, Divider, Form, message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Loading } from "../../Common/components/Loading";
import SearchPersonByEmail from "../../Common/components/SearchPersonByEmail";
import { PropertyHandlers } from "../../PropertyComp/state/actions";
import axios from "axios";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import { documentAction } from "../../DocumentSection/state/actions";
import PersonsList from "../../Common/components/PersonsList";

const EditAgentsModal = ({ isModalOpen, section, setIsModalOpen, item, filterSort }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { getIndividualTransactionData, triggerIndividualTransactionFetch } = useSelector((state) => state.rtdTransactionLane, shallowEqual);
  const { agentsArray, agentDetailsArray, agentDetailsLoading } = useSelector(
    (state) => ({
      agentsArray: state.documentSection.CorpReducer.agentsArray || [],
      agentDetailsArray: state.PropertyReducer.agentDetailsArray || [],
      agentDetailsLoading: state.PropertyReducer.agentDetailsLoading,
    }),
    shallowEqual
  );

  const delegateSide = section === "DELEGATE" ? "SELLER" : undefined;
  const agentDetails = item.currentAgentId;

  useEffect(() => {
    if (getIndividualTransactionData?.offerSellerAgentIds) {
      dispatch(documentAction.setCorpState({ agentsArray: getIndividualTransactionData.offerSellerAgentIds }));
    }
  }, [getIndividualTransactionData]);

  useEffect(() => {
    let unmounted = { value: false };
    let source = axios.CancelToken.source();

    dispatch(
      transactionLaneDataHandle.getIndividualTransaction({
        offerId: item?.offerId,
        ertsId: item?.persistId,
        propertyId: item?.propertyId,
        setLoading,
        unmounted,
        source,
        delegateSide,
        delegateOwnerId: delegateSide && agentDetails?._id,
      })
    );

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    };
  }, [triggerIndividualTransactionFetch]);

  const handleCancel = () => {
    setIsModalOpen((prev) => ({ ...prev, editAgentModalVisible: false }));
    dispatch(documentAction.setCorpState({ agentsArray: [] }));
    dispatch(PropertyHandlers.setAgentDetailsData({ agentDetailsArray: [] }));
  };

  const handleAgentSelected = (agent) => {
    if (agentsArray.some((client) => client._id === agent._id)) {
      message.error(`${agent.fullName} already exists in the list.`);
      return;
    }

    Modal.confirm({
      title: `Are you sure you want to add ${agent.fullName || "this Agent"} to the to the list of broker agents?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(documentAction.setCorpState({ agentsArray: [...agentsArray, agent] }));
        dispatch(PropertyHandlers.setAgentDetailsData({ agentDetailsArray: [] }));
        message.success(`${agent.fullName || "Agent"} added to the list.`);
      },
    });
  };

  const handleAddAgents = () => {
    if (!agentsArray.length) {
      message.error("You must have at least one broker for this listing.");
      return;
    }
    Modal.confirm({
      title: `Are you sure you want to continue with these Brokers?`,
      content: "",
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const payloadData = {
          propertyId: item?.propertyId,
          agentIds: agentsArray.map((el) => el._id),
          ...(section === "DELEGATE" && (item.isProperty || item?.isErts) ? { delegateSide: "SELLER", delegateOwnerId: agentDetails?._id } : {}),
        };
        const currentAgentDeleted = !payloadData.agentIds.includes(String(item.currentAgentId._id));
        if (payloadData.agentIds?.length) {
          dispatch(PropertyHandlers.editAgents({ payloadData, currentAgentDeleted, filterSort }));
        }
        if (currentAgentDeleted) {
          handleCancel();
        }
      },
    });
  };

  const handleDeleteAgent = (agent) => {
    Modal.confirm({
      title: `Are you sure you want to remove ${agent.fullName || "this Agent"} from the list of broker agents?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(documentAction.setCorpState({ agentsArray: agentsArray.filter((el) => el._id !== agent._id) }));
        message.success(`${agent.fullName || "Agent"} removed from the list.`);
      },
    });
  };

  const handleSearchByEmail = (values) => {
    dispatch(PropertyHandlers.getAgentDetails({ query: { email: values.email } }));
    form.resetFields();
  };

  return (
    <Modal
      visible={isModalOpen?.editAgentModalVisible}
      width={700}
      title={<h2 style={{ color: "grey", textAlign: "center" }}>Seller Broker-Agents</h2>}
      onCancel={handleCancel}
      footer={
        !loading && item?.isProperty ? (
          <div style={{ display: "flex", justifyContent: "center", gap: "4%" }}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" onClick={handleAddAgents}>
              Save
            </Button>
          </div>
        ) : null
      }
      style={{ top: "20px" }}
    >
      <div style={{ top: "20px", height: "60vh", overflow: "auto" }}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <SearchPersonByEmail form={form} handleSearchByEmail={handleSearchByEmail} />
            <div>{agentDetailsLoading ? <Loading /> : agentDetailsArray.length ? <PersonsList dataSource={agentDetailsArray} onItemClick={handleAgentSelected} hoverable /> : null}</div>
            <Divider />
            <h2 style={{ color: "gray" }}>Selected Broker-Agents</h2>
            <div style={{ width: "100%", marginBottom: "10px" }}>
              {agentsArray.length > 0 ? (
                <PersonsList dataSource={agentsArray} onDelete={handleDeleteAgent} showDeleteIcon />
              ) : (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "100px" }}>
                  <EmptyData />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default EditAgentsModal;
