/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState,useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import { Button, Form, Image, Modal, Collapse, Input, message, Tooltip } from "antd";
// import { propertyDataHandle } from "../state/actions";
import { Checkbox } from "antd"
import debounce from 'lodash/debounce';
// import AddEmail from "./AddEmail";
import EmailTemplateModal from "../../Common/components/EmailTemplateModal";
import ContentEditable from "../../Common/components/ContentEditable";
import { PropertyHandlers } from "../state/actions";
import AddEmailModal from "./AddEmailModal";
import { mapClientsForSendEmail } from "../utils/helper";
import DocumentList from "../../Rtd/component/DocumentList";
import { Select } from 'antd';
import { accountHandle } from "../../Account/state/actions";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { ClientList } from "../../Client/components/ClientList";
import { generateFullNameFromObj } from "../../Common/utils/extraFunctions";
import { useMediaQuery } from "react-responsive";
import { TiContacts } from "react-icons/ti";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import RecipientList from "../../Common/components/RecipientList";

const { Option } = Select;

// This is the new design in send document
const EmailModal = ({
    role,
    client,
    source,
    section,
    ispdfClone,
    documentId,
    selectedData,
    contractType,
    signerChecked,
    initialMountRef,
    setSignerChecked,
    isEmailModalVisible,
    currentPropertyDocId,
    setNestedModalOpen,
    nestedModalOpen,
    delegateOwnerId,
    RTDInitiator,
    newState,
    setNewState,
    reloadSection,
  componentUsedSection,
  allBuyerAgentData,
  allSellerAgentData,
    screenSource
     }) => {

  const delegate =  (section === "DELEGATE");
  const [clientsArray, setClientsArray] = useState([]);
  const [Email_templatevisible, setEmail_templatevisible] = useState(false);
  const [selectedtext, setSelectedText] = useState("");
  const [emailSignatureId, setEmailSignatureId] = useState({});
  const [ispreview, setIspreview] = useState(false);
  const [value, setValue] = useState("");
  const [page, setPage] = useState([1]);
  const [showClientListModal, setShowClientListModal] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(true);
  const typingTimeoutRef = useRef(null); 
  const [emailList, setEmailList] = useState({
    emailMessage: "",
  });
  
  
  let payloadData = {};
  const { clickedItem } = currentPropertyDocId || {};
  
  const initialState = {
    documentSelected: [],
    clientDocumentSelected:[],
    propertyDocsSelected: [],
    sharedSelected: [],
  };
  const [data,setData] = useState(initialState);
  const { emailSignData, emailSignLoading } = useSelector(
    (state) => state.account,
  );

  const { getIndividualTransactionData } = useSelector((state) => state.rtdTransactionLane);
  const history = useHistory();
  const {pathname} = useLocation();
  const breakPoint = useMediaQuery({maxWidth:"570px"})

  useEffect(() => {
      const defaultSignVal = emailSignData?.find(sign => sign.isDefault === true) || { isDefault: false, _id: '' };
      setEmailSignatureId({ ...defaultSignVal });

    return () => {
      setEmailSignatureId({ checked: false, value: '' });
    };
  }, [emailSignData]);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Panel } = Collapse;

  const handleEmail_templateCancel = () => {
    setEmail_templatevisible(false);
  };

  const handleInput = (event) => {
    setEmailList({
      ...emailList,
      emailMessage: event.target.innerHTML,
    });
  };

  const handleselectemail = (value) => {
    <div dangerouslySetInnerHTML={{ __html: selectedtext }} />;
    setSelectedText(value);
    setEmailList({
      ...emailList,
      emailMessage: value,
    });
    setEmail_templatevisible(false);
  };

  useEffect(() => {
    let tempArr = [];
    switch (source) {
      case "SELLING_DRAFT":
      case "SELLING_INPROGRESS":
      case "SELLING_RTD":
        tempArr = mapClientsForSendEmail(selectedData?.sellerIds, "SELLER");
        break;
      case "PROPERTY_DOCS":
        tempArr =
          role === "BUYER"
            ? mapClientsForSendEmail(selectedData?.buyerIds, "BUYER")
            : mapClientsForSendEmail(selectedData?.sellerIds, "SELLER");
        break;
      case "BUYING_DRAFT":
      case "BUYING_RTD":
        tempArr = mapClientsForSendEmail(selectedData?.buyerIds, "BUYER");
        break;
      case "CLIENT_DOC":
        tempArr =
          role === "BUYER"
            ? mapClientsForSendEmail(selectedData?.buyerIds, "BUYER")
            : mapClientsForSendEmail(selectedData?.sellerIds, "SELLER");
        break;
      default:
        tempArr = selectedData?.acceptedOfferSellers?.map(seller => ({
          openFor: "SELLER",
          seller: seller?.fullName || null,
          key: seller?._id || null,
          name: seller?.fullName || null,
          emailPresent: !!seller?.email,
          email: seller?.email || null
        }));
        break;
    }
    setClientsArray(tempArr);
    const initialChecked = {};
    tempArr?.forEach(client => {
      initialChecked[client._id] = { checked: !!client.personId?.email,isReadOnly: client.isReadOnly,isAddedByUser:false, row: client };
      if (client.isCorp) {
        client?.signers?.forEach(signer => {
          initialChecked[signer._id] = { checked: !!signer.personId?.email,isReadOnly: client.isReadOnly,isAddedByUser:false, row: signer };
        });
      }
    });
    setSignerChecked(initialChecked);
    return () => {
      initialMountRef.current = false;
    };
  }, [selectedData, source, role]);




  let unmounted = {
    value: false
  };
  let sourceAxios = axios.CancelToken.source();

useEffect(() => {
  dispatch(accountHandle.getEmailSignData({unmounted, source:sourceAxios}));

  return () => {
          unmounted.value = true;
          sourceAxios.cancel("Cancelling in cleanup");
          setData({
            documentSelected: [],
            propertyDocsSelected: [],
            sharedSelected: []
          });
        }
}, []);

  useEffect(() => {
    if (!initialMountRef.current && Object.keys(signerChecked)?.length > 0) {
      handleSelectAllChange(true);
      initialMountRef.current = true;
    }
  }, [signerChecked, isEmailModalVisible]);

  const handleEmailCancel = () => {
    setNestedModalOpen({...nestedModalOpen,isEmailModalVisible:false})
    setEmailList({
      emailMessage: "",
    });
    setSelectedText("");
    setSignerChecked({});
  };


  function processNestedDataforReadOnly(data) {
    let results = [];

    const isMongoDBObjectID = id => /^[a-f\d]{24}$/i.test(id);

    if (data && data.length) {
      data.forEach(item => {
        if (!item?.isCorp && item?.isReadOnly) {
          if (item.personId?._id && isMongoDBObjectID(item.personId._id)) {
            // Only send personId if it's a valid MongoDB ObjectID
            results.push({ personId: item.personId._id });
          } else {
            // Otherwise send email
            results.push({
              ...item.personId?.email && { email: item.personId.email },
            });
          }
        } else if (item?.isCorp && item?.signers?.length > 0) {
          // Handle the case for corps
          item.signers.forEach(signer => {
            if (signer.isReadOnly) {
              if (signer.personId?._id && isMongoDBObjectID(signer.personId?._id)) {
                results.push({ personId: signer.personId._id });
              } else {
                results.push({
                  ...signer.personId?.email && { email: signer.personId.email },
                });
              }
            }
          });
        }
      });
    }

    return results;
  }


  const handleSend = () => {
      const arr = Object.values(signerChecked)
      .filter((el) => el.checked && !el?.isReadOnly && !el?.isAddedByUser)
      .map((el) => ({
         ...(el?.row?.personId?._id ? {clientId: el?.row?.personId?._id}:{}),
         ...(el?.row?.openFor ? {openFor: el?.row?.openFor}:{}),
         ...(el?.row?.isCorp ? {key: el?.row?.corpKey} : el?.row?.personId?._id ? {key:el?.row?.personId?._id}:{}),
        })).filter(el => Object.keys(el).length !== 0);


    const readonlyClients = processNestedDataforReadOnly(clientsArray);
    if(nestedModalOpen?.sendMultipleDoc){

        let params={
        clientArray: arr,
          ...(readonlyClients.length ? { readOnlyEmailsArray: readonlyClients } : {}),
          ...(data?.documentSelected.length ? { documentIds: [...data?.documentSelected] }: {}),
          ...(data?.sharedSelected.length ? { sharedFolderDocsArray: [...data?.sharedSelected] }: {}),
          ...(data?.propertyDocsSelected.length ? { propertyDocsArray: [...data?.propertyDocsSelected] }: {}),
          ...(data?.clientDocumentSelected.length ? { contactDocArray: [...data?.clientDocumentSelected] }: {}),
          ...(emailSignatureId?.isDefault && emailSignatureId?._id ? { emailSignatureId: emailSignatureId?._id }: {}),
          sentBy: role === "BUYER" ? "BUYER_AGENT" : "SELLER_AGENT",
          ...(emailList.emailMessage ?
            {emailMessage: emailList.emailMessage.charAt(0).toUpperCase() + emailList.emailMessage.slice(1),} : {}),
          ...(delegate ? { delegateSide: role === "BUYER" ? "BUYER" : "SELLER" } : {}),
    };
    if (RTDInitiator ) {
        params = {
        ...params,
          ...(getIndividualTransactionData?.thirdpartyId ? { thirdpartyIdDoc: getIndividualTransactionData?.thirdpartyId }: {}),
          ...(getIndividualTransactionData?.offerId ? { offerId: getIndividualTransactionData?.offerId } : {}),
          ...(getIndividualTransactionData?.persistId ? { ertsId: getIndividualTransactionData?.persistId } : {}),
          ...(getIndividualTransactionData?.propertyId ? { propertyId: getIndividualTransactionData?.propertyId } : {}),
        };
      } else {
        params = {
            ...params,
          ...(selectedData?.thirdpartyId ? { thirdpartyIdDoc: selectedData?.thirdpartyId } : {}),
          ...(selectedData?.offerId ? { offerId: selectedData?.offerId } : {}),
          ...(selectedData?.persistId ? { ertsId: selectedData?.persistId } : {}),
          ...(selectedData?.propertyId ? { propertyId: selectedData?.propertyId } : {}),

        };
      }
       dispatch(PropertyHandlers.SendMultipleDoc(params));
   }
    else if(nestedModalOpen?.isAgent){
        payloadData = {
          documentId: documentId,
          data: { ...(RTDInitiator === "BUYER" ? { showSeller: true } : { showBuyer: true }), contractType },
          contractType,
          client: client,
          offerId: selectedData?.offerId,
          ...(emailList.emailMessage ? { emailMessage: emailList.emailMessage.charAt(0).toUpperCase() + emailList.emailMessage.slice(1) } : {}),
          ...(delegate ? { delegateSide: role === "BUYER" ? "BUYER" : "SELLER" } : {}),
          ...(emailSignatureId?.isDefault && emailSignatureId?._id ? { emailSignatureId: emailSignatureId?._id } : {}),
        };
        dispatch(transactionLaneDataHandle.updateDocument(payloadData));
    }
    // else if (nestedModalOpen?.sendMultipleDoc && source === "PROPERTY_DOCS" && clickedItem?.offerDocument) {

    //   payloadData = {
    //     clientArray: arr,
    //     ...(emailList.emailMessage ? { emailMessage: emailList.emailMessage.charAt(0).toUpperCase() + emailList.emailMessage.slice(1) } : {}),
    //     ...(selectedData?.propertyId ? { propertyId: selectedData.propertyId } : {}),
    //     ...(selectedData?.offerId ? { offerId: selectedData.offerId } : {}),
    //     ...(selectedData?.persistId ? { ertsId: selectedData.persistId } : {}),
    //     ...(selectedData?.thirdpartyId ? { thirdpartyIdDoc: selectedData.thirdpartyId } : {}),
    //     // ...(data?.documentSelected.length ? { documentIds: [...data?.documentSelected] }: {}),
    //     //   ...(data?.sharedSelected.length ? { sharedFolderDocsArray: [...data?.sharedSelected] }: {}),
    //     //   ...(data?.propertyDocsSelected.length ? { propertyDocsArray: [...data?.propertyDocsSelected] }: {}),
    //     documentId: clickedItem.documentId,
    //     offerDocument: true,
    //     ...(delegate ? ((role === "SELLER") ? {delegateOwnerId: selectedData?.sellerAgentId?._id || selectedData?.sellerAgentId} : {delegateOwnerId: selectedData?.buyerAgentId?._id || selectedData?.buyerAgentId }) : {}),
    //     ...((emailSignatureId?.isDefault && emailSignatureId?._id) ? {emailSignatureId:emailSignatureId?._id}:{})
    //   };
    //   dispatch(PropertyHandlers.sharePropertyDocs(payloadData, false));
    //  }
    else {
        const roleSpecificPayload = role === "SELLER" ? { sentBy: "SELLER_AGENT", offerId: selectedData?.offerId } : { sentBy: "BUYER_AGENT", offerId: selectedData?._id };
      payloadData = {
        ...roleSpecificPayload,
        clientArray: arr,
        documentId,
        ...(readonlyClients.length ? { readOnlyEmailsArray: readonlyClients } : {}),
        ...(selectedData?.persistId ? { ertsId: selectedData.persistId } : {}),
        ...(selectedData?.thirdpartyId?.length > 0 ? { thirdpartyIdDoc: selectedData.thirdpartyId } : {}),
        ...(role === "SELLER" ? { offerId: selectedData.offerId } : { offerId: selectedData?._id }),
        ...(emailList.emailMessage ? { emailMessage: emailList.emailMessage.charAt(0).toUpperCase() + emailList.emailMessage.slice(1) } : {}),
        ...(delegate ? ((role === "SELLER") ? {delegateSide: "SELLER"} : {delegateSide: "BUYER" }) : {}),
        ...((emailSignatureId?.isDefault && emailSignatureId?._id) ? {emailSignatureId:emailSignatureId?._id}:{})
      };
      dispatch(transactionLaneDataHandle.sendEmailToClients(payloadData));
    }
    handleEmailCancel();
  };

  const successFunc = ({reducerFunc}) => {
    if(componentUsedSection === "TRANSACTION_DASHBOARD"){
      if(reducerFunc){
        dispatch(reducerFunc());
      }
    }
    else{
      history.push({
        pathname,
        state: {
          category: role,
          categoryId: selectedData?.offerId
        }
      })
    }
  }

const handleSelectAllChange = (checked) => {
    const updatedSignerChecked = {};

    const updatedClientsArray = clientsArray.map((seller) => {
      if (!seller.isCorp && seller.personId?.email) {
        updatedSignerChecked[seller._id] = { checked, isAddedByUser:seller?.isAddedByUser, row: seller };
        // Preserve isReadOnly if isAddedByUser is true
        return seller.isAddedByUser ? { ...seller, isReadOnly: true } : { ...seller };
      } else if (seller.isCorp) {
        const updatedSigners = seller?.signers.map((signer) => {
          if (signer.personId?.email) {
            updatedSignerChecked[signer._id] = { checked, isAddedByUser:signer?.isAddedByUser, row: signer };
            // Preserve isReadOnly if isAddedByUser is true
            return signer.isAddedByUser ? { ...signer, isReadOnly: true }: { ...signer };
          }
          return signer;
        });
        return { ...seller, signers: updatedSigners };
      }
      return seller;
    });

    setSignerChecked(updatedSignerChecked);
    setClientsArray(updatedClientsArray);
  };

const handleClientsCheckboxChange = (sellerId, checked) => {
    setSignerChecked((prev) => ({
      ...prev,
      [sellerId]: {
        checked,
        isReadOnly:checked,
        isAddedByUser: prev[sellerId]?.isAddedByUser,
        row: prev[sellerId]?.row,
      },
    }));

    setClientsArray((prev) =>
      prev.map((client) =>
        client._id === sellerId ? { ...client, isReadOnly: checked } : client
      )
    );
  };


  const CorporationCheckbox = ({ corporation = {} }) => {
    const [isCorpChecked, setIsCorpChecked] = useState(() => {
      const isInitiallyChecked = corporation?.signers?.every((signer) => signer.personId?.email && signerChecked[signer._id]?.checked);
      return isInitiallyChecked || false;
    });

    const handleCheckboxChange = (signerId, checked) => {
      setSignerChecked((prev) => ({
        ...prev,
        [signerId]: { checked,  isReadOnly: checked, row:prev[signerId]?.row },
      })
    );

    setClientsArray((prevData) =>
        prevData.map((client) => {
        if (client.signers && client.signers.length > 0) {
            const updatedSigners = client.signers.map((signer) =>
            signer._id === signerId
                ? { ...signer, isReadOnly: false }
                : signer
            );
            return { ...client,isReadOnly: false, signers: updatedSigners };
        }

        return client;
        })
    );

      if (corporation?.signers) {
        // Checking if all child checkboxes are checked
        const allChildChecked = corporation.signers.every((signer) => signer.personId?.email && signerChecked[signer._id]?.checked);
        // Updating the state of the parent checkbox
        setIsCorpChecked(allChildChecked);
      }
    };


    const handleParentCheckboxChange = (checked) => {
      setIsCorpChecked(checked);
      if (corporation?.signers) {
        corporation?.signers?.forEach((signer) => {
          if (signer.personId?.email) {
            setSignerChecked((prev) => ({
              ...prev,
              [signer._id]: { checked,isReadOnly:false, row: prev[signer._id]?.row },
            }));
          }
        });
      }

     setClientsArray(prev=>
        prev.map((client)=>{
            if(client?.signers && client.signers.length > 0){
                const updatedSigners = client.signers.map((signer)=>({...signer, isReadOnly:false}));
                return { ...client,isReadOnly:false, signers: updatedSigners };
            }
            return client
        })
     )
    };


    const handleUpdateReadOnlyCorp = (signerId, checked) => {
        const updatedReadOnly = checked;

        setClientsArray((prevData) =>
            prevData.map((client) => {
            if (client.signers && client.signers.length > 0) {
                const updatedSigners = client.signers.map((signer) =>
                signer._id === signerId
                    ? { ...signer, isReadOnly: updatedReadOnly }
                    : signer
                );
                return { ...client,isReadOnly: updatedReadOnly, signers: updatedSigners };
            }

            return client;
            })
        );

        setSignerChecked((prev) => ({
          ...prev,
          [signerId]: { checked:true, row: prev[signerId]?.row, isReadOnly: checked },
        }));
      };



    return (
      <div>
        <Checkbox checked={isCorpChecked} onChange={(e) => handleParentCheckboxChange(e.target.checked)} style={breakPoint?{fontSize: "10px", fontWeight:"bold", color: "orange"}:
        { fontSize: "20px", color: "orange" }}>
          {corporation?.fullName || ""} {"Signers"}
        </Checkbox>
        {corporation?.signers?.map((signer) => (
          <div style={breakPoint ? {marginLeft: "10px", display: "flex", alignItems: "center", fontSize: "10px"}:
          { marginLeft: "20px", display: "flex", alignItems: "center", whiteSpace: "nowrap", fontSize: "18px" }} key={signer?._id}>
            <Checkbox checked={signerChecked[signer._id]?.checked} onChange={() => handleCheckboxChange(signer._id, !signerChecked[signer._id]?.checked)} disabled={!signer.personId?.email} style={{ marginTop: "0px" }} />
            <div style={{ display: "flex", alignItems: "center", marginLeft: "10px",}}>
              <span style={breakPoint ? {fontSize: "10px",fontWeight:"bold", cursor: "context-menu"} :{ fontSize: "20px", cursor: "context-menu" }}>{signer?.fullName}</span>
              <span style={{ fontSize: "25px", fontWeight: "bold", cursor: "context-menu", marginLeft: "5px", marginRight: "5px" }}>-</span>
              <span style={breakPoint?{fontSize: "10px", color: "blue", textDecoration: "underline"}:
              { fontSize: "20px", color: "blue", textDecoration: "underline" }}>
                {signer.personId?.email ? (
                  <span style={breakPoint ? { fontSize: "10px", color: "blue", cursor: "context-menu", textDecoration: "underline"} : { fontSize: "20px", color: "blue", cursor: "context-menu", textDecoration: "underline" }}>
                    {signer.personId.email}
                    <span style={breakPoint ? {fontSize:"10px", marginLeft:"5px"} : {marginLeft:"10px", fontWeight:"bold"}}>
                        <Checkbox  checked={signerChecked[signer._id]?.isReadOnly}
                          onChange={(event)=>handleUpdateReadOnlyCorp(signer._id, event.target.checked)}>
                            <span  style={{color:"gray"}}>Read Only</span>
                        </Checkbox>
                    </span>
                </span>
                ) : (
                  <AddEmailModal offerId={selectedData?.offerId ? selectedData?.offerId : getIndividualTransactionData?.offerId} clientData={signer} successFunc={successFunc} role={role} delegate={delegate}/>
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

const handleSelectChange =(id)=>{
   let [filteredValue] = emailSignData?.filter((el)=>el?._id===id).map((el)=>{return {...el, isDefault:true}});
   setEmailSignatureId(filteredValue);
}

const checkClient = (data, selectedClient) => {
    for (const client of data) {
      if (client.isCorp) {
        // If isCorp is true, loop over the signers array
        for (const signer of client.signers) {
          if (signer.personId._id === selectedClient._id) {
            return true;
          }
        }
      } else {
        // If isCorp is false, compare personId._id directly
        if (client.personId._id === selectedClient._id) {
          return true;
        }
      }
    }
    return false;
  };

  // when the user select any client in the clientList
  const handleClientSelected = (selectedClient) => {
    if (!selectedClient?.email) {
      message.error("Please add the client's email to proceed!");
      return;
    }
    const clients = clientsArray || [];

    if (clients.length >= 10) {
      message.error(`Maximum number of clients already added.`);
      return;
    }

    let check = checkClient(clientsArray, selectedClient);

    if (check) {
      message.error(`User already exists in read only recepients list.`);
      return;
    } else {
      setClientsArray(
        [...clientsArray,
        {
        fullName: generateFullNameFromObj(selectedClient),
        personId:{email:selectedClient?.email, _id:selectedClient._id},
        isCorp: false,
        isReadOnly:true,
        isAddedByUser:true,
        _id:selectedClient._id,
        signers: [],
      }]);

      setSignerChecked(
        {
         ...signerChecked,
         [selectedClient._id]:
        {
        fullName: generateFullNameFromObj(selectedClient),
        personId:{email:selectedClient?.email,},
        isCorp: false,
        isReadOnly:true,
        _id:selectedClient._id,
        signers: [],
        checked:true
      }});
      message.success("Read only recipient added!");
    }
  };



  const handleUpdateReadOnly = (selectedClient, checked) => {
    const updatedReadOnly = checked;

    setClientsArray((prevData) =>
      prevData.map((client) =>
        client?._id === selectedClient?._id ? { ...client, isReadOnly: updatedReadOnly } : client
      )
    );

    setSignerChecked((prevState) => ({
      ...prevState,
      [selectedClient?._id]: {
        ...prevState[selectedClient?._id],
        isReadOnly: updatedReadOnly,
        isAddedByUser:false,
      },
    }));
  };


  const handleDeleteReadOnly = (id) => {
    let clients = clientsArray || [];

    let tobeDelete = clients.find(client => client?.personId?._id === id && client.isReadOnly);
    if (tobeDelete) {
      let updatedClients = clients.filter(client => !(client?.personId?._id === id && client.isReadOnly));

      setClientsArray(updatedClients);

      let updatedSignerChecked = { ...signerChecked };
      delete updatedSignerChecked[id];
      setSignerChecked(updatedSignerChecked);

      message.success("Read only recipient deleted successfully!");
    } else {
      message.error("Read only recipient not found.");
    }
  };
//add read only
const handleReadOnlyEmailChange = (e) => {
  const value = e.target.value;
  const emailArray = value
    .split(/[\s,]+/) // Split by spaces or commas
    .map((email) => email.trim()) // Trim whitespace from each email
    .filter((email) => email.length > 0); // Filter out empty strings
  setNewState({ ...newState, email: emailArray }); // Update the state with the array of emails
};

  const handleEmailAddress = () => {

    // Generate unique ID
    let uniqueId = uuidv4().replace(/-/g, "").slice(0, 26);
    // Ensure email addresses are split into an array
    let emailArray = newState?.email || []; // Assuming emailArray is an array of emails
    // Process each email address
    let newClientsArray = [];
    let newSignerChecked = {};
    emailArray.forEach((email) => {
      if (email.trim()) {
        // Ensure email is not empty
        let uniqueEmailId = uuidv4().replace(/-/g, "").slice(0, 26); // Generate a unique ID for each email
        let data = {
          personId: {
            email: email,
            _id: uniqueEmailId,
          },
          isReadOnly: true,
          isAddedByUser: true,
          _id: uniqueEmailId,
        };

        let signerData = {
          checked: true,
          isReadOnly: true,
          isAddedByUser: true,
          row: {
            personId: {
              email: email,
            },
            _id: uniqueEmailId,
          },
        };
        newClientsArray.push(data);
        newSignerChecked[uniqueEmailId] = signerData;
      }
    });
    // Update state
    setClientsArray((prevArray) => [...prevArray, ...newClientsArray]);
    setSignerChecked((prevChecked) => ({ ...prevChecked, ...newSignerChecked }));
    setNewState({ ...newState, email: [] }); // Reset email array
    form.resetFields();
 };

  const isButtonDisabled = () => {
    const noSignersChecked = Object.values(signerChecked)?.filter((el) => el.checked)?.length === 0;
    const noDocumentsSelected =
      data?.documentSelected?.length === 0 &&
      data?.propertyDocsSelected?.length === 0 &&
      data?.clientDocumentSelected?.length === 0 &&
      data?.sharedSelected?.length === 0;
    const noEmailMessage = emailList?.emailMessage === "" || emailList?.emailMessage === "<p><br></p>";

    if (nestedModalOpen?.sendMultipleDoc) {
      return noEmailMessage && (noDocumentsSelected && noEmailMessage);
    } else if (nestedModalOpen?.isAgent) {
      return false;
    } else {
      return noSignersChecked;
    }
  };
  //read only email added
  const handlePressEnter = (e) => {
    e.preventDefault(); // Prevent default newline behavior
    form.submit(); // Trigger form submission
  };
  //validate read only emails 
  const validateEmails = (rule, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const emailArray = value.split(/[\s,]+/).filter(Boolean);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Check if the number of emails exceeds three
    if (emailArray.length > 3) {
      return Promise.reject(new Error("You can only add up to three email addresses."));
    }
    for (const email of emailArray) {
      if (!emailRegex.test(email)) {
        return Promise.reject(new Error("Please enter valid email address"));
      }
    }
    return Promise.resolve();
  };
  const debouncedHandleEmailAddress = debounce(() => {
    if(newState.email.length>0)
    {
      form.submit()
    }

  }, 1000);
 // Clear the previous timeout
 if (typingTimeoutRef.current) {
  clearTimeout(typingTimeoutRef.current);
}

// Set a new timeout to call handleEmailAddress after 10 seconds
typingTimeoutRef.current = setTimeout(() => {
  debouncedHandleEmailAddress();
}, 1000); 

  return (
    <div style={{ maxHeight:"550px", overflow:"hidden", overflowY:"auto", padding:"5px"}}>
        {/* email signature p[review modal ] */}
      <Modal width={'800px'} visible={ispreview} footer={false} onCancel={()=>setIspreview(false)}>
              {emailSignatureId &&
                  <div
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "10px",
                      margin: "auto",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div>
                        <span
                          style={{
                            color: "gray",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Name: {emailSignatureId?.title}
                        </span>
                      </div>
                     </div>
                     <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          textAlign: "left",
                          lineHeight: "0.8",
                          marginTop:"5px"
                        }}
                      >
                        {emailSignatureId?.footerText && (
                          <div dangerouslySetInnerHTML={{ __html: emailSignatureId.footerText }} />
                        )}
                      </p>

                    <div style={{display:"flex", justifyContent:"center",minHeight:"300px"}}>
                    {emailSignatureId?.signatureImageUrl && (
                      <Image
                        src={emailSignatureId?.signatureImageUrl}
                        alt="Signature"
                        loading="lazy"
                      />
                    )}
                    </div>
                  </div>
                }

      </Modal>

      {/* client list modal  */}
      <Modal
      visible={showClientListModal}
      onCancel={()=>setShowClientListModal(false)}
      footer={false}
      style={{ marginTop: "-80px" }}>
        <ClientList
        listSource={"offerSection"}
         selectClient={handleClientSelected}
        // searchBuyerNameInOffer={input}
        setshowModal={setShowClientListModal}
        page={page}
        setPage={setPage}
        showSearchInput={showSearchInput}
        setShowSearchInput={setShowSearchInput}
        value={value}
        setValue={setValue}
        clientsArray={clientsArray}
        // isCorpTrue={isCorpTrue}
        // currentlySelectedCorpData={currentlySelectedCorpData}
        delegateOwnerId={delegateOwnerId}
        />
        <Button
        type="primary"
        style={{bottom:0,left:175,position:"relative",minWidth:"10vw",marginTop:"20px"}}
        onClick={()=>{setShowClientListModal(false); setNewState({...newState, isOpen:false})}}
        >Add</Button>
      </Modal>

      <EmailTemplateModal Email_templatevisible={Email_templatevisible} setEmail_templatevisible={setEmail_templatevisible} handleEmail_templateCancel={handleEmail_templateCancel} handleselectemail={handleselectemail} />

     {(!nestedModalOpen?.isAgent) && ( <div style={{ display: "", justifyContent: "left" }}>
      <div style={{ marginBottom: "-10px" }}>
        <p style={{fontWeight:"bold",marginTop:"5px",color:"grey"}}>Add Read Only Recipients</p>
      </div>
      <Form
         form={form}
        onFinish={handleEmailAddress}

      >
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Form.Item name="email" rules={[{ required: true }, { validator: validateEmails }]} style={{ flex: 1 }}>

          <Input.TextArea
                  rows={1}
                  placeholder="Enter email addresses,"
                  onChange={handleReadOnlyEmailChange}
                  value={newState.email || ""}
                  autoSize={{ minRows: 1 }}
                
                />
                
                </Form.Item>
                <Tooltip title="Add Existing Contact" trigger={["click", "hover"]}>
                  <div style={{ display: "flex", justifyContent: "center", cursor:"pointer" }} onClick={() => setShowClientListModal(true)}>
                    <TiContacts style={{ fontSize: "32px", marginTop: "-20px" }}/>
                  </div>
                </Tooltip>
            
        </div>
      </Form>
    </div>)}
    {nestedModalOpen?.isAgent ? (
        <div>{RTDInitiator === "SELLER" ? <RecipientList data={allBuyerAgentData} type="buyer" /> : <RecipientList data={allSellerAgentData} type="seller" />}</div>
      ) : (
        <div style={{ paddingBottom: "20px", marginBottom: "20px" }}>
        <Checkbox checked={Object.values(signerChecked).length && Object.values(signerChecked)?.every((el) => el.checked)} onChange={(e) => handleSelectAllChange(e.target.checked)} style={breakPoint ? {fontSize:"10px", fontWeight:"bold"} :{ fontSize: "20px" }}>
          Select All
        </Checkbox>
        {clientsArray?.map((seller) => (
          <div key={seller?._id} style={breakPoint?{display: "flex", alignItems: "center",} : { display: "flex", alignItems: "center", whiteSpace: "nowrap",}}>
            {!seller?.isCorp && (
              <>
                <Checkbox checked={signerChecked[seller._id]?.checked} onChange={() => handleClientsCheckboxChange(seller?._id, !signerChecked[seller?._id]?.checked)} style={{ fontSize: "20px", marginRight: "10px" }} disabled={!seller.personId?.email} />
                <div>
                  <span style={breakPoint ? {fontWeight:"bold"} : { fontSize: "20px", cursor: "context-menu" }}>{seller?.fullName}</span>
                  <span style={breakPoint ? {fontSize: "20px",} : { fontSize: "25px", fontWeight: "bold", cursor: "context-menu", marginRight: "5px" }}>-</span>{" "}
                  {seller.personId?.email ? (<>
                    <span style={breakPoint ? {fontSize:"10px", color: "blue", cursor: "context-menu", textDecoration: "underline"} :{ fontSize: "20px", color: "blue", cursor: "context-menu", textDecoration: "underline" }}>
                        {seller?.personId?.email}
                        <span style={breakPoint ? {fontSize:"10px", marginLeft:"5px"} : {marginLeft:"10px", fontWeight:"bold"}}>{seller?.isAddedByUser ? null :
                            <Checkbox  checked={seller?.isReadOnly}
                             onChange={(event)=>handleUpdateReadOnly(seller, event.target.checked)}>
                               <span style={{color:"gray"}}>Read Only</span>
                            </Checkbox>}
                        </span>
                    </span>

                    <span style={{color:"grey",  fontWeight:"bold"}}>
                      <span style={breakPoint?{fontSize:"5px", fontWeight:"normal"}:{}}>{seller?.isAddedByUser  ? "(Read Only)" : ""}</span>
                      <span style={{cursor:"pointer"}}>{"  "}{(seller?.isAddedByUser) &&
                        (<Tooltip title="Delete readonly recipient!" trigger={["hover", "click"]}>
                          <DeleteOutlined onClick={()=>handleDeleteReadOnly(seller?.personId?._id)}/>
                        </Tooltip>)
                      }</span>
                    </span>

                    </>
                  ) : (
                    <AddEmailModal offerId={selectedData?.offerId ? selectedData?.offerId : getIndividualTransactionData?.offerId} clientData={seller} successFunc={successFunc} role={role} delegate={delegate}/>
                  )}
                </div>
              </>
            )}
            {seller.isCorp && <CorporationCheckbox corporation={seller} />}
          </div>
        ))}
      </div>)}

     {(nestedModalOpen?.sendMultipleDoc) && (
      <div style={{marginTop:"-20px", boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"}}>
             <Collapse ghost accordion defaultActiveKey={['1']}>
               <Panel
               header={<span style={{fontSize:"15px"}}>Document List</span>}
                key="1">
                    <div>
                      <DocumentList documentId={documentId} clickedItem={clickedItem?.documentId} RTDInitiator={RTDInitiator} role={role} nestedModalOpen={nestedModalOpen} data={data} setData={setData} selectedData={selectedData} section={section} reloadSection={reloadSection} screenSource={source}/>
                    </div>
               </Panel>
             </Collapse>
      </div>

     )}
      <ContentEditable setEmail_templatevisible={setEmail_templatevisible} selectedtext={selectedtext} handleInput={handleInput} emailList={emailList} setEmailList={setEmailList} />

      <div style={breakPoint ? {marginTop:"40px"} : { marginTop: "10px",}}>
      <Checkbox
        checked={emailSignatureId.isDefault}
        onChange={(e) => setEmailSignatureId({ ...emailSignatureId, isDefault: e.target.checked })}
      >
        Include Email Signature
      </Checkbox>
      <Select
        value={emailSignatureId._id} // Use value instead of defaultValue
        style={{ width: 200 }}
        loading={emailSignLoading}
        onChange={(value) => handleSelectChange(value)}
        disabled={!emailSignatureId.isDefault}
      >
        {emailSignData && emailSignData?.length && emailSignData?.map((el, ind) => (
          <Option key={ind} value={el?._id}>{el?.title}</Option>
        ))}
      </Select>
      {emailSignatureId?.isDefault && (<Button style={{fontSize:"16px"}} icon={<EyeOutlined />} onClick={()=>setIspreview(true)} type="link">Preview</Button>)}
    </div>

      <div style={{ textAlign: "right", marginTop: "10px" }}>
        <Button type="primary" onClick={handleSend}
        disabled={isButtonDisabled()}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export { EmailModal };
