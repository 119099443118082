import { logoutAndInWithBroadcast } from "../../App/components/Broadcast.jsx";
import { clienAuthApi } from "../utils/api.js";
import { clientAuthConstants } from "./action-types.js";
const apiURL = process.env.REACT_APP_ADMIN_BASE_URL;

const verifyOTPError = (error) => {
  return {
    type: clientAuthConstants.VERIFY_OTP_ERROR,
    setError: error,
    setLoading: false,
  };
};

const verifyOTPLoading = () => {
  return {
    type: clientAuthConstants.VERIFY_OTP_LOADING,
    setLoading: true,
  };
};
const verifyOTPSuccess = () => {
  return {
    type: clientAuthConstants.VERIFY_OTP_SUCCESS,
  };
};

const verifyOTP = (data, history) => {
  return (dispatch, getState) => {
    dispatch(verifyOTPLoading());
    clienAuthApi
      .verifyUserOtp(data)
      .then((response) => {
        localStorage.setItem("clientAuth", response.data.info.token);
        dispatch(verifyOTPSuccess());
        logoutAndInWithBroadcast()
        history.push("/clientScreen")
      })
      .catch((error) => {
        dispatch(
          verifyOTPError(
            error?.response?.data?.message || "Error occured while verifying the otp!"
          )
        );
      });
  };
};

export const clientAuthHandle = {
  verifyOTP,
  verifyOTPError,
};
