import { Col, Form, Input, Row, Image, Popconfirm, Typography, Button, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined, CloseCircleOutlined, PlusCircleFilled } from "@ant-design/icons";
import imageCompression from "browser-image-compression";
import { accountHandle } from "../state/actions";
import { useMediaQuery } from "react-responsive";

const { TextArea } = Input;
const { Text } = Typography

const EditBrokerageForm = ({ getUserDetailsData, brokerageForm }) => {
    const dispatch = useDispatch();
    const imageFormdataRef = useRef([]);
    const [bufferData, setbufferData] = useState([]);
    const [isFormChanged, setIsFormChanged] = useState({
        formChange: false,
        deleteLogo: false
    });
    const isMobile = useMediaQuery({ maxWidth: 575 })

    const formItemLayout = {
        labelCol: {
          xs: {
            span: 10,
          },
          sm: {
            span: 10,
          },
          md: {
            span: 10,
          },
          lg: {
            span: 10,
          }
        },
        wrapperCol: {
          xs: {
            span: 14,
          },
          sm: {
            span: 14,
          },
          md: {
            span: 14,
          },
          lg: {
            span: 14,
          }
        },
      };

    const initialValues = Object.keys(getUserDetailsData?.brokerageData)?.length > 0 ? {
        brokerageName: getUserDetailsData?.brokerageData?.brokerageName,
        brokerageLicenseNumber: getUserDetailsData?.brokerageData?.brokerageLicenseNumber,
        brokerageAddress: getUserDetailsData?.brokerageData?.brokerageAddress,
        brokeragePhoneNumber: getUserDetailsData?.brokerageData?.brokeragePhoneNumber,
        brokerageFaxNumber: getUserDetailsData?.brokerageData?.brokerageFaxNumber,
        brokerageEmail: getUserDetailsData?.brokerageData?.brokerageEmail,
    } : {};

    const onValuesChange = () => {
        const isChanged = checkForFormChanges();
        setIsFormChanged({ ...isFormChanged, formChange: isChanged });
    };

    const checkForFormChanges = () => {
        const formValues = brokerageForm.getFieldsValue();
        const isChanged = Object.keys(initialValues).some(key => initialValues[key] !== formValues[key]);
        return isChanged
    }

    const handleSubmit = (values) => {
        let urlData = {
            ...(values?.brokerageName !== initialValues?.brokerageName) ? { brokerageName: values?.brokerageName } : {},
            ...(values?.brokerageLicenseNumber !== initialValues?.brokerageLicenseNumber) ? { brokerageLicenseNumber: values?.brokerageLicenseNumber } : {},
            ...(values?.brokerageAddress !== initialValues?.brokerageAddress) ? { brokerageAddress: values?.brokerageAddress?.split("\n")?.join(",")?.split(",")?.filter((str) => str !== "")?.join(",")} : {},
            ...(values?.brokeragePhoneNumber !== initialValues?.brokeragePhoneNumber) ? { brokeragePhoneNumber: values?.brokeragePhoneNumber } : {},
            ...(values?.brokerageFaxNumber !== initialValues?.brokerageFaxNumber) ? { brokerageFaxNumber: values?.brokerageFaxNumber } : {},
            ...(values?.brokerageEmail !== initialValues?.brokerageEmail) ? { brokerageEmail: values?.brokerageEmail } : {},
            ...(isFormChanged.deleteLogo) ? { brokerageLogo: "" } : {},
        }
        let imageBuffers = imageFormdataRef.current;
        dispatch(accountHandle.editBrokerage(urlData, imageBuffers, "base64"))
        handleClose()
    };

    const handleClose = () => {
        setIsFormChanged({ ...isFormChanged, formChange: false, deleteLogo: false });
        setbufferData([]);
        imageFormdataRef.current = [];
    };

    function bytesToSize(bytes) {
        let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes === 0) return "0 Byte";
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    }

    function beforeUpload(file) {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            throw new Error("You can only upload JPG/PNG file!");
        }
        return isJpgOrPng;
    }

    function deleteImg(index) {
        let filterData = bufferData.filter((el, i) => i !== index);
        setbufferData(filterData);
        const imageFormdataCopy = [...imageFormdataRef.current];
        imageFormdataCopy.splice(index, 1);
        imageFormdataRef.current = imageFormdataCopy;
    }

    let handleCustome = async (data) => {
        const image = await compressImage(data.file);
        const reader = new FileReader();

        reader.readAsDataURL(image);
        reader.onload = () => {
            const newFile = {
                name: data.file.name,
                size: bytesToSize(image.size),
                file: reader.result,
                origin: "base64",
            };
            setbufferData((prevFiles) => {
                if (prevFiles.length) {
                    return [...prevFiles, newFile];
                } else {
                    return [newFile];
                }
            });
            imageFormdataRef.current = [...imageFormdataRef.current, newFile];
        };
        reader.onerror = (error) => {
            return error;
        };
    };

    let handleFilesSelected = (event) => {
        const selectedFiles = Array.from(event.target.files);
        selectedFiles.forEach(async (file) => {
            const image = await compressImage(file);
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = () => {
                const newFile = {
                    name: file.name,
                    size: bytesToSize(image.size),
                    file: reader.result,
                    origin: "base64",
                };
                setbufferData((prevFiles) => [...prevFiles, newFile]);
                imageFormdataRef.current = [...imageFormdataRef.current, newFile];
            };
            reader.onerror = (error) => {
                return error;
            };
        });
    };

    async function compressImage(file) {
        const maxSizeMB = 3;
        const maxSizeBytes = maxSizeMB * 1024 * 1024;
        if (file.size <= maxSizeBytes) {
            return file;
        }

        const initialQuality = 0.7;
        let currentQuality = initialQuality;

        while (true) {
            try {
                const compressedFile = await imageCompression(file, { quality: currentQuality });
                if (compressedFile.size <= maxSizeBytes || currentQuality <= 0.1) {
                    return compressedFile;
                }
                currentQuality -= 0.1;
            } catch (error) {
                throw error;
            }
        }
    }

    let handleAddFile = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.multiple = true;
        input.addEventListener("change", handleFilesSelected);
        input.click();
    };

    useEffect(() => {
        if (bufferData?.length < 1) {
            let handlePasteEvent = async (event) => {
                let count = 0;
                var items = (event.clipboardData || event.originalEvent.clipboardData).items;
                for (let index in items) {
                    var item = items[index];
                    if (item.kind === "file" && count === 0) {
                        var blob = item.getAsFile();
                        var reader = new FileReader();
                        const ide = Math.floor(10000 + Math.random() * 90000);
                        const imageName = "pastedImage" + ide;
                        var updatedBlob = new File([blob], imageName, { type: blob.type });
                        const image = await compressImage(updatedBlob);
                        let checkImage = beforeUpload(updatedBlob);
                        if (checkImage) {
                            reader.onload = () => {
                                const newFile = {
                                    name: updatedBlob.name,
                                    size: bytesToSize(image.size),
                                    file: reader.result,
                                    origin: "base64",
                                };
                                setbufferData((prevFiles) => {
                                    if (prevFiles.length) {
                                        return [...prevFiles, newFile];
                                    } else {
                                        return [newFile];
                                    }
                                });
                                imageFormdataRef.current = [...imageFormdataRef.current, newFile];
                            };
                            reader.readAsDataURL(image);
                            count = +count + 1;
                        }
                    }
                }
            };
            let getModal = document;
            getModal.addEventListener("paste", handlePasteEvent);
            return () => {
                getModal.removeEventListener("paste", handlePasteEvent);
            };
        } else {
            setIsFormChanged({ ...isFormChanged, formChange: true });
        }
    }, [bufferData]);

    useEffect(() => {
        if (Object.keys(getUserDetailsData?.brokerageData)?.length > 0) {
            brokerageForm.setFieldsValue({ ...initialValues, brokerageLogo: getUserDetailsData?.brokerageData?.brokerageLogo });
        }else{
            setIsFormChanged({...isFormChanged,formChange:false})
        }
    }, [brokerageForm])

    return (
        <>
            <Form form={brokerageForm} onFinish={handleSubmit} layout="horizontal" style={{ padding: 10 }} scrollToFirstError onValuesChange={onValuesChange} {...formItemLayout}>
                <Form.Item name={`brokerageLogo`} style={{ marginBottom: "0px" }} label={<div style={{ display: "flex", gap: "15px" }}><div style={{ marginTop: "6px" }}>Logo</div>{(!bufferData?.length) && <div><span
                    style={{
                        color: "#1990ff",
                        fontSize: "27px",
                        cursor: "pointer",
                        top: 0
                    }}
                    onClick={handleAddFile}
                >
                    <Tooltip title="Update Logo" trigger={["click", "hover"]}>
                        <PlusCircleFilled />
                    </Tooltip>
                </span>{" "}</div>}</div>}>

                    {bufferData.length > 0 ? (
                        <Form.Item>
                            <div
                                style={{
                                    display: "flex",
                                    maxWidth: "100%",
                                    overflow: "auto",
                                    minHeight: "120px",
                                    boxShadow:
                                        "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                }}
                            >
                                {bufferData.map((file, index) => (
                                    <div key={index} style={{ margin: "10px" }}>
                                        <Image
                                            width={100}
                                            src={file.file}
                                            preview={{
                                                visible: false,
                                                mask: (
                                                    <div
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            zIndex: 21,
                                                            top: "0px",
                                                            right: "0px",
                                                        }}
                                                    >
                                                        <div
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                            }}
                                                        >
                                                            <Popconfirm
                                                                title="Are you sure you want to delete this image?"
                                                                onConfirm={() => {
                                                                    deleteImg(index);
                                                                    const isChanged = checkForFormChanges()
                                                                    if (!isChanged && !getUserDetailsData?.brokerageData?.brokerageLogo?.length) {
                                                                        setIsFormChanged({ ...isFormChanged, formChange: false });
                                                                    }
                                                                }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <CloseCircleOutlined
                                                                    style={{ fontSize: "30px" }}
                                                                />
                                                            </Popconfirm>
                                                        </div>
                                                    </div>
                                                ),
                                            }}
                                        />
                                        <br />
                                        <Text type="secondary">{file.name}</Text>
                                        <br />
                                        <Text type="secondary">Size: {file.size}</Text>
                                    </div>
                                ))}
                            </div>
                        </Form.Item>
                    ) :
                        (getUserDetailsData?.brokerageData?.brokerageLogo && !isFormChanged.deleteLogo) ?
                            <Form.Item >
                                <Image style={{ objectFit: "contain" }} width={"40%"} height={110} src={getUserDetailsData?.brokerageData?.brokerageLogo}></Image>
                                <Tooltip title="Remove brokerage logo">
                                    <CloseCircleOutlined
                                        style={{ position: 'absolute', top: '4px', right: isMobile ? "40%" : '25%', fontSize: '24px', cursor: 'pointer', color: "#178DFA" }}
                                        onClick={() => {
                                            setIsFormChanged({ ...isFormChanged, formChange: true, deleteLogo: true });
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>
                            : (
                                <Dragger
                                    accept="image/*"
                                    showUploadList={false}
                                    customRequest={handleCustome}
                                    multiple
                                >
                                    <div style={{ height: "150px" }}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined
                                                style={{ position: "relative", top: "-25px" }}
                                            />
                                        </p>
                                        <p
                                            className="ant-upload-text"
                                            style={{ position: "relative", top: "-55px" }}
                                        >
                                            Click or drag or paste file to this area to upload
                                        </p>
                                        <p
                                            className="ant-upload-hint"
                                            style={{ position: "relative", top: "-55px" }}
                                        >
                                            Support for logo image upload. Strictly prohibit from
                                            uploading company data or other banned files
                                        </p>
                                    </div>
                                </Dragger>
                            )}
                </Form.Item>
                <Form.Item label="Name" name="brokerageName" style={{ marginBottom: "0px" }}>
                    <Input
                        placeholder="Enter Brokerage Name"
                        style={{
                            border: getUserDetailsData?.brokerageData?.brokerageName
                                ? "none"
                                : "1px solid #d9d9d9",
                            borderRadius: "4px",
                            padding: "4px 11px",
                            fontWeight:"bold",
                            fontSize:"15px",
                            cursor:"pointer",
                        }}
                        onFocus={(e) => (e.target.style.border = "1px solid #d9d9d9")}
                        onBlur={(e) => {
                            if (e.target.value) {
                                e.target.style.border = "none";
                            }
                        }}>
                    </Input>
                </Form.Item>

                <Form.Item label="License Number" name="brokerageLicenseNumber" style={{ marginBottom: "0px" }}>
                    <Input
                        placeholder="Enter Brokerage License Number"
                        style={{
                            border: getUserDetailsData?.brokerageData?.brokerageLicenseNumber
                                ? "none"
                                : "1px solid #d9d9d9",
                            borderRadius: "4px",
                            padding: "4px 11px",
                            fontWeight:"bold",
                            fontSize:"15px",
                            cursor:"pointer",
                        }}
                        onFocus={(e) => (e.target.style.border = "1px solid #d9d9d9")}
                        onBlur={(e) => {
                            if (e.target.value) {
                                e.target.style.border = "none";
                            }
                        }}>
                    </Input>
                </Form.Item>

                <Form.Item label="Address" name="brokerageAddress" style={{ marginBottom: "5px" }}>
                    <TextArea
                        placeholder="Enter Brokerage Address"
                        style={{
                            border: getUserDetailsData?.brokerageData?.brokerageAddress
                                ? "none"
                                : "1px solid #d9d9d9",
                            borderRadius: "4px",
                            padding: "4px 11px",
                            fontWeight:"bold",
                            fontSize:"15px",
                            cursor:"pointer",
                        }}
                        onFocus={(e) => (e.target.style.border = "1px solid #d9d9d9")}
                        onBlur={(e) => {
                            if (e.target.value) {
                                e.target.style.border = "none";
                            }
                        }}>
                    </TextArea>
                </Form.Item>

                <Form.Item label="Phone" name="brokeragePhoneNumber" style={{ marginBottom: "0px" }}>
                    <PhoneInput 
                        allowClear
                        inputStyle={{ width: "100%", paddingLeft: "2px",border:"none",fontWeight:"bold", fontSize:"15px",cursor:"pointer" }}
                        country={"us"}
                        onlyCountries={["us"]}
                        maxLength="10"
                        international={false}
                        disableDropdown={true}
                        disableCountryCode={true}
                        placeholder="Enter Brokerage Phone Number"
                        containerStyle={{ width: "100%" }}
                        buttonStyle={{ display: "none" }}
                        style={{
                            borderRadius: "4px",
                            padding: "4px 11px",
                            fontWeight:"bold",
                        }}
                        onFocus={(e) => (e.target.style.border = "1px solid #d9d9d9")}
                        onBlur={(e) => {
                            if (e.target.value) {
                                e.target.style.border = "none";
                            }
                        }}
                    />
                </Form.Item>

                <Form.Item label="Fax" name="brokerageFaxNumber" style={{ marginBottom: "0px" }}>
                    <PhoneInput
                        allowClear
                        inputStyle={{ width: "100%", paddingLeft: "2px",border:"none",fontWeight:"bold", fontSize:"15px",cursor:"pointer" }}
                        country={"us"}
                        onlyCountries={["us"]}
                        maxLength="10"
                        international={false}
                        disableDropdown={true}
                        disableCountryCode={true}
                        placeholder="Enter Brokerage Fax Number"
                        containerStyle={{ width: "100%" }}
                        buttonStyle={{ display: "none" }}
                        style={{
                            borderRadius: "4px",
                            padding: "4px 11px",
                            fontWeight:"bold",
                        }}
                        onFocus={(e) => (e.target.style.border = "1px solid #d9d9d9")}
                        onBlur={(e) => {
                            if (e.target.value) {
                                e.target.style.border = "none";
                            }
                        }}
                    />
                </Form.Item>

                <Form.Item label="Email" name="brokerageEmail" style={{ marginBottom: "0px" }}>
                    <Input
                        placeholder="Enter Brokerage Email"
                        style={{
                            border: getUserDetailsData?.brokerageData?.brokerageEmail
                                ? "none"
                                : "1px solid #d9d9d9",
                            borderRadius: "4px",
                            padding: "4px 11px",
                            fontWeight:"bold",
                            fontSize:"15px",
                            cursor:"pointer",
                        }}
                        onFocus={(e) => (e.target.style.border = "1px solid #d9d9d9")}
                        onBlur={(e) => {
                            if (e.target.value) {
                                e.target.style.border = "none";
                            }
                        }}>
                    </Input>
                </Form.Item>

                {isFormChanged.formChange && <Row justify={"center"} gutter={0} style={{ width: "100%", marginTop:"10px" }}>
                    <Col>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit" style={{ marginLeft: "10px" }} disabled={!isFormChanged.formChange}>Save</Button>
                    </Col>
                </Row>}
            </Form>
        </>
    );
}

export { EditBrokerageForm };
