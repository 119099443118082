import { Modal } from 'antd';
import React from 'react'
import { BiShare } from 'react-icons/bi';
import { ExclamationCircleOutlined, CheckCircleFilled, SnippetsOutlined, CopyOutlined, } from "@ant-design/icons";
import { RiShareForwardLine } from 'react-icons/ri';

const RtdLegend = ({showlegend, setShowLegend, seller  }) => {
  return (
    <Modal
    visible={showlegend}
    onCancel={() => {
    setShowLegend(false);
    }}
    style={{ marginTop: "-50px" }}
    footer={false}
>
    <div>
    <table
        style={{
            width: "100%",
            padding: "5%",
            // borderRadius: "8px",
            fontWeight: 600,
            border: "1px solid grey",
        }}
    >
        <thead>
        <tr
            style={{
            height: "50px",
            width: "100%",
            fontSize: "17px",
            background:"#3871b9",
            color:"white",
            border: "1px solid #d3d3d3",
            }}
        >
            <th style={{border:"1px solid lightgray",borderRadius:"0px 0px 0px 0px"}}>Symbols</th>
            <th style={{border:"1px solid lightgray",borderRadius:"0px 0px 0px 0px"}}>Information</th>
        </tr>{" "}
        </thead>
        <tbody
        style={{
            fontSize: "15px",
            padding: "30px",
            height: "50px",
        }}
        >
        <tr
            style={{
            height: "50px",
            width: "100%",
            border: "1px solid #d3d3d3",
            }}
        >
            <td style={{ width: "30%", paddingLeft: "50px",border:"1px solid lightgray" }}>
            <div
                style={{
                width: 18,
                height: 18,
                borderRadius: 8,
                border: "1px solid black",
                backgroundColor: "whitesmoke",
                }}
            ></div>
            </td>
            <td
            style={{
                width: "70%",
                textAlign: "left",
            }}
            >
            Document not started
            </td>
        </tr>

        <tr
            style={{
            width: "100%",
            height: "50px",
            }}
        >
            <td
            style={{
                width: "30%",
                paddingLeft: "50px",
            }}
            >
            {" "}
            <div
                style={{
                width: 18,
                height: 18,
                borderRadius: 8,
                backgroundColor: "green",
                }}
            ></div>
            </td>
            <td
            style={{
                width: "70%",
                textAlign: "left",
            }}
            >
            Document is valid & signed by all parties
            </td>
        </tr>
        <tr
            style={{
            height: "50px",
            width: "100%",
            }}
        >
            <td
            style={{
                width: "30%",
                paddingLeft: "50px",
            }}
            >
            {" "}
            <div
                style={{
                width: 18,
                height: 18,
                borderRadius: 8,
                backgroundColor: "yellow",
                }}
            ></div>
            </td>
            <td
            style={{
                width: "70%",
                textAlign: "left",
            }}
            >
            Document is not complete
            </td>
        </tr>
        <tr
            style={{
            height: "50px",
            width: "100%",
            }}
        >
            <td
            style={{
                width: "30%",
                paddingLeft: "50px",
            }}
            >
            {" "}
            <div
                style={{
                width: 18,
                height: 18,
                borderRadius: 8,
                backgroundColor: "red",
                }}
            ></div>
            </td>
            <td
            style={{
                width: "70%",
                textAlign: "left",
            }}
            >
            Document is not valid or outdated
            </td>
        </tr>
        <tr
            style={{
            width: "100%",
            height: "50px",
            }}
        >
            <td
            style={{
                width: "30%",
                paddingLeft: "50px",
            }}
            >
            {" "}
            <div
                style={{
                width: 18,
                height: 18,
                borderRadius: 8,
                backgroundColor: "darkgray",
                }}
            ></div>
            </td>
            <td
            style={{
                width: "70%",
                textAlign: "left",
            }}
            >
            Document is invalidated or cancelled
            </td>
        </tr>

        <tr
            style={{
            width: "100%",
            height: "50px",
            }}
        >
            <td
            style={{
                width: "30%",
                paddingLeft: "50px",
            }}
            >
            <CopyOutlined />
            </td>
            <td
            style={{
                width: "70%",
                textAlign: "left",
            }}
            >
            Copy Transaction Id
            </td>
        </tr>
        <tr
            style={{
            width: "100%",
            height: "50px",
            }}
        >
            <td
            style={{
                width: "30%",
                paddingLeft: "50px",
            }}
            >
            <SnippetsOutlined
                style={{
                color: "hsl(209deg 72% 51%)",
                fontWeight: "bold",
                marginLeft: "5px",
                }}
            />
            </td>
            <td
            style={{
                width: "70%",
                textAlign: "left",
            }}
            >
            Transaction Id copied to clipboard
            </td>
        </tr>
        <tr
            style={{
            width: "100%",
            height: "50px",
            }}
        >
            <td
            style={{
                width: "30%",
                paddingLeft: "50px",
            }}
            >
            <CheckCircleFilled style={{ fontSize: "18px", backgroundColor: "white", borderRadius: "100px", color: "green" }} />
            </td>
            <td
            style={{
                width: "70%",
                textAlign: "left",
            }}
            >
            Document sent to buyer/seller agent
            </td>
        </tr>

        <tr
            style={{
            width: "100%",
            height: "50px",
            }}
        >
            <td
            style={{
                width: "30%",
                paddingLeft: "50px",
            }}
            >
            <BiShare style={{ fontSize: "22px", backgroundColor: "white", borderRadius: "100px", color:"#376ada" }} />
            </td>
            <td
            style={{
                width: "70%",
                textAlign: "left",
            }}
            >
             {seller === "BUYER" ? "Send document to seller agent" : "Send document to sellers"}
            </td>
        </tr>

        <tr
            style={{
            width: "100%",
            height: "50px",
            }}
        >
            <td
            style={{
                width: "30%",
                paddingLeft: "50px",
            }}
            >
            <RiShareForwardLine style={{ fontSize: "23px", backgroundColor: "white", borderRadius: "100px", color:"#376ada" }} />
            </td>
            <td
            style={{
                width: "70%",
                textAlign: "left",
            }}
            >
            {seller === "BUYER" ?  "Send document to buyers" : "Send document to buyer agent"}
            </td>
        </tr>

        <tr
            style={{
            width: "100%",
            height: "50px",
            }}
        >
            <td
            style={{
                width: "30%",
                paddingLeft: "50px",
            }}
            >
            <ExclamationCircleOutlined style={{ color: "red", fontSize: "18px" }} />
            </td>
            <td
            style={{
                width: "70%",
                textAlign: "left",
            }}
            >
            Invalidated
            </td>
        </tr>
        </tbody>
    </table>
    </div>
</Modal>
  )
}

export default RtdLegend
