import { bugListingPageConstants } from "./action-types";
import { bugDataApi } from "../utils/api";
import { message } from "antd";
import { logoutAndInWithBroadcast } from "../../App/components/Broadcast";
/**
 * All Actions for bug Listing Page
 */

// with access token
const bugListingLoading = () => {
  return {
    type: bugListingPageConstants.BUG_LISTING_PAGE_LOADING,
  };
};
const bugListingError = (error) => {
  return {
    type: bugListingPageConstants.BUG_LISTING_PAGE_FAILURE,
    bugLlistingError: error,
  };
};

const bugListingSuccess = (response,pagination) => {
  return {
    type: bugListingPageConstants.BUG_LISTING_PAGE_SUCCESS,
    bugLlistingData: response.data.info,
    bugLlistLoading: false,
    pagination:pagination
  };
};

const getBugListingDataWith = (page) => {
  let pagination
  if(page)
        {
          pagination=true
        }
        else
        {
          pagination=false
        }
  return (dispatch) => {

    dispatch(bugListingLoading());

    //}
    bugDataApi
      .buglistingPageLoadRequest(page,"BUGS")
      .then((response) => {

        dispatch(bugListingSuccess(response,pagination));
      })
      .catch((error) => {
        dispatch(bugListingError(error));
      });
  };
};

// without access token
const bugListingWithoutError = (error) => {
  return {
    type: bugListingPageConstants.BUG_LISTING_PAGE_WITHOUT_FAILURE,
    bugLlistingWithoutError: error,
    bugListWithoutLoading: true,
  };
};

const bugListingWithoutSuccess = (response) => {
  return {
    type: bugListingPageConstants.BUG_LISTING_PAGE_WITHOUT_SUCCESS,
    bugLlistingWithoutData: response.data.info.data,
    bugLlistWithoutLoading: false,
  };
};

const getBugListingDataWithout = () => {
  return (dispatch, getState) => {
    bugDataApi
      .buglistingPageLoadRequestWithout()
      .then((response) => {
        dispatch(resetAllData("BUGS"))
        dispatch(bugListingWithoutSuccess(response));
      })
      .catch((error) => {
        dispatch(bugListingWithoutError(error));
      });
  };
};

// my bugs
const myBugListingLoading = () => {
  return {
    type: bugListingPageConstants.MY_BUG_LISTING_PAGE__LOADING,
  };
};
const myBugListingError = (error) => {
  return {
    type: bugListingPageConstants.MY_BUG_LISTING_PAGE_FAILURE,
    myBugLlistingError: error,
  };
};

const myBugListingSuccess = (response) => {
  return {
    myBugLlistingLoading: false,
    type: bugListingPageConstants.MY_BUG_LISTING_PAGE__SUCCESS,
    myBugLlistingData: response.data.info.data,

  };
};

const getBugListingDatayourBugs = (type,boolean) => {
  return (dispatch) => {
    dispatch(myBugListingLoading());
    bugDataApi
      .myBuglistingPageLoadRequest(type,boolean)
      .then((response) => {
        dispatch(myBugListingSuccess(response));
      })
      .catch((error) => {
        dispatch(myBugListingError(error));
      });
  };
};
//update likes
const bugLikeError = (error) => {
  return {
    type: bugListingPageConstants.BUG_LIKE_FAILURE,
    bugLikeError: error,
    bugLikeLoading: true,
  };
};

const bugLikeSuccess = (response) => {
  return {
    type: bugListingPageConstants.BUG_LIKE__SUCCESS,
    bugLikeData: response.data.info,
    bugLikeLoading: false,
  };
};

const updateLike = (id, status) => {
  return (dispatch) => {
    bugDataApi
      .bugLikeRequest(id, status)
      .then((response) => {
        dispatch(bugLikeSuccess(response));
        if (status) {
          message.success("Bug Liked!");
        } else {
          message.success("Bug Disliked!");
        }
      })
      .catch((error) => {
        dispatch(bugLikeError(error));
        message.error(error?.response?.data?.message || "We encountered an issue while updating the likes. Please try again later!");
      });
  };
};

//comments post
const addCommentError = (error) => {
  return {
    type: bugListingPageConstants.BUG_COMMENT_FAILURE,
    addCommentError: error,
    addCommentLoading: true,
  };
};

const addCommentSuccess = (response) => {
  return {
    type: bugListingPageConstants.BUG_COMMENT__SUCCESS,
    addCommentData: response.data.info,
    addCommentLoading: false,
  };
};

const addCommentOnBugs = (id, status) => {
  return (dispatch, getState) => {
    bugDataApi
      .bugCommentRequest(id, status)
      .then((response) => {
        dispatch(addCommentSuccess(response));
        dispatch(getBugListingDataWith());
      })
      .catch((error) => {
        dispatch(addCommentError(error));
      });
  };
};

const bugPostError = (error) => {
  return {
    type: bugListingPageConstants.POST_BUG_FAILURE,
    bugPostError: error,
    bugPostLoading: false,
  };
};

const bugPostSuccess = (response) => {
  return {
    type: bugListingPageConstants.POST_BUG_SUCCESS,
    bugPostData: response.data.info,
    bugPostLoading: false,
  };
};

const bugPostLoading = (error) => {
    return {
      type: bugListingPageConstants.POST_BUG_LOADING,
      bugPostError: error,
      bugPostLoading: true,
    };
  };
const sendNotification = (response) => {
  bugDataApi
    .sendNotificationRequest(response)
    .then((response) => {})
    .catch((error) => {});
};

const bugPost = (value, url,type, createdBY,boolean,source) => {
  return (dispatch, getState) => {
    dispatch(bugPostLoading());
    bugDataApi
      .bugPostRequest(value, url,type)
      .then((response) => {
        dispatch(bugPostSuccess(response));
        if(type === "BUGS"){
          dispatch(getBugListingDataWith());
        }else if(type === "SUGGESTIONS"){
          dispatch(getSuggestionsData())
        }else if(source !=="fromHome"){
          dispatch(getSupportData())
        }
        if(source!=="fromHome"){
        dispatch(getBugListingDatayourBugs(type,boolean));
        }else{
            message.success("Ticket Created!")
        }
        dispatch(sendNotification(response, createdBY));
      })
      .catch((error) => {
        dispatch(bugPostError(error));
      });
  };
};
const UpdateBug = (value,img,type,boolean,handleData) => {
  for(let el of handleData[0].imageUrls){
    img.unshift(el);
  }
  return (dispatch, getState) => {
    bugDataApi.updateReportBugRequest(value,img,type,boolean)
      .then((response) => {
        dispatch(resetAllData(type))
        dispatch(bugPostSuccess(response));
        if(type === "BUGS"){
          dispatch(getBugListingDataWith());
        }else if(type === "SUGGESTIONS"){
          dispatch(getSuggestionsData())
        }else{
          dispatch(getSupportData())
        }
        dispatch(getBugListingDatayourBugs(type,boolean));
      })
      .catch((error) => {
        dispatch(bugPostError(error));
      });
  };
};

const changeStatus = (id,type,boolean,searchData,status,filteredData, filterStatus, like, priority, startDate, endDate) => {
  return (dispatch) => {
    bugDataApi.updateStatus(id,status)
      .then((response) => {
        message.success(`${type === "BUGS" ? "Bug" : type === "SUGGESTIONS" ? "Suggestion" : "Ticket"} status updated successfully!`)
        if(searchData?.[0]){
          dispatch(searchbug(id,type))
        }else if(filteredData?.length > 0){
          dispatch(getFilteredData(filterStatus, like, priority, startDate, endDate, type))
        }else{
          dispatch(resetAllData(type))
          if(type === "BUGS"){
            dispatch(getBugListingDataWith());
          }else if(type === "SUGGESTIONS"){
            dispatch(getSuggestionsData())
          }else{
            dispatch(getSupportData())
          }
        }
        dispatch(getBugListingDatayourBugs(type,boolean));
      })
      .catch((error) => {
        dispatch(bugPostError(error));
      });
  }
}

// delete bug
const bugDelete=(id,type,boolean,searchData)=>{
  return (dispatch)=>{
  bugDataApi.deleteBug(id).then((response)=>{
    dispatch(resetAllData(type))
    message.success(response?.info?.message||"Deleted Sucessfully!")
    if(type === "BUGS"){
      dispatch(getBugListingDataWith());
    }else if(type === "SUGGESTIONS"){
      dispatch(getSuggestionsData())
    }else{
      dispatch(getSupportData())
    }
    if(searchData?.[0]){
      dispatch(searchbug(id,type))
    }
    dispatch(getBugListingDatayourBugs(type,boolean));
   } ).catch((error)=>{
    message.error("Sorry,Bug can't delete")
   })
}
}
// search any bug
const searchbugdata= (response) => {
  return {
    type: bugListingPageConstants.SEARCH_BUG,
    bugSearchData: response.data.info,
    bugLlistLoading: false,
  };
};
const searchbug=(value,type)=>{
  return (dispatch)=>{
bugDataApi.searchBug(value,type)
.then((response) => {
  dispatch(searchbugdata(response));
})
.catch((error) => {
  dispatch(bugListingError(error));
});
  }
}
const loginModuleError = (error) => {
  return {
    type: bugListingPageConstants.BUGS_LOGIN_UPDATE_PAGE_FAIL,
    logInError: error,
    logInLoading: false,
  };
};

const loginModuleSuccess = (response) => {
  return {
    type: bugListingPageConstants.BUGS_LOGIN_UPDATE_PAGE,
    logInData: response.data.info,
    logInLoading: false,
  };
};

const loginModule = (data) => {
  return (dispatch, getState) => {
    bugDataApi
      .bugsLoginModuleRequest(data)
      .then((response) => {
        dispatch(loginModuleSuccess(response));
        logoutAndInWithBroadcast();
        // dispatch(getListingData());
      })
      .catch((error) => {
        dispatch(loginModuleError(error));
      });
  };
};

const resetListingData = () => ({
  type: bugListingPageConstants.RESET_LISTING_PAGE,
});
const clearImageRest = () => ({
  type: bugListingPageConstants.RESET_IMAGE_UPLOAD_PAGE,
});

// const loginModule = () => ({
//   type: bugListingPageConstants.LOGIN_UPDATE_PAGE,
// });
const logOut = () => {
  logoutAndInWithBroadcast();
  return({
  type: bugListingPageConstants.LOGOUT_PAGE,
})};

// resetbug post data
const resetBugPostData = () => ({
  type: bugListingPageConstants.RESET_BUG,
});

// resetbug post data
const resetSearchData = () => ({
    type: bugListingPageConstants.RESET_SEARCH,
  });

// Upload Image
const uploadImageError = (error) => {
  return {
    type: bugListingPageConstants.UPLOAD_IMAGE_FAILURE,
    uploadImageError: error,
    uploadImageLoading: false,
  };
};

const uploadImageLoading = () => {
  return {
    type: bugListingPageConstants.UPLOAD_IMAGE_LOADING,
    uploadImageLoading: true,
    uploadImageError:false,
  };
};

const uploadImageSuccess = (response) => {
  return {
    type: bugListingPageConstants.UPLOAD_IMAGE_SUCCESS,
    uploadImageData: response.data.info.docUrl
      ? response.data.info.docUrl
      : response.data.info.fileData.fileUrl,
    uploadImageLoading: false,
  };
};

const uploadCommentImageSuccess = () => {
  return {
    type: bugListingPageConstants.UPLOAD_IMAGE_SUCCESS,
    uploadImageLoading: false,
  }
}

const uploadImageforProfile = (file, origin) => {
  return (dispatch, getState) => {
    bugDataApi
      .uploadImageforProfileRequest(file, origin)
      .then((response) => {
        dispatch(uploadImageSuccess(response));
      })
      .catch((error) => {
        dispatch(uploadImageError(error));
      });
  };
};

const uploadImageforBugNew = (files, origin, values, userName,type,boolean,source) => {
    return async (dispatch) => {
      dispatch(uploadImageLoading())
      let ImageUrls = [];
      const uploadAllImages = async () => {
        dispatch(uploadImageLoading())
        try {
          for (let i = 0; i < files.length; i++) {
            let imageUrl = await bugDataApi.uploadImageforProfileRequest(files[i], origin);
            let url = imageUrl?.data?.info?.fileData?.fileUrl
            ImageUrls = [...ImageUrls, url];
          }
          dispatch(bugPost(values, ImageUrls,type,userName,boolean,source))
            .then((response) => {
              dispatch(uploadImageSuccess(response));
            })
            .catch((error) => {
              dispatch(uploadImageError(error));
            });
        } catch (error) {
          dispatch(uploadImageError(error));
        }
      };
      await uploadAllImages();
    };
  };


  const updateImageforBugNew =  (data,files, origin,type,boolean,handleData,searchData) => {
    return async (dispatch) => {
      // dispatch(bugListingLoading())
      dispatch(uploadImageLoading())
      let ImageUrls = [];
      const uploadAllImages = async () => {
        dispatch(uploadImageLoading())
        try {
          for (let i = 0; i < files.length; i++) {
            let imageUrl = await bugDataApi.uploadImageforProfileRequest(files[i], origin);
            let url = imageUrl?.data?.info?.fileData?.fileUrl
            ImageUrls = [...ImageUrls, url];
          }

        dispatch(UpdateBug(data,ImageUrls,type,boolean,handleData))
            .then((response) => {
              dispatch(uploadImageSuccess(response));
            })
            .catch((error) => {
              dispatch(uploadImageError(error));
            });
        } catch (error) {
          dispatch(uploadImageError(error));
        }
      };
      await uploadAllImages();
      if(searchData?.[0]){
        dispatch(searchbug(data._id,type))
      }
    };
  };

const bugReportComment =  (id,urlData,files)=>{
return async  (dispatch)=>{
  dispatch(bugCommentsLoading())
      let ImageUrls = [];
      const uploadAllImages = async () => {
        dispatch(bugCommentsLoading())
        try {
          for (let i = 0; i < files.length; i++) {
            let imageUrl = await bugDataApi.uploadImageforProfileRequest(files[i], origin);
            let url = imageUrl?.data?.info?.fileData?.fileUrl
            ImageUrls = [...ImageUrls, url];
          }
          bugDataApi.bugCommentRequest(id,urlData,ImageUrls)
          .then((response) => {
            message.success(response?.data?.info?.message || "Comment Added");
            dispatch(getBugComments(id))
          })
          .catch(err => {
            message.error("sorry")
          })
        } catch (error) {
          dispatch(bugCommentsError(error));
        }
      };
    await uploadAllImages();
  }
}

// get comments for bugs
const bugCommentsLoading = () => {
    return {
      type: bugListingPageConstants.GET_BUG_COMMENTS_LOADING,
    };
  };
  const bugCommentsError = (error) => {
    return {
      type: bugListingPageConstants.GET_BUG_COMMENTS_ERROR,
      bugCommentsError: error,
    };
  };

  const bugCommentsSuccess = (response,id) => {
    return {
      type: bugListingPageConstants.GET_BUG_COMMENTS_SUCCESS,
      payload:{data:response.data.info.data,id}

    };
  };

  const getBugComments = (id) => {
    return (dispatch) => {
      dispatch(bugCommentsLoading());
      bugDataApi
        .bugCommentLoadRequest(id)
        .then((response) => {
          dispatch(bugCommentsSuccess(response,id));
        })
        .catch((error) => {
          dispatch(bugCommentsError(error));
        });
    };
  };

  // render a componant
const renderCompLoading = () => {
    return {
      type: bugListingPageConstants.RENDER_COMPONANT_LOADING,
    };
  };
  const renderCompSucess = ({data}) => {
    return {
      type: bugListingPageConstants.RENDER_COMPONANT_SUCESS,
      data,
    };
  };

  const renderCompError = (response,id) => {
    return {
      type: bugListingPageConstants.RENDER_COMPONANT_FAILURE,
    //   payload:{data:response.data.info.data,id}

    };
  };

  const renderComponant = (data) => {
        // renderCompLoading();
    return (dispatch) => {
        dispatch(renderCompSucess({data}))
        // .catch((error) => {
        //   dispatch(renderCompError(error));
        // });
    };
  };

  //suggestion get method
  const suggestionsLoading = () => {
    return {
      type: bugListingPageConstants.SUGGESTIONS_DATA_LOADING,
      suggestionsDataLoading:true,
    };
  };
  const suggestionsError = (error) => {
    return {
      type: bugListingPageConstants.SUGGESTIONS_DATA_ERROR,
    };
  };

  const suggestionsSuccess = (response,pagination) => {
    return {
      type: bugListingPageConstants.SUGGESTIONS_DATA_SUCCESS,
      bugSuggestionData: response.data.info,
      pagination,
    //   suggestionsDataLoading: false,
    };
  };

  const getSuggestionsData = (page) => {
    let pagination
  if(page){pagination=true}else{pagination=false}
  return (dispatch) => {
    dispatch(suggestionsLoading());
    bugDataApi
      .buglistingPageLoadRequest(page,"SUGGESTIONS")
      .then((response) => {
        dispatch(suggestionsSuccess(response,pagination));
      })
      .catch((error) => {
        dispatch(suggestionsError(error));
      });
  };
  };

  //support get method
  const supportLoading = () => {
    return {
      type: bugListingPageConstants.SUPPORT_DATA_LOADING,
      supportDataLoading:true,
    };
  };
  const supportError = (error) => {
    return {
      type: bugListingPageConstants.SUPPORT_DATA_ERROR,
    };
  };

  const supportSuccess = (response,pagination) => {
    return {
      type: bugListingPageConstants.SUPPORT_DATA_SUCCESS,
      bugSupportData: response.data.info,
      pagination,
    };
  };

  const getSupportData = (page) => {
    let pagination
    if(page){pagination=true}else{pagination=false}
    return (dispatch) => {
      dispatch(supportLoading());
      bugDataApi
        .buglistingPageLoadRequest(page,"SUPPORT")
        .then((response) => {
          dispatch(supportSuccess(response,pagination));
        })
        .catch((error) => {
          dispatch(supportError(error));
        });
    };
  };

  const filterLoading = () => {
    return {
      type: bugListingPageConstants.FILTERED_DATA_LOADING,
      filterLoading:true,
    };
  };
  const filterError = (error) => {
    return {
      type: bugListingPageConstants.FILTERED_DATA_ERROR,
    };
  };

  const filterSuccess = (response) => {
    return {
      type: bugListingPageConstants.FILTERED_DATA_SUCCESS,
      filterData: response.data.info,
    };
  };

  const getFilteredData = (status,like,priority,startDate,endDate,type) => {
    return (dispatch)=>{
      dispatch(filterLoading())
      bugDataApi.filteredData(status,like,priority,startDate,endDate,type)
      .then((response) => {
        dispatch(filterSuccess(response));
      })
      .catch((error) => {
        dispatch(filterError(error));
      });
    }
  }

  const changeFilterStatus = (status) => {
    return {
      type:bugListingPageConstants.CHANGE_FILTER_STATUS,
      status:status
    }
  }

  const changeFilterLike = (like) => {
    return {
      type:bugListingPageConstants.CHANGE_FILTER_LIKE,
      like:like
    }
  }

  const changeFilterPriority = (priority) => {
    return {
      type:bugListingPageConstants.CHANGE_FILTER_PRIORITY,
      priority:priority
    }
  }

  const changeFilterStartDate = (startDate) => {
    return {
      type:bugListingPageConstants.CHANGE_FILTER_START_DATE,
      startDate:startDate
    }
  }

  const changeFilterEndDate = (endDate) => {
    return {
      type:bugListingPageConstants.CHANGE_FILTER_END_DATE,
      endDate:endDate
    }
  }

  const getUsersLoading = () => {
    return {
      type: bugListingPageConstants.GET_USERS_DATA_LOADING,
      usersDataLoading:true,
    };
  };
  const getUsersError = (error) => {
    return {
      type: bugListingPageConstants.GET_USERS_DATA_ERROR,
    };
  };

  const getUsersSuccess = (response) => {
    return {
      type: bugListingPageConstants.GET_USERS_DATA_SUCCESS,
      usersData: response.data.info,
    };
  };

  const getUsersData = (page,search) => {
    return (dispatch)=>{
      dispatch(getUsersLoading())
      bugDataApi.getUsersData(page,search)
      .then((response) => {
        dispatch(getUsersSuccess(response));
      })
      .catch((error) => {
        dispatch(getUsersError(error));
      });
    }
  }

  const resetFilteredData = () => {
    return {
      type:bugListingPageConstants.RESET_FILTERED_DATA
    }
  }

  const resetAllData = (tab) => {
    return {
      type:bugListingPageConstants.RESET_ALL_DATA,
      tab
    }
  }

  const resetCmntsData = (id) => ({
    type: bugListingPageConstants.RESET_CMNTS_DATA,
    id
  });

export const bugReportHandle = {
  getBugListingDataWith,
  getBugListingDataWithout,
  bugReportComment,
  bugPost,
  bugDelete,
  searchbug,
  UpdateBug,
  uploadImageforProfile,
  resetListingData,
  loginModule,
  logOut,
  clearImageRest,
  getBugListingDatayourBugs,
  updateLike,
  addCommentOnBugs,
  resetBugPostData,
  uploadImageforBugNew,
  updateImageforBugNew,
  getBugComments,
  renderComponant,
  getSuggestionsData,
  getSupportData,
  resetCmntsData,
  resetSearchData,
  getFilteredData,
  changeFilterEndDate,
  changeFilterLike,
  changeFilterPriority,
  changeFilterStartDate,
  changeFilterStatus,
  getUsersData,
  changeStatus,
  resetFilteredData,
  resetAllData,
};
