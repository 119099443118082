import React, { useEffect, useState } from "react";
import { List, Typography, Drawer, Badge, Space, Button, Switch, Dropdown, Menu, Spin } from "antd";
import { BellOutlined ,CheckSquareOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { MoreOutlined } from '@ant-design/icons';
import EachNotificialtion from "./EachNotificialtion";
import { appStatusChange } from "../state/actions";
import { useMediaQuery } from "react-responsive";
const { Title } = Typography;

const NotificationDrawer = () => {
  const dispatch=useDispatch()
  const [visible, setVisible] = useState(false);
  const [read,setRead]=useState(false)
  const [page,setPage]=useState(1)
  const isSmallScreen = useMediaQuery({maxWidth:480});

 

  const { getNotificationData,getNotificationLoading, totalrecords,getNotificationCountData,paginationdata } = useSelector(
    (state) => state.app
  );
 
const [notiCount, setNotiCount] = useState(0);
const [actualNotiCount, setAcutalNotiCount] = useState();
 useEffect(() => {
if(page>1)
{
    dispatch(appStatusChange.getNotificationtData(page));
}}, [page,dispatch])
  // const dispatch = useDispatch();
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
 
  useEffect(() => {
    setNotiCount(
      getNotificationCountData.totalCount > 9
        ? "9+"
        : getNotificationCountData.totalCount
    );
    setAcutalNotiCount(getNotificationCountData.totalCount)
  }, [getNotificationCountData]);
 const handlescroll=(e)=>{
    const element=e.target
    if (element.scrollHeight - element.scrollTop  <= window.innerHeight +150 ) {
      let pages=Math.ceil(totalrecords/20)
      if (page+1<=pages) {
        setPage(prevPage => prevPage + 1);
          }
   }}
  
   const handleMarkAllRead = () => {
    const firstNotificationId = paginationdata?.[0]?._id;
    if (firstNotificationId) {
      dispatch(appStatusChange.updateNotificationFun({ _id: firstNotificationId, markAllRead: true }));
      setRead(true);
      setNotiCount(0);
    }
  };
  
const menu = (
  <Menu
  size="large"
 
    items={[
      {
        key: '1',
        label: (
          <text onClick={ handleMarkAllRead} >
            Mark all read
          </text>
          
        ),
      },
    ]}/>)
  return (
    <>
      {totalrecords>= 0 ? (
        <Badge count={notiCount}>
          <BellOutlined
            style={{
              fontSize: "30px",
              marginRight: "30px",
              borderRadius: "50px",
              color: "white",
              marginLeft: "5px"
            }}
            onClick={showDrawer}
          />
        </Badge>
      ) : (
        <BellOutlined
          style={{ fontSize: "30px", marginRight: "30px" }}
          onClick={showDrawer}
        />
      )}

      <Drawer
        placement="right"
        onClose={onClose}
        visible={visible}
        bodyStyle={{ padding: "0", overflow:"hidden" }}
        title={<Title style={{fontSize: isSmallScreen ? "15px" : "20px", fontFamily:"sans-serif",color: "#178DFA", textAlign:"center", marginTop:'10px' }}>NOTIFICATIONS</Title> }
      >
<div style={{textAlign:"right",fontSize:"20px",paddingRight:"10px"}}>
 <Dropdown overlay={menu} placement="bottomLeft" style={{width:"100px",}}>
        <MoreOutlined/>
      </Dropdown>
</div>
<div
style={{overflow:"auto", height:"95%", }}
 onScroll={
handlescroll
}>
        <List
          className="infinite-container"
          //   loading={getClientLoading}
          itemLayout="horizontal"
          scroll="auto"
         
          dataSource={paginationdata}
          style={{ width: "100%", padding: "0" }}
          renderItem={(item) => (
            <EachNotificialtion
              setVisible={setVisible}
              item={item}
              read={read}
              notiCount={notiCount}
              actualNotiCount={actualNotiCount}
              setAcutalNotiCount={setAcutalNotiCount}
              setNotiCount={setNotiCount}
              setPage={setPage}
              isSmallScreen={isSmallScreen}
            />
          )}
        />
         {getNotificationLoading?<div style={{textAlign:"center",width:"100%"}}> <Spin/>  </div>:""}
        </div>
   
      </Drawer>
     
    </>
  );
};

export default NotificationDrawer;
