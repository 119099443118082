import { clientScreenConstants } from "./action-types.js";
const initialState = {
  setLoading: false,
  setError: "",
  clientScreenData: [],

  emailFilesLoading: false,
  emailFilesError: "",
  emailFilesData: [],

  propertUploadDocs: [],
  propertDocsLoading: false,
  propertDocsError: null,

};
const clientScreen = (state = initialState, action) => {
  switch (action.type) {
    case  clientScreenConstants.CLIENT_SCREEN_LOADING:
      return {
        ...state,
        setLoading:true,
        setError: "",
      };
    case  clientScreenConstants.CLIENT_SCREEN_ERROR:
      return {
        ...state,
        setLoading: action.setLoading,
        setError: action.setError,
      };
    case  clientScreenConstants.CLIENT_SCREEN_SUCCESS:
      
      return {
        ...state,
        setLoading: false,
        setError: false,
        clientScreenData:action.payload,
      };
      case  clientScreenConstants.EMAIL_FILES_LOADING:
        return {
          ...state,
          emailFilesLoading:true,
          emailFilesError: "",
        };
      case  clientScreenConstants.EMAIL_FILES_ERROR:
        return {
          ...state,
          emailFilesLoading: action.emailFilesLoading,
          emailFilesError: action.emailFilesError,
        };
      case  clientScreenConstants.EMAIL_FILES_SUCCESS:
        
        return {
          ...state,
          emailFilesLoading: false,
          emailFilesError: false,
          emailFilesData:action.payload,
        };

        case clientScreenConstants.UPLOAD_DOCUMENT_LOADING:
          return {
            ...state,
            propertDocsLoading: true,
            propertDocsError: null,
          };
        case clientScreenConstants.UPLOAD_DOCUMENT_SUCCESS:
          return {
            ...state,
            propertUploadDocs: action.propertUploadDocs,
            propertDocsLoading: false,
            propertDocsError: null,
          };
        case clientScreenConstants.UPLOAD_DOCUMENT_FAILURE:
          return {
            ...state,
            propertUploadDocs: [],
            propertDocsLoading: false,
            propertDocsError: null,
          };
    default:
      return state;
  }
};
export { clientScreen };
