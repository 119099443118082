import React from "react";
import { Button, Modal, Form, Input } from "antd";
import cbsr_copy from "./cbsr_copy";
import JsxParser from "react-jsx-parser";
import "./../styles/renderJsx.css";
import htmltojsx from "./htmltojsx";
import topbar from "./TopBar";

export const removeSelection = (except) => {
  let spans = document.querySelectorAll("#canvas>.page span");
  let inputs = document.querySelectorAll("#canvas>.page input");
  let textAreas = document.querySelectorAll("#canvas>.page textarea");
  let images = document.querySelectorAll("#canvas>.page img");

  spans?.forEach((e) => {
    if (e.style.cssText !== except?.target.style.cssText) {
      e.style.border = "none";
    }
  });
  inputs?.forEach((e) => {
    if (e.style.cssText !== except?.target.style.cssText) {
      e.style.border = "none";
    }
  });
  textAreas?.forEach((e) => {
    if (e.style.cssText !== except?.target.style.cssText) {
      e.style.border = "none";
    }
  });
  images?.forEach((e) => {
    if (e.style.cssText !== except?.target.style.cssText) {
      e.style.border = "none";
    }
  });
};

// Execute and change
const changes = {
  current: -1,
  length: 0,
  top: {
    change: [],
  },
  left: {
    change: [],
  },
  right: {
    change: [],
  },
  bottom: {
    change: [],
  },
  text: {
    change: [],
  },
  color: {
    change: [],
  },
  bgcolor: {
    change: [],
  },
  remove: {
    change: [],
  },
};

const makeChange = () => {
  let el = changes.top.change[changes.current].el;
  el.style.top = changes.top.change[changes.current].el;
  el = changes.left.change[changes.current].el;
  el.style.left = changes.left.change[changes.current].el;
  el = changes.right.change[changes.current].el;
  el.style.right = changes.right.change[changes.current].el;
  el = changes.bottom.change[changes.current].el;
  el.style.bottom = changes.bottom.change[changes.current].el;
  el = changes.text.change[changes.current].el;
  el.style.text = changes.text.change[changes.current].el;
  el = changes.color.change[changes.current].el;
  el.style.color = changes.color.change[changes.current].el;
  el = changes.bgcolor.change[changes.current].el;
  el.style.backgroundColor = changes.bgcolor.change[changes.current].el;
};

function Document(props) {
  // const [event,setEvent] = props.ev;
  // const [modal,setModal] = props.mdl;
  const [html, setHtml] = props.Html;
  const [showTopBar, setShowTopBar] = props.showTB;
  // const objRef = React.useRef();
  // const createDialog = ()=>{
  //     const root = document.getElementById('root');
  //     const dialog = document.createElement('dialog');
  //     dialog.setAttribute('id','dialog');
  //     dialog.innerHTML = '<div><h2>Hello world!</h2></div>'
  //     root.appendChild(dialog);
  // }

  // const openDialog = (e)=>{
  //     const top = e.target.style.top;
  //     const left = e.target.style.left;
  //     const right = e.target.style.right;
  //     const bottom = e.target.style.bottom;
  //     const text = e.target.innerText;
  //     const color = e.target.style.color;
  //     const bgcolor = e.target.style.backgroundColor || '#FFFFFF';
  //     const dialog = document.getElementById('dialog');
  //     dialog.innerHTML = `
  //         <div class='dialog-container'>
  //             <div>
  //                 <button id='undo'> Undo </button>
  //                 <button id='redo'> Redo </button>
  //             </div>
  //             <div class='flex-box'>
  //                 <div><label>top</label></div>
  //                 <div class='tooltip' data-text='top'><input id='top' /></div>
  //             </div>
  //             <div class='flex-box'>
  //                 <div><label>left</label></div>
  //                 <div class='tooltip' data-text='left'><input id='left' /></div>
  //             </div>
  //             <div class='flex-box'>
  //                 <div><label>right</label></div>
  //                 <div class='tooltip' data-text='right'><input id='right' /></div>
  //             </div>
  //             <div class='flex-box'>
  //                 <div><label>bottom</label></div>
  //                 <div class='tooltip' data-text='bottom'><input id='bottom' /></div>
  //             </div>
  //             <div class='flex-box'>
  //                 <div><label>Text</label></div>
  //                 <div class='tooltip' data-text='text'><input id='innertext'/></div>
  //             </div>
  //                 <div class='flex-box'>
  //                 <div><label>Color</label></div>
  //                 <div class='tooltip' data-text='color'><input type='color' id='color'/></div>
  //             </div>
  //             <div class='flex-box'>
  //                 <div><label>Background-Color</label></div>
  //                 <div class='tooltip' data-text='background color'><input type='color' id='bgcolor'/></div>
  //             </div>
  //             <div class='flex-box'>
  //                 <button id='remove'>Remove</button>
  //                 <button id='cancel'>Cancel</button>
  //                 <button id='propChange'>Save</button>
  //             </div>
  //         </div>
  //     `;

  //     // Prefilling the inputs
  //     document.getElementById('top').value = top;
  //     document.getElementById('left').value = left;
  //     document.getElementById('right').value = right;
  //     document.getElementById('bottom').value = bottom;
  //     document.getElementById('innertext').value = text;
  //     document.getElementById('color').value = color;
  //     document.getElementById('bgcolor').value = bgcolor;

  //     // Removing an element
  //     document.getElementById('remove').addEventListener('click',function(){
  //         e.target.remove();
  //     })

  //     // Closing the dialog box
  //     document.getElementById('cancel').addEventListener('click',function(){
  //         document.querySelector('dialog').close();
  //     })

  //     // Submitting the changes
  //     document.getElementById('propChange').addEventListener('click',function(){
  //         let top = document.getElementById('top').value;
  //         let left = document.getElementById('left').value;
  //         let right = document.getElementById('right').value;
  //         let bottom = document.getElementById('bottom').value;
  //         let text = document.getElementById('innertext').value;
  //         let color = document.getElementById('color').value;
  //         let bgcolor = document.getElementById('bgcolor').value;
  //         if(changes.current === changes.length-1){
  //             changes.current = changes.length;
  //             changes.length++;
  //         }
  //         else if(changes.current < changes.length-1){
  //             // not to increment current, but splice from current till end and length is current+1
  //             changes.top.change.splice(changes.current);
  //             changes.left.change.splice(changes.current);
  //             changes.right.change.splice(changes.current);
  //             changes.bottom.change.splice(changes.current);
  //             changes.text.change.splice(changes.current);
  //             changes.color.change.splice(changes.current);
  //             changes.bgcolor.change.splice(changes.current);
  //             changes.length = changes.current+1;
  //         }
  //         changes.top.change.push({el:e.target,v:top});
  //         changes.left.change.push({el:e.target,v:left});
  //         changes.right.change.push({el:e.target,v:right});
  //         changes.bottom.change.push({el:e.target,v:bottom});
  //         changes.text.change.push({el:e.target,v:text});
  //         changes.color.change.push({el:e.target,v:color});
  //         changes.bgcolor.change.push({el:e.target,v:bgcolor});

  //         e.target.style.top = top;
  //         e.target.style.left = left;
  //         e.target.style.right = right;
  //         e.target.style.bottom = bottom;
  //         e.target.innerText = text;
  //         e.target.style.color = color;
  //         e.target.style.backgroundColor = bgcolor;
  //         dialog.close();
  //     })

  //     // undo
  //     document.getElementById('undo').addEventListener('click',function(){
  //         if(changes.current < 1) document.getElementById('undo').disabled = true;
  //         if(changes.current === 1){
  //             changes.current--;
  //             makeChange();
  //             document.getElementById('undo').disabled = true;
  //             return;
  //         }
  //         changes.current--;
  //         makeChange();
  //     })

  //     // redo
  //     document.getElementById('redo').addEventListener('click',function(){
  //         if(changes.current === changes.length-2){
  //             changes.current++;
  //             makeChange();
  //             document.getElementById('redo').disabled = true;
  //             return;
  //         }
  //         if(changes.current < changes.length-2){
  //             changes.current++;
  //             makeChange();
  //         }
  //     })

  //     dialog.showModal();
  // }
  const topBar_interface = topbar();
  const createTopBar = () => {
    const topBar = document.createElement("div");
    topBar.setAttribute("id", "top-bar");
    topBar.innerHTML = topBar_interface;
    document.body.appendChild(topBar);
  };
  const loadInterface = () => {
    document.getElementById("top-bar").innerHTML = topBar_interface;
  };
  const removeTopBar = () => {
    document.getElementById("top-bar")?.remove();
  };
  const fillTopBar = (e) => {
    document.getElementById("top-bar").addEventListener("click", function (e) {
      e.stopImmediatePropagation();
    });
    loadInterface();
    document.getElementById("top_1").value = parseFloat(e.target.style.top);
    document.getElementById("left_1").value = parseFloat(e.target.style.left);
    document.getElementById("right_1").value = parseFloat(e.target.style.right);
    document.getElementById("bottom_1").value = parseFloat(
      e.target.style.bottom
    );
    document.getElementById("text_1").value = e.target.innerText;
    document.getElementById("text_1").disabled = true;
    document.getElementById("color_1").value = e.target.style.color;
    document.getElementById("bgcolor_1").value = "#FFFFFF";
    document.getElementById("width_1").value = e.target.style.width;
    document.getElementById("height_1").value = e.target.style.height;
    document.getElementById("size-font").value = e.target.style.fontSize;

    document
      .getElementById("propChange_1")
      .addEventListener("click", function () {
        e.target.style.top = document.getElementById("top_1").value + "px";
        e.target.style.left = document.getElementById("left_1").value + "px";
        e.target.style.right = document.getElementById("right_1").value + "px";
        e.target.style.bottom =
          document.getElementById("bottom_1").value + "px";
        e.target.innerText = document.getElementById("text_1").value;
        e.target.style.color = document.getElementById("color_1").value;
        e.target.style.backgroundColor =
          document.getElementById("bgcolor_1").value;
        e.target.style.width = document.getElementById("width_1").value + "px";
        e.target.style.height =
          document.getElementById("height_1").value + "px";
        e.target.style.fontSize =
          document.getElementById("size-font").value + "px";

        removeTopBar();
        let tbr = document.getElementById("top-bar-react");
        if (tbr) tbr.style.display = "none";
      });
    document.getElementById("remove_1").addEventListener("click", function () {
      changes.remove.change.push(e.target);
      changes.current++;
      changes.length++;
      e.target.remove();
    });
    // document.getElementById('undo_1').addEventListener('click',function(){
    //     if(changes.current < 0) return;
    //     let e = changes.remove.change[changes.current];
    //     const canvas = document.getElementById('canvas');
    //     canvas.appendChild(e);
    //     fillTopBar(e);
    //     document.getElementById('propChange_1').click();
    //     changes.current--;
    // })

    document.getElementById("bold").addEventListener("click", function () {
      if (
        e.target.style.fontWeight === undefined ||
        e.target.style.fontWeight !== "bold"
      ) {
        e.target.style.fontWeight = "bold";
      } else e.target.style.fontWeight = "normal";
    });
    document.getElementById("italic").addEventListener("click", function () {
      if (
        e.target.style.fontStyle === undefined ||
        e.target.style.fontStyle !== "italic"
      ) {
        e.target.style.fontStyle = "italic";
      } else e.target.style.fontStyle = "normal";
    });
    document
      .getElementById("underlined")
      .addEventListener("click", function () {
        if (
          e.target.style.textDecoration === undefined ||
          e.target.style.textDecoration !== "underline"
        ) {
          e.target.style.textDecoration = "underline";
        } else e.target.style.textDecoration = "none";
      });

    document
      .getElementById("word-break_1")
      .addEventListener("click", function () {
        let text = e.target.innerText;
        text = text
          .split(" ")
          .map((t) => `<span>${t} </span>`)
          .join(" ");
        e.target.innerHTML = text;
      });
  };

  const mouseOverEventListener = (element) => {
    element.addEventListener("mouseover", function (e) {
      e.stopImmediatePropagation();
      // hover effect will only be applied on elements which are not selected
      if (e.target.style.border[0] !== "2") {
        e.target.style.border = "1px solid black";
        e.target.style.borderRadius = "5px";
      }
    });
  };

  const mouseLeaveEventListener = (element) => {
    element.addEventListener("mouseleave", function (e) {
      e.stopImmediatePropagation();
      // hover effect will only be applied on elements which are not selected
      if (e.target.style.border[0] !== "2") {
        e.target.style.border = "none";
        e.target.style.outline = "none";
      }
    });
  };

  const clickEventListener = (element) => {
    element.addEventListener("click", function (e) {
      e.stopImmediatePropagation();
      if (e.target.style.border[0] !== "2") {
        removeSelection(e);
        e.target.style.border = "2px solid black";
        // setEvent(e);
        // setModal(true);
        // createDialog();
        // document.querySelector('dialog').close();
        // openDialog(e);
        removeTopBar();
        createTopBar();
        fillTopBar(e);
        let tbr = document.getElementById("top-bar-react");
        if (tbr) tbr.style.display = "block";
      } else {
        e.target.style.border = "none";
        removeTopBar();
      }
    });
  };

  const dragStartEventListener = (element) => {
    element.addEventListener("dragstart", function (e) {
      e.dataTransfer.setData("text/plain", e.target.id);
    });
  };

  const dropEventListener = (element) => {
    element.addEventListener("drop", function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      let element = e.dataTransfer.getData("text/plain");
      element = element.split("-");
      if (element[0] == "input") {
        if (element[0] !== "") {
          const node = document.createElement(element[0]);
          node.setAttribute("type", element[1]);
          const span = document.createElement("span");
          span.appendChild(node);
          e.target.appendChild(span);
        }
      } else if (element[0] == "div") {
        const node = document.createElement("div");
        node.innerText = "Signature";
        node.setAttribute("class", element[1]);
        const span = document.createElement("span");
        span.appendChild(node);
        e.target.appendChild(span);
      }
    });
  };

  React.useEffect(() => {
    // Adding Event Listeners to the Document

    let jsx_parser = document.querySelector(".jsx-parser");
    // const jsx = htmltojsx(jsx_parser.innerHTML);

    let spans = document.querySelectorAll("#canvas>.page span");
    let inputs = document.querySelectorAll("#canvas>.page input");
    let textAreas = document.querySelectorAll("#canvas>.page textarea");
    let images = document.querySelectorAll("#canvas>.page img");

    let pages = document.querySelectorAll("#canvas>.page");
    let vectors = document.querySelectorAll("#canvas>.vector");

    vectors.forEach((e, i) => {
      dropEventListener(e);
    });

    pages.forEach((e, i) => {
      dropEventListener(e);
    });

    // For span elements
    spans.forEach((e, i, arr) => {
      mouseOverEventListener(e);
      mouseLeaveEventListener(e);
      clickEventListener(e);
      dropEventListener(e);
    });

    // For input elements
    inputs.forEach((e) => {
      mouseOverEventListener(e);
      mouseLeaveEventListener(e);
      clickEventListener(e);
      dropEventListener(e);
    });

    // For textAreas
    textAreas.forEach((e) => {
      mouseOverEventListener(e);
      mouseLeaveEventListener(e);
      clickEventListener(e);
      dropEventListener(e);
    });

    // For img elements
    images.forEach((e) => {
      mouseOverEventListener(e);
      mouseLeaveEventListener(e);
      clickEventListener(e);
      dropEventListener(e);
    });
    document
      .getElementById("pdf")
      .addEventListener("fullscreenchange", function () {
        if (document.fullscreenElement) {
          setShowTopBar(true);
        } else setShowTopBar(false);
      });
    // if(document.fullscreenElement) document.getElementById('pdf').requestFullscreen();
  }, [props, showTopBar]);
  return <JsxParser jsx={html} />;
}

export default Document;
