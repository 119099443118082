import React, { useEffect, useState } from 'react';
import { FileImageOutlined,FileDoneOutlined,} from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import { FaRegFilePdf } from 'react-icons/fa'
import moment from 'moment';
import {Spin } from 'antd';
import { transactionLaneDataHandle } from '../state/actions';
import { EmptyData } from '../../Common/components/EmptyData';
import styles from "../style/rtd.module.css"
const EmailFile = ({id,offerIds,role,thirdpartyIdDoc,ertsId}) => {
  const [isHovered, setIsHovered] = useState(null);
  const [isHovered2, setIsHovered2] = useState(null);
  const { emailFilesData,emailFilesLoading,propertUploadDocs,propertDocsLoading } = useSelector((state) => state.rtdTransactionLane);

  const dispatch = useDispatch();
  const convertTimestampToMMDDYYYY = (timestamp) => {
    let date = moment(timestamp).format("MM/DD/YYYY");
    return date;
  }

  const convertTimestampTotime = (timestamp) => {

    const currentTime = moment(timestamp).format('h:mm A');
    return currentTime;
  }
  return (
    <>
   <div className={styles.documentContainer}>
      <div style={{ width: "100%", height: "90%" ,marginTop:"-20px",overFlow:"auto"}}>

          <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", padding: "5px",height:"8.5%" ,fontSize:"18px",color: "Gray",marginTop:"-25px",minHeight:"35px" }}>Emails from RE-Sure</div>

          <div style={{ display: "flex",
           width: "100%",
           alignItems:"center",
            backgroundColor: " #0078d4",
            height:"7.5%",
             padding: "2px",
              position:'sticky',minHeight:"30px" }}>
            <div style={{ width: "25.33%", textAlign: "center",fontWeight:"bold" }} className={styles.heading}>From</div>
            <div style={{ width: "25.33%", textAlign: "center",fontWeight:"bold" }} className={styles.heading}>Date/Time</div>
            <div style={{ width: "50.33%", textAlign: "center",fontWeight:"bold" }} className={styles.heading}>Contract Name</div>
          </div>
          {emailFilesLoading ?  <div style={{ width: "100%", fontSize: "15px", height: "35%",paddingTop:"10%",textAlign:"center" }}><Spin/></div>:
          <div style={{ width: "100%", fontSize: "15px", height: "35%", color: "black", overflowY: "auto" }}>
          { emailFilesData? emailFilesData?.map((ele,index) => {
            const timestamp = ele.sentDateMili;
            const formattedDate = convertTimestampToMMDDYYYY(timestamp);
            const currentTime=convertTimestampTotime(timestamp)
            return (
              <div
                 key={index}
                 style={{
                  display: "flex",
                  backgroundColor: isHovered===index ? "rgb(132, 187, 238)" : 'transparent',
                  margin: "5px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  alignItems:"center"
                }}
                onMouseEnter={() => setIsHovered(index)}
                onMouseLeave={() => setIsHovered(null)}>
                <div style={{ width: "25.33%", fontSize: "15px", padding: "5px", textAlign: "center",textOverflow:"ellipsis",whiteSpace:"nowrap",overflow:"hidden" }}>{ele.sentBy}</div>
                <div style={{ width: "25.33%", textAlign: "center",textOverflow:"ellipsis",whiteSpace:"nowrap",overflow:"hidden"  }}><p style={{height:"10px"}}>{formattedDate}</p><p style={{height:"10px",marginTop:"-5px"}}>{currentTime}</p></div>
                <div style={{ width: "50.33%", textAlign: "center",textOverflow:"ellipsis",whiteSpace:"nowrap",overflow:"hidden",padding: "5px",}}>{ele.contractName}</div>
              </div>
            );
          }):<EmptyData/>}
        </div>}

        <hr style={{ color: "#0078d4" }} />
        <div style={{ textAlign: "center", display: "flex", fontWeight: "bold",height:"8.5%", width: "100%",padding:"5px",  justifyContent: "center" ,marginTop:"-15px",minHeight:"35px"}}>
          <div><FileDoneOutlined size={18} style={{ position: "relative", fontSize: "18px", color: "#085191",paddingTop:"5px"}}  /></div>
          <div style={{ paddingLeft: "10px", paddingRight: "10px", color: "Gray",fontSize: "18px" ,paddingBottom:"5px"}}>Files Upload</div>

        </div>
        <div style={{ display: "flex", width: "100%",alignItems:"center",height:"6.5%", backgroundColor: " #0078d4", marginTop:"3px",color: "white", padding: "2px",position:"sticky",minHeight:"30px"}}>
          <div style={{ width: "70.33%", textAlign: "center",fontWeight:"bold" }} className={styles.heading}>File Name</div>
          <div style={{ width: "30.33%", textAlign: "center",fontWeight:"bold" }} className={styles.heading}>Date/Time</div>

        </div>
        {propertDocsLoading ?  <div style={{ width: "100%", fontSize: "15px", height: "25%",display:"flex",alignItems:"center",justifyContent:"center" }}><Spin/></div>:
        <div style={{ width: "100%", fontSize: "15px", height: "45%", color: "black", overflowY: "auto", }}>
        { propertUploadDocs?.length>0? propertUploadDocs?.map((ele,index) => {
          const timestamp = ele.createdDateMili;
          const formattedDate = convertTimestampToMMDDYYYY(timestamp);
          const currentTime=convertTimestampTotime(timestamp)
          return (
            <div
              key={index}
                  style={{
                  display: "flex",
                  backgroundColor: isHovered2===index ? "rgb(132, 187, 238)" : 'transparent',
                  margin: "5px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  alignItems:"center"
                }}
                onMouseEnter={() => setIsHovered2(index)}
                onMouseLeave={() => setIsHovered2(null)}

                >
            <a target ="blank" href={ele.fileUrl} style={{width: "70.33%",color:"black"}}>
            <div style={{
              display:"flex" ,
              alignItems:"center",
              padding: "5px",

             }}>
            <div style={{marginRight:"10px",color:"#0078d4"}} >
            {ele.fileType==="IMAGE"?<FileImageOutlined style={{fontSize:"20px",color:"red"}}/>:
             <FaRegFilePdf style={{fontSize:"20px",color:"red"}}/>}</div><div style={{
              width: "90%",
              textOverflow:"ellipsis",
              textAlign:"left",
              whiteSpace:"nowrap",overflow:"hidden"}}>{ele?.documentName||ele?.fileName}</div></div></a>
            <div style={{ width: "30.33%",textAlign: "center",}}><a target ="blank" href={ele.fileUrl}
            style={{color:"black",textOverflow:"ellipsis",whiteSpace:"nowrap",overflow:"hidden" }}>
            <p style={{height:"10px"}}>{formattedDate}</p><p style={{height:"10px",marginTop:"-5px"}}>{currentTime}</p></a></div>

          </div>)}):<EmptyData/>}
        </div>}
      </div>
    </div>

    </>
  );
}

export { EmailFile };

