import React from "react";
import { Button } from "antd";
import { appStatusChange } from "../../App/state/actions";
import { useDispatch } from "react-redux";
import firebase from "../../firebase";
import "../../Template/styles/Template.css";
import CustomModal from "./custom-modal";

const CommonModal = ({ unSessionFlag, sessionFlag }) => {
  let dispatch = useDispatch();
  let modifyModalStatus = (flag) => {
    unSessionFlag(); // only doing the work of closing the modal
    let sessionss = {
      modal_status: flag,
      deviceToken:null,
    };
    localStorage.setItem("userResponse", JSON.stringify(sessionss));
  };

  const pushNotify = () => {
    unSessionFlag();
    const msg = firebase.messaging();
    msg.requestPermission()
      .then(() => {
        return msg.getToken();
      })
      .then((deviceToken) => {
        let sessions = {
          modal_status: true,
          deviceToken:deviceToken,
        };
        sessionStorage.setItem("userResponse", sessions);
        localStorage.setItem("userResponse", JSON.stringify(sessions));
        dispatch(appStatusChange.deviceTokenFun(deviceToken));
        dispatch(appStatusChange.updatedeviceToken(deviceToken));
      })
      .catch((error) => {
        console.error('Error in getting permission/token:', error);
      });
  };
  
  return (
    <>
      <CustomModal isModalOpen={sessionFlag} handleModalClose={unSessionFlag}>
        <h2 style={{ fontSize: "30px", color: "#646464", fontWeight: "bold" }}>
          RE-Sure Notifications
        </h2>
        <p style={{ fontSize: "20px", color: "#646464" }}>
          Allow the RE-Sure application to send notifications about your
          business transactions and reminders.
        </p>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Button
            onClick={() => modifyModalStatus(false)}
            style={{
              marginRight: "15px",
              background: "#dbdbdb",
              color: "white",
              bordeRadius: "10px",
              width: "50%",
              borderRadius: "10px",
              height: "45px",
              fontSize: "20px",
              textTransform: "uppercase",
            }}
          >
            No
          </Button>
          <Button
            style={{
              background: "#085191",
              borderRadius: "10px",
              marginRight: "15px",
              color: "white",
              bordeRadius: "10px",
              width: "50%",
              height: "45px",
              fontSize: "20px",
              textTransform: "uppercase",
            }}
            onClick={() => pushNotify()}
          >
            Yes
          </Button>
        </div>
      </CustomModal>
    </>
  );
};

export default CommonModal;
