import { Button, Form, Input, message, Modal, Steps } from 'antd'
import React, { useEffect, useState } from 'react'
import SendTitleCompany from './SendTitleCompany';
import DocumentList from './DocumentList';
import { useDispatch, useSelector } from 'react-redux';
import { transactionLaneDataHandle } from '../state/actions';
import { v4 as uuidv4 } from "uuid";

const TitleCompanyDocument = ({ selectedData, setSendCompanyModal, selectedStatus, section }) => {
  const delegate = ( section === "DELEGATE");
  const [modalstep,setModalStep]=useState(0)
  const [selectedtext, setSelectedText] = useState("");
  const [emailSignatureId, setEmailSignatureId] = useState({});
  const [data,setData] = useState({
    selectedPerson: [],
    personData: [],
    emailMessage: "",
    documentSelected: [],
    sharedSelected: []
  });
  const [form] = Form.useForm();
  const { getTitleCompanySelectedData } = useSelector((state) => state.rtdTransactionLane);
  const dispatch = useDispatch();

  const next = () => {
    setModalStep(modalstep + 1);
  };

  const prev = () => {
    setModalStep(modalstep - 1);
  };

  const onClose=()=>{
    setSendCompanyModal(false)
  }

  useEffect(() => {
    let flag=[]
    // Initial state of arr based on default selected rows
    flag = [
      ...(getTitleCompanySelectedData?.titleCloserEmail ? [{
        _id: getTitleCompanySelectedData?.titleCloserId || null,
        name: getTitleCompanySelectedData?.titleCloserName,
        email: getTitleCompanySelectedData?.titleCloserEmail,
        key: uuidv4()
      }] : []),
      ...(getTitleCompanySelectedData?.salesRepEmail ? [{
        _id: getTitleCompanySelectedData?.salesRepId || null,
        name: getTitleCompanySelectedData?.salesRepName,
        email: getTitleCompanySelectedData?.salesRepEmail,
        key: uuidv4()
      }] : []),
      ...(getTitleCompanySelectedData?.titleOfficeEmail ? [{
        _id: getTitleCompanySelectedData?.titleOfficeId || null,
        name: getTitleCompanySelectedData?.titleOfficeName,
        email: getTitleCompanySelectedData?.titleOfficeEmail,
        key: uuidv4()
      }] : []),
    ];
    setData({
      ...data,
      personData: [ ...(data?.personData), ...flag ]
    });
  }, []);

  const handleSend=()=>{
    let manualEmails = [];
    let ids = [];
    data?.personData?.forEach((element)=>{
      if(data?.selectedPerson?.includes(element?.key)){
        if(element?._id) ids.push(element?._id);
        else manualEmails.push({
          fullName: element?.name,
          email: element?.email
        });
      }
    })
    const payload={
      ...(data.documentSelected.length ? {documentIds: data.documentSelected} : {}),
      ...((getTitleCompanySelectedData?.titleOfficeId && ids.length) ? {
        sentTo: {
          titleOfficeId: getTitleCompanySelectedData.titleOfficeId,
          ids
        }
      } : {}),
      propertyId: selectedData.propertyId,
      offerId: selectedData.offerId,
      ...(delegate ? {delegateSide : selectedStatus} : {}),
      sentBy: (selectedStatus === "SELLER") ? "SELLER_AGENT" : "BUYER_AGENT",
      ...(data.emailMessage ? {emailMessage: data.emailMessage} : {}),
      ...(manualEmails.length ? {readOnlyEmailsArray: manualEmails} : {}),
      ...(data.sharedSelected.length ? {sharedFolderDocsArray: data.sharedSelected} : {}),
      ...((emailSignatureId?.isDefault && emailSignatureId?._id) ? {emailSignatureId:emailSignatureId?._id}:{})

    }
    console.log(emailSignatureId)
    dispatch(transactionLaneDataHandle.sendTitleCompanyDocument(payload))
    setSendCompanyModal(false)
  }

  const steps = [
    {
      title:  <div style={{cursor:"pointer",fontSize:"16px"}}>First</div>,
      content: (
        <SendTitleCompany
          selectedtext={selectedtext}
          setSelectedText={setSelectedText}
          data={data}
          setData={setData}
          emailSignatureId={emailSignatureId}
          setEmailSignatureId={setEmailSignatureId}
        />
      ),

    },
    {
      title:  <div><text style={{ fontSize:"16px" }}>Second</text></div>,
      content: (
        <DocumentList
            setData={setData}
            data={data}
            selectedData={selectedData}
            section={section}
            role={selectedStatus}
            reloadSection="RTD"
            RTDInitiator={selectedStatus}
        />
      ),
    }
  ];

  const handleEmailAddress = (value) => {
    let emailPresent = data?.personData?.filter((el) => el?.email === value?.email);
    if(emailPresent?.length === 0){
      const uniqueId = uuidv4();
      setData({ ...data, personData: [...(data?.personData), { _id: null, email: value?.email, key: uniqueId, name: value?.fullName}], selectedPerson: [ ...(data?.selectedPerson), uniqueId ] });
      form.resetFields();
    }else{
      message.info("This email is already added!")
    }
  };

  return (
    <Modal
      title="Send Documents"
      visible={true}
      onCancel={onClose}
      footer={null}
      width={800}
      style={{marginTop: "-50px"}}
    >
      <div style={{marginTop:"-10px"}}>
        {modalstep === 0 && <Form style={{ width: "100%", textAlign: "center", display: "flex", justifyContent: "center", gap: "10px" }} form={form} onFinish={handleEmailAddress}>
          <Form.Item
            name="fullName"
            rules={[
              { required: true, message: "Please enter full name!" },
              { whitespace: true }
            ]}
          >
            <Input style={{ width: "250px", height: "32px" }} placeholder="Enter full name" type='text'/>
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Please enter email!" },
              {
                type: 'email',
                message: 'Please enter a valid email!',
              },
            ]}
          >
            <Input style={{ width: "250px", height: "32px" }} placeholder="Enter email address" type="email" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>}
        <div className="steps-content" style={{ marginTop: "20px",height:"420px",overflowY:"auto"}}>
          {steps[modalstep]?.content}
        </div>

        <div className="steps-action" style={{ marginTop: "25px" }}>

          {
            (modalstep === 1) && <Button
              style={{ margin: "0 8px", }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          }
          {
            (modalstep === 0) && <Button
              type="primary"
              disabled={!(data?.selectedPerson?.length)}
              style={{margin: "0 8px"}}
              onClick={() => next()}
            >
              Next
            </Button>
          }
          {
            (modalstep === 1) && <Button
              type="primary"
              disabled={!(data?.sharedSelected?.length || data?.documentSelected?.length)}
              style={{
                margin: "0 8px",
              }}
              onClick={handleSend}
            >
              Send
            </Button>
          }
        </div>
      </div>
    </Modal>
  )
}

export default TitleCompanyDocument
