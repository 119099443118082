import { Col, Form, Select, Spin } from "antd";
import React from "react";
import { TreeSelector } from "./TreeSelector";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { documentAction } from "../state/actions";
const { Option } = Select;

const PropertyTypeInput = ({
  handleOnBlurChange,
  handleOnfocusChange,
  fieldEmpty,
  onChange,
  setFormFields,
  setFieldIsEmpty,
}) => {
  const { getCategoryData, getCategoryLoading } = useSelector((state) => state.documentSection.categoryReducer, shallowEqual);
  const dispatch = useDispatch();
  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
      <Form.Item
        className="lbl"
        name="propertyType"
        label="Select Contract"
        rules={[
          {
            required: true,
            message: "Please Select Contract!",
          },
        ]}
        onFocus={() => {
          handleOnfocusChange({
            documentType: true,
            documentTypeColor: "yellow",
          })
          // dispatch(documentAction.getDocumentData())
        }
        }
        onBlur={() => {
          handleOnBlurChange({
            documentType: fieldEmpty.documentType === undefined ? false : true,
            documentTypeColor: "green",
          });
        }}
      >
        {/* <TreeSelector
          setPropertyType={setPropertyType}
          setPropertyTypeError={setPropertyTypeError}
          data={getCategoryData}
          onChangeValue={(value) => {
            if (!value) {
              handleOnBlurChange({
                documentType: false,
                documentTypeColor: "blue",
              });
            }
            if (value) {
              setPropertyTypeError(false);
            }
            onChange(value, "documentType");
          }}
        /> */}
        <Select
          showSearch
          placeholder={<span style={{fontSize: 'medium', color: '#B8BCC5'}}>Select Contract</span>}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          defaultValue={fieldEmpty?.documentType}
          onChange={(value) => {
            if (!value) {
              handleOnBlurChange({
                documentType: false,
                documentTypeColor: "blue",
              });
            }
            if (value) {
              value = JSON.parse(value);
              setFormFields((prev)=>({
                ...prev,
                contractId: value.contractId,
                contractType: value.contractType,
                name: value.name
              }))
              onChange(value.contractType, "documentType");
            }
          }}
          allowClear
          onClear={()=>{
            onChange("", "documentType");
            setFormFields((prev)=>({
              ...prev,
              contractId: "",
              contractType: "",
              name: "",
            }))
            setFieldIsEmpty({
              ...fieldEmpty,
              documentType: "",
            });
          }}
        >
          {getCategoryLoading || getCategoryLoading === null ?
          <div style={{ minHeight: "20vh", display: "flex", alignItems: "center", textAlign: "center" }}>
            <Spin size="medium" />
          </div> :
          getCategoryData?.map((element, index) => (
            <Option key={element.contractId} value={JSON.stringify(element)} disabled={element.isDisabled}>
              {element.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};

export { PropertyTypeInput };
