/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { PropertyHandlers } from '../state/actions';
import { accountHandle } from '../../Account/state/actions';
import axios from 'axios';
import { Button, DatePicker, Form, Input, List, Modal, Select, Tooltip, Typography, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../Common';
import { CheckCircleFilled, CloseOutlined, PlusCircleFilled } from '@ant-design/icons';

const Option = Select;
const { Text } = Typography;

export default function FirstStepMLSModal({data, setData, section, selectedData}) {
    const delegate = (section === "DELEGATE");
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [licenseModal, setlicenseModal] = useState({visible:false, type:""});
    const { getPropertyLicenseData, getPrpertyLicenseError, getPropertyLicenseLoading,  } = useSelector((state) => state.PropertyReducer);
    const {getUserDetailsData, createLicenseData, getLicenseData, getLicenseLoading, getLicenseError, createLicenseLoading, createLicenseError} = useSelector((state) => state.account);

    const delegateOwnerId = delegate && selectedData?.sellerAgentId?._id;
    let unmounted = {
        value: false
    };
    let source = axios.CancelToken.source();
    useEffect(() => {
        dispatch(accountHandle.getAllLicense({unmounted, source, delegateOwnerId}));
        return () => {
          unmounted.value = true;
          source.cancel("Cancelling in cleanup");
        }
    }, [createLicenseData]);

    useEffect(()=>{
        dispatch(PropertyHandlers.getMlsConstants({isMlsId: true, source, unmounted}));
        return () => {
            unmounted.value = true;
            source.cancel("Cancelling in cleanup");
        }
    }, []);

    useEffect(()=>{
        if(data.selectedMlsType.length){
            overAllUpdate(data.selectedMlsType, "STATE");
        }
    }, [getLicenseData])

    const onFinish = (values) => {
        const dateValue = values.expiryDate ? values.expiryDate.toDate() : null;
        const updatedValues = {
            ...values,
            idType: licenseModal?.type,
            expiryDate: dateValue,
        };
        dispatch(accountHandle.createLicense({payload: updatedValues, delegateOwnerId, getUserDetailsData}));
        form.resetFields();
        setlicenseModal({visible:false});
    };
    

    const handleChange = (selectedOptions) => {
        overAllUpdate(selectedOptions, "ONCHANGE");
    };

    const overAllUpdate = (loopOver, type) => {
        const updatedResults = [];
        for (const selectedValue of loopOver) {
            const matchingData = (type === "ONCHANGE") ? getLicenseData.find((data) => data.idType === selectedValue) : getLicenseData.find((data) => data.idType === selectedValue.value);
            if (matchingData) {
                updatedResults.push({ value: (type === "ONCHANGE") ? selectedValue : selectedValue.value, id: matchingData.id, expiryDate: matchingData.expiryDate});
            } else {
                updatedResults.push({ toggle: true, value: (type === "ONCHANGE") ? selectedValue : selectedValue.value });
            }
        }
        setData({
            ...data,
            selectedMlsType: updatedResults
        })
    }

    const handleRemoveItem = (item) => {
        const updatedValues = data?.selectedMlsType?.filter(
            (ele) => ele.value !== item.value,
        );
        setData({
            ...data,
            selectedMlsType: updatedValues
        })
    };

    return (
        <div style={{ minHeight: "400px" }}>
            <Modal
                visible={licenseModal.visible}
                width={600}
                title={
                    <div style={{ position: "absolute", top: "0", left: "0", right: "0", height: "50px", display: "flex", alignItems: "center", background: "#2171B9",}}>
                        {" "}
                        <Text style={{ color: "white", textDecorationLine: "underline", marginLeft: "200px", fontSize: "20px", }}>
                            ADD {licenseModal?.type} ID LICENSE
                        </Text>
                    </div>
                }
                okText="Save"
                cancelText="Cancel"
                footer={false}
                onCancel={() => {
                    form.resetFields();
                    setlicenseModal({visible:false});
                    // setSelectedIdType("");
                }}
                onOk={() => {}}
            >
                {" "}
                {createLicenseLoading ? (
                    <> <Loading /> </>
                ) : (
                    <>
                        <Form
                            form={form}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 18 }}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            {licenseModal?.type !== 'RECOLORADO' &&(
                            <Form.Item
                                label="Expiration Date"
                                name="expiryDate"
                                // rules={[{ message: "Please Enter Expiration Date" }]}
                            >
                                <DatePicker
                                    inputReadOnly={true}
                                    style={{ width: "100%" }}
                                    format="MM/DD/YYYY"
                                    placeholder="Select Expiration Date"
                                />
                            </Form.Item>)}

                            <Form.Item
                                label="ID No"
                                name="id"
                                initialValues=''
                                rules={[
                                    { required: true, message: "Please Enter Id Number" },
                                    {whitespace: true}
                                ]}
                            >
                                <Input  placeholder="Enter ID Number" allowClear />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ background: "#2171B9" }}
                                >
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )}
            </Modal>
            {
                (createLicenseLoading || getLicenseLoading || getPropertyLicenseLoading) ? <div style={{marginTop:"20%"}}><Loading/></div> : (createLicenseError || getLicenseError || getPrpertyLicenseError) ? "" :
                <>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <Select
                            placeholder="Select MLS"
                            showArrow
                            mode="multiple"
                            value={data?.selectedMlsType}
                            size="large"
                            style={{ width: "100%", marginRight: "10px" }}
                            onChange={handleChange}
                        >
                            {getPropertyLicenseData &&
                                getPropertyLicenseData?.map((item) => (
                                <Option key={item.value} value={item.value}>
                                    <img
                                        src={item.label}
                                        alt={item.value}
                                        style={{
                                            width: "100px",
                                            height: "25px",
                                            margin: "auto",
                                            objectFit: "contain",
                                        }}
                                    />
                                </Option>
                                ))}
                        </Select>
                    </div>
                    <List
                        dataSource={data.selectedMlsType}
                        renderItem={(item, index) => (
                            <List.Item>
                                <List.Item.Meta
                                title={<span>Id Name: {item?.value}</span>}
                                description={
                                    <div
                                    style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                    <div style={{ lineHeight: "5px", fontWeight: "bold" }}>
                                        <p>ID No: {item?.id ? item?.id : "NA"}</p>
                                        <p>
                                        Expiry Date:{" "}
                                        {item?.expiryDate ? item?.expiryDate : "NA"}
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        width: "60px",
                                        }}
                                    >
                                        {item?.toggle ? (
                                        <div
                                            onClick={() => {
                                                setlicenseModal({visible:true, type: item?.value});
                                            }}
                                            style={{
                                            height: "25px",
                                            width: "70px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            }}
                                        >
                                            <Tooltip
                                            title="Add Liscense Details."
                                            trigger={"hover"}
                                            >
                                            <PlusCircleFilled
                                                style={{
                                                fontSize: "25px",
                                                color: "#085191",
                                                }}
                                            />
                                            </Tooltip>
                                        </div>
                                        ) : (
                                        <div
                                            style={{
                                            height: "25px",
                                            width: "70px",
                                            display: "flex",
                                            alignItems: "center",
                                            }}
                                        >
                                            <Tooltip title="MLS Selected!" trigger={"hover"}>
                                            <CheckCircleFilled
                                                style={{ fontSize: "25px", color: "green" }}
                                            />
                                            </Tooltip>
                                        </div>
                                        )}
                                        <div>
                                        <Tooltip title="Remove MLS" trigger={"hover"}>
                                            <CloseOutlined
                                            onClick={() => handleRemoveItem(item)}
                                            style={{
                                                fontSize: "20px",
                                                cursor: "pointer",
                                                marginTop: "3px",
                                            }}
                                            />
                                        </Tooltip>
                                        </div>
                                    </div>
                                    </div>
                                }
                                />
                            </List.Item>
                        )}
                    />
                </>
            }
        </div>
    )
}
