import React, { useState } from 'react';
import { Col, Image, Row } from 'antd';
import { ApartmentOutlined, FileDoneOutlined } from "@ant-design/icons";
import {  useSelector } from 'react-redux';
import { DefaultScreen } from './DefaultScreen';
import styles from "./styles/ClientScreen.module.css"

export default function ClientScreen() {
 
  const [defaultScreen, setDefaultScreen] = useState(true);
  const { getUserDetailsData } = useSelector((state) => state.account);
  return (
    <div >
      <Row
        gutter={[4, 4]}
        style={{
          // marginLeft: "unset",
          marginRight: "5px",
          marginTop: "10px",
          marginLeft: "5px",
          // width: "100%",
          overflowX:"hidden"
         
        }}
      >
        <Col
          span={24}
          xs={24}
          sm={24}
          md={24}
          lg={7}
          xl={6}
          
        >
        <div 
        style={{
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "8px",
           width:"100%",
            border: "1px solid  rgba(0,0,0,0.2)",
          padding:"0.5rem",
          height:"100%"
          }}>
          <div
           className={styles.firsttab}
          >
             {getUserDetailsData?.propertyData?.propertyImages?.[0]?.imageUrl ?
              <Image
                width={"100%"}
                height={200}
                src={getUserDetailsData?.propertyData?.propertyImages?.[0]?.imageUrl ? getUserDetailsData?.propertyData?.propertyImages?.[0]?.imageUrl : ""}
                alt="home"
              />
              : ""
            }
            {getUserDetailsData?.propertyData?.address}
          </div>
          <div
            className="modal_row"
            style={{
              marginTop: "3px",
              marginBottom: "14px",
              backgroundColor: defaultScreen ? "#96c8f6" : "transparent",
              marginRight: "23px",
              borderRadius: "5px",
            }}
            onClick={() => setDefaultScreen(true)}
          >
            <div className="icon_div">
              <FileDoneOutlined style={{ fontSize: "22px", color: "black" }} />
            </div>
            <div className="content_div">
              <p className="modalContent" style={{ marginLeft: "20px" }}>Dashboard</p>
            </div>
          </div>
          <div className="modal_row" style={{ backgroundColor: !defaultScreen ? "#96c8f6" : "transparent", marginRight: "23px", borderRadius: "5px" }}>
            <div className="icon_div">
              <ApartmentOutlined style={{ fontSize: "22px", color: "black" }} />
            </div>
            <div className="content_div" style={{color:"gray"}} >
              <p className="modalContent" style={{ marginLeft: "20px",color:"gray",cursor:"not-allowed" }}>Task</p>
            </div>
          </div>
          </div>
        </Col>
        <Col span={24} xs={24} sm={24} md={24} lg={17} xl={18}>
          <div className="OfferTopContainer" style={{ height: "calc(100vh - 4.4rem)", padding: "0.5rem",
           boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "8px", width: "100%", border: "1px solid  rgba(0,0,0,0.2)", paddingBottom: "2rem" }}>
            <DefaultScreen />
          </div>
        </Col>
      </Row>
    </div>
  );
}
