import { Modal } from "antd";
import React from "react";
import { useEffect } from "react";

const DebugModal = ({
  setIsModalVisible,
  isModalVisible,
  setLocations,
  active,
  locations,
  finalAddressData,
}) => {
  const onSuccess = (location) => {
    setLocations({
      // 16.166700, 74.833298
      lat: location.coords.latitude,
      lng: location.coords.longitude,
    });
    active(true);
  };

  const onError = (error) => {};
  useEffect(() => {
    if (!("geolocation" in navigator)) {
      onError({
        code: 0,
        message: "Geolocation not supported",
      });
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title={<h3 style={{ textDecoration: "underline" }}>GPS Details</h3>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
              borderBottom: "3px solid #F0F0F0",
              justifyContent: "space-between",
            }}
          >
            <h2>Latitude</h2> <h2>{locations?.lat}</h2>
            <h2>Longitude</h2> <h2>{locations?.lng}</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
              borderBottom: "3px solid #F0F0F0",
              justifyContent: "space-between",
            }}
          >
            <h2>Google Searched Address</h2>{" "}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <ul>
                {finalAddressData &&
                  finalAddressData.map((item, i) => {
                    return (
                      <li key={i}>
                        <h4>{`${item?.streetNumber},${item?.streetName}`}</h4>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h2>MLS Searched Address</h2>{" "}
            <h2>No address available for these gps co-ordinates</h2>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DebugModal;
