/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {  Drawer, Dropdown, Grid, Menu, Tooltip, message } from "antd";
import "./style/index.css";
import RtdDesign from "./component/RtdDesign";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { transactionLaneDataHandle } from "./state/actions";
import { CgPrinter } from "react-icons/cg";
import { ArrowLeftOutlined, MoreOutlined, MailOutlined, UsergroupAddOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { decryptUrlParams, encryptStr } from "../Common/utils/extraFunctions";
import { getNeedToKnowRoute } from "./utils/helper";
import { Loading } from "../Common/components/Loading";
import DocsAndVendors from "./component/DocsAndVenders";
import styles from "./style/rtd.module.css";
import axios from "axios";
import { SignableAndReadOnly } from "../PropertyComp/components/SignableAndReadOnly";
import RtdLegend from "../Common/components/RtdLegend";
import DoraDocument from "./component/DoraDocument";
const { useBreakpoint } = Grid;

const TransactionLane = ({ RTDInitiater, section }) => {
  const delegate = (section === "DELEGATE");
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [showlegend, setShowLegend] = useState(false);
  const [nestedModalOpen, setNestedModalOpen] = useState({
    isEmailModalVisible: false,
    sendMultipleDoc: false,
  });
  const { getIndividualTransactionData, getIndividualTransactionError, addEmailSuccess, getTransactionLaneLoading, getTransactionLaneError,getTransactionLaneData } = useSelector((state) => state.rtdTransactionLane, shallowEqual);
  let { uploadedPdfDocSuccess } = useSelector((state) => state.PropertyReducer,shallowEqual);
  const rtdClient = (pathname?.split("/")[(pathname?.split("/")?.length) - 2] === "sDashboard") ? "SELLER" : "BUYER";

 
  const currentAgentDetails = getIndividualTransactionData?.currentAgentId;
 
  const handleEmailIconClick = (event) => {
    // event.stopPropagation();
    if (!nestedModalOpen.isEmailModalVisible) {
      setNestedModalOpen({
        ...nestedModalOpen,
        isEmailModalVisible: true,
        sendMultipleDoc: true,
      });
    }
  }
  const screens = useBreakpoint();

  console.log("pathname", pathname);
  useEffect(() => {
    let unmounted = { value: false };
    let source = axios.CancelToken.source();
    let arr = pathname.split("/");
    let offerId = arr.pop();
    offerId = offerId.split("-").join("");
    console.log("offerId", offerId);
    const delegateSide = delegate && rtdClient;
    const queryParams = new URLSearchParams(search);
    const encryptedOwnerId = queryParams.get("ref");
    const delegateOwnerId = encryptedOwnerId ? decryptUrlParams(encryptedOwnerId) : null;

    dispatch(transactionLaneDataHandle.getIndividualTransaction({ offerId, setLoading, unmounted, source, delegateSide, delegateOwnerId }));

    return () => {
      // dispatch(transactionLaneDataHandle.resetRTDData());
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    };
  }, [addEmailSuccess, uploadedPdfDocSuccess,]);

  const handlePrintTransRep = (item) => {
    let buildData = {
      builtForId: currentAgentDetails?._id,
      key: currentAgentDetails?._id,
      builtForSection: "DOCUMENT",
      signable: true,
      offerId: getIndividualTransactionData?.offerId,
      token: localStorage.getItem("userAuth"),
      contractId: '63b6c7f1379c01d3efe69b12',
      contractType: "TR",
      ...(delegate ? {delegateOwnerId: currentAgentDetails?._id} : {})
    };
    dispatch(transactionLaneDataHandle.getDraftDocumentData(buildData, pathname, "Print"));
  };

  const openContactSheet = () => {
    let buildData = {
      builtForId:currentAgentDetails?._id,
      key: currentAgentDetails?._id,
      builtForSection: "CONTACT_SHEET",
      openFor: (rtdClient === "SELLER" ? "SELLER_AGENT" : "BUYER_AGENT"),
      signable: false,
      offerId: getIndividualTransactionData.offerId,
      token: localStorage.getItem("userAuth"),
      ...(delegate ? { delegateOwnerId: currentAgentDetails?._id, }:{})
    };
    dispatch(transactionLaneDataHandle.getDraftDocumentData(buildData, pathname));
  }




  return (
    <>
    {
      loading ? <Loading /> : (
        getIndividualTransactionError ? "" : <div
          className={(rtdClient === "SELLER") ? styles.laneMainDiv :  styles.laneMainBDiv}
        >
          {
            (rtdClient === "SELLER") && !(getTransactionLaneLoading || getTransactionLaneError) && <DocsAndVendors rtdClient={rtdClient} section={section}/>
          }

          <RtdLegend setShowLegend={setShowLegend} showlegend={showlegend} seller={rtdClient} />

          {
            (screens.xl || screens.xxl) && rtdClient === "SELLER" &&
            <div style={{width: "25%"}}>
              <DoraDocument seller={rtdClient} showDrawer={true} section={section} />
            </div>
          }
          
          <div className={delegate ? styles.rtdDelegateWidthDiv : styles.rtdWidthDiv}>
            <div className={styles.rtdNavContainer}>
                 <span
                 className={styles.documentName}
                  onClick={() => {
                    history.push({ pathname: pathname?.split("/transactions")?.[0] + "/transactions", state: { category: rtdClient, categoryId: (rtdClient === "BUYER") ? getIndividualTransactionData.offerId : getIndividualTransactionData.propertyId }});
                  }}
                  style={{
                    marginRight: "10px",
                    fontSize: "18px",
                  }}
                >
                  <ArrowLeftOutlined style={{ marginRight: "7px" }} />
                  Back
                </span>
                <span className={styles.documentName} onClick={() => setShowLegend(true)} >
                    <InfoCircleOutlined  style={{marginRight: "5px",marginBottom: "-3px",}} />
                    <span className={styles.rtdNavbar}>Information</span>
                </span>
                {(getIndividualTransactionData?.status === "ACCEPTED") && (
                    <span className={styles.documentName} onClick={handlePrintTransRep} style={{marginLeft: "10px",}}>
                      <CgPrinter style={{marginRight: "5px",marginBottom: "-3px",}}/>
                        <span className={styles.rtdNavbar}>Transaction Report</span>
                    </span>
                )}
                <span onClick={openContactSheet} className={styles.documentName}>
                <UsergroupAddOutlined  style={{marginRight: "5px",marginBottom: "-3px",}}/>
                <span className={styles.rtdNavbar}>Contact Sheet</span>
                </span>
                <span onClick={handleEmailIconClick} className={styles.documentName}>
                       <MailOutlined />
                       <span className={styles.rtdNavbar}>{" "}Send Email</span>
                </span>
              {/* </div> */}

            </div>
            <>
            <SignableAndReadOnly
                componentUsedSection="TRANSACTION_DASHBOARD"
                nestedModalOpen={nestedModalOpen}
                setNestedModalOpen={setNestedModalOpen}
                RTDInitiator={rtdClient}
                role={rtdClient}
                docSelected={false}
                source={ rtdClient === "SELLER" ? "SELLING_RTD" : "BUYING_RTD"}
                selectedData={getTransactionLaneData[0]}
                section={section}
                reloadSection="RTD"
                delegateOwnerId={delegate && currentAgentDetails?._id}
              />

              <RtdDesign
                currentAgentDetails={currentAgentDetails}
                client={rtdClient}
                section={section}
              />
            </>
          </div>

          {
            (rtdClient === "BUYER") && !(getTransactionLaneLoading || getTransactionLaneError) && <DocsAndVendors rtdClient={rtdClient} section={section}/>
          }

          {
            (screens.xl || screens.xxl) && rtdClient === "BUYER" &&
            <div style={{width: "25%"}}>
              <DoraDocument seller={rtdClient} showDrawer={true} section={section} />
            </div>
          }
        
        </div>
      )
    }
  </>
  );
};

export { TransactionLane };
