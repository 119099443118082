import { Modal,} from "antd";
import React, { useRef, useState } from "react";
import { EmailModal } from "./EmailModal";


const SignableAndReadOnly = ({
      role,
      nestedModalOpen,
      setNestedModalOpen,
      currentPropertyDocId,
      setCurrentPropertyDocId,
      selectedData,
      documentId,
      source,
      section,
      agentStatus,
      ispdfClone,
      delegateOwnerId,
      RTDInitiator,
      client,
      contractType,
      reloadSection,
      componentUsedSection,
      allBuyerAgentData,
      allSellerAgentData,
      selectedClient,
      docSelected
     }) => {
  const [newState, setNewState] = useState({email:[],isOpen:false});
  const [signerChecked, setSignerChecked] = useState({});
  const initialMountRef = useRef(false);

  const handleEmailCancel = () => {
    setNestedModalOpen({...nestedModalOpen,isEmailModalVisible:false});
    setNewState({email:[]});
    initialMountRef.current = false;
    setSignerChecked({});
  };
  
  return (
    <>
    
      {nestedModalOpen?.isEmailModalVisible && (
        <Modal visible={nestedModalOpen?.isEmailModalVisible} onCancel={handleEmailCancel}
        title={<div style={{display: "flex", alignItems: "center", position: "relative"}}>
            <span style={{ fontWeight: "bold", color: "grey",  margin: "0 auto" }}>
                {nestedModalOpen?.sendMultipleDoc ? "SEND EMAIL" : "SEND DOCUMENT TO"}
            </span>
        </div>
        }
        style={{marginTop:"-50px"}}
        width={800}
        footer={false}>
              <EmailModal
              role={role}
              section={section}
              source={source}
              client={client}
              contractType={contractType}
              ispdfClone={ispdfClone}
              agentStatus={agentStatus}
              initialMountRef={initialMountRef}
              selectedData={selectedData}
              signerChecked={signerChecked}
              setSignerChecked={setSignerChecked}
              setCurrentPropertyDocId={setCurrentPropertyDocId}
              currentPropertyDocId={docSelected?currentPropertyDocId:{}}
              documentId={docSelected?documentId:""}
              setNestedModalOpen={setNestedModalOpen}
              isEmailModalVisible={nestedModalOpen?.isEmailModalVisible}
              handleEmailCancel={handleEmailCancel}
              nestedModalOpen={nestedModalOpen}
              delegateOwnerId={delegateOwnerId}
              RTDInitiator={RTDInitiator}
              newState={newState}
              setNewState={setNewState}
              reloadSection={reloadSection}
            componentUsedSection={componentUsedSection}
            allBuyerAgentData={allBuyerAgentData} allSellerAgentData={allSellerAgentData}
              selectedClient={selectedClient}
              />
        </Modal>
      )}
    </>
  );
};

export { SignableAndReadOnly };
