/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tabs, Row, Col } from "antd";
import { PhotoComp } from "./PhotoComp";
import { TransactionDetails } from "./TransactionDetails";
import { DocumentDetails } from "./DocumentDetails";
import "../styles/draft.css";
const { TabPane } = Tabs;

function DocumentryRoot({ selectedClient, setDocumentType, documentType, getClientDetail, newRedirectClientToOffer, isopen, setSelectedClientData, selectedClintData, queryKey }) {
  const [activeKey, setActiveKey] = useState(queryKey ? queryKey : "Transaction");

  useEffect(() => {
    setActiveKey(queryKey ? queryKey : activeKey ? activeKey: "Transaction");
  }, [queryKey, selectedClient]);

  return (
    <>
      <div>
        <Tabs activeKey={activeKey} tabBarGutter={10} onChange={(key) => setActiveKey(key)}>
          <TabPane
            tab={
              <div style={{ paddingLeft: "1rem" }}>
                <span>
                  <span>
                    <img src="icons/tax-document.svg" alt=""></img>
                  </span>
                  <span
                    className="mozFontSize"
                    style={{
                      fontSize: "1.2rem",
                    }}
                  >
                    {" "}
                    TRANSACTIONS
                  </span>
                </span>
              </div>
            }
            key="Transaction"
          >
            {(activeKey === "Transaction") && <TransactionDetails setDocumentType={setDocumentType} documentType={documentType} id={selectedClient} getClientDetail={getClientDetail} newRedirectClientToOffer={newRedirectClientToOffer} setSelectedClientData={setSelectedClientData} selectedClintData={selectedClintData} />}
          </TabPane>
          <TabPane
            tab={
              <span>
                <span>
                  <img src="icons/document.svg" alt=""></img>
                </span>
                <span
                  className="mozFontSize"
                  style={{
                    fontSize: "1.2rem",
                  }}
                >
                  {" "}
                  DOCUMENTS{" "}
                </span>
              </span>
            }
            key="Docs"
          >
            {(activeKey === "Docs") && <DocumentDetails selectedClient={selectedClient} getClientDetail={getClientDetail} />}
          </TabPane>
          <TabPane
            tab={
              <span>
                <span>
                  <img src="icons/photo.svg" alt=""></img>
                </span>
                <span
                  className="mozFontSize"
                  style={{
                    fontSize: "1.2rem",
                  }}
                >
                  {" "}
                  PHOTOS{" "}
                </span>
              </span>
            }
            key="Photos"
          >
            <Row>
              <Col span={24}>
              {(activeKey === "Photos") && <PhotoComp id={selectedClient} /> }
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}
export { DocumentryRoot };
