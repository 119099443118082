/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteTwoTone, WarningOutlined } from "@ant-design/icons";
import { DatePicker,  Modal, Popconfirm, Spin,  Typography, message, Tooltip } from "antd";
import { Button, Form, Input, } from "antd";
import React, { useEffect, useState } from "react";
import {CloseOutlined } from "@ant-design/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styles from "../styles/Accounts.module.css"
import { accountHandle } from "../state/actions";
// import "../styles/Licence.css";
import "../styles/Licensetable.css"

import moment from "moment";
 const LicenceDetails = ({formOpen,setFormOpen}) => {
  const [idEdit, setIdEdit] = useState(false);

  const [selectedRow, setSelectedrow] = useState({});
  const [updatedId, setUpdatedId] = useState("");
  const [updatedExpiryDate, setUpdatedExpiryDate] = useState(null);
  const dispatch = useDispatch();

  const { getUserDetailsData, getLicenseData, getLicenseLoading, getLicenseError, } = useSelector(
    (state) => state.account,
    shallowEqual
  );
  
  useEffect(() => {
    if (
      !getLicenseLoading &&
      getLicenseData?.length === 0 &&
      getLicenseData?.length !== 3
    ) {
      setFormOpen(true);
    }
  }, [getLicenseData]);
  // create a new License ID on the submit of form
 

  // when the user clicks on any particular id to update then to opening the input box
  const [licenseId,setlicenseId]=useState(false)
  const handleEdit = (el) => {
    setSelectedrow(el);
    setlicenseId(true);
    setUpdatedId(el.id);
  };
  //logic for updating the LicenseId when the user clicks on save button
  const handleIdUpdate = () => {
    let payload = {
      idType: selectedRow.idType,
      id: updatedId,
    };
    dispatch(accountHandle.updateLicense({licenseMongoId: selectedRow._id, payload, getUserDetailsData}));
    setlicenseId(false);
  };

  // when the user clicks on any particular id to update then to opening the input box
  const [licenseExpiry,setlicenseExpiry]=useState(false)
  const [defaultexpiry,setDefaultExpiry]=useState(null)
  const { Text } = Typography;
  const [form] = Form.useForm();
  const handleEditExpiryDate = (el) => {
    setSelectedrow(el)
    setDefaultExpiry(el?.expiryDate)
    if(el.idType!=="COLORADO STATE LICENSE"){
      setlicenseExpiry(true)
    }
  };
  //logic for updating the LicenseId when the user clicks on save button
  const handleExpiryDateUpdate = () => {
    let payload = {
      id:selectedRow.id,
      idType:selectedRow.idType,
      expiryDate: updatedExpiryDate,
    };
    dispatch(accountHandle.updateLicense({licenseMongoId: selectedRow._id, payload, getUserDetailsData}));
    setUpdatedExpiryDate("")
    setlicenseExpiry(false);
  };

  // delete a particular license ID
  const handleDelete = (licenseMongoId) => {
    dispatch(accountHandle.deleteLicense(licenseMongoId, message));
  };
  const handleOutsideClick = () => {
    if (idEdit) {
      setIdEdit(false);
      
    }
   
  };
  const handleDateChange = (date, dateString) => {
    setUpdatedExpiryDate(dateString);
  };

  return (
    getLicenseLoading ? <div style={{display: "flex", "justifyContent": "center", alignItems: "center", minWidth: "400px", minHeight: "100px"}}>
      <Spin/>
    </div> : (getLicenseError ? "...error" : <div onClick={handleOutsideClick} style={{borderRadius:"10px",margin:"5% 0 0 10px"}}>
        {getLicenseData?.length > 0 &&(
          <table className="license" style={{width:window.screen.width>1024?"32vw":"430px",boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",}}>
            <thead>
              <tr style={{fontSize:"14px",height:"15px",borderRadius:"3px"}}>
                <th style={{width:"40%"}}>ID Type</th>
                <th style={{width:"20%"}}>ID No.</th>
                <th style={{minWidth:"100PX"}}>Expire Date</th>
                <th style={{width:"10%"}}>Delete</th>
              </tr>
            </thead>
            <tbody style={{border:"none"}}>
              {getLicenseData?.map((el) => (
                <tr key={el._id} style={{fontSize:"14px",height:"80%", }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      fontSize:"12px",
                    }}
                  >
                    {el?.label}
                </td>
                  <td
                    onClick={() => handleEdit(el)}
                    style={{
                      color: "#178DFA",
                      height: "100%",
                      fontWeight: "bolder",
                      textAlign: "center",
                      fontSize: "12px",
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <div style={{
                      paddingRight: '24px'
                    }}>
                      {el?.id.toUpperCase()}
                    </div>
                    {el.licenseStatus === "INVALID" && el.idType && (
                      <div style={{
                        position: "absolute",
                        right: 5,
                        top: "50%",
                        transform: "translateY(-50%)"
                      }}>
                        <Tooltip
                          title={el.notes==="Error validating license ID."? "Error validating license ID.":`This License ID number does not match the DORA State License ID records.`}
                          placement="right"
                        >
                          <WarningOutlined style={{ color: 'red', fontSize: '1.3em' }}/>
                        </Tooltip>
                      </div>
                    )}
                  </td>
                  <td 
                  style={{
                      fontWeight: "bolder",
                        fontSize:"12px",
                      textAlign: "center",
                      color:"black",
                      cursor: el.idType!=="COLORADO STATE LICENSE"?"pointer":"",
                      }} 
                      onClick={() => handleEditExpiryDate(el)}>
                  
                          {el?.expiryDate }
                      </td>
                  <td
                    style={{
                      color: "#DC143C",
                      textAlign: "center",
                      fontSize:"12px",
                      width: "10%",
                      cursor: "pointer",
                    }}
                  >
                    <Popconfirm
                      title="Are you sure to delete this Licence?"
                      onConfirm={() => handleDelete(el?._id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteTwoTone />
                    </Popconfirm>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
            )
        }
        <Modal visible={licenseExpiry}
            width={500}
            title={
            <div style={{position:"absolute",top:"0",left:"0",right:"0",height:"50px",display:"flex",alignItems:"center",background:"#2171B9",justifyContent:"center"}}> 
            <Text style={{ color: "white",fontSize:"20px" ,}}>
              UPDATE EXPIRY DATE 
              </Text></div>
            }
            okText="Save"
            cancelText="Cancel"
            onCancel={()=>{
              setlicenseExpiry(false)
            setUpdatedExpiryDate("")
            }}
            onOk={()=>{
              setlicenseExpiry(false)
            setUpdatedExpiryDate("")
            }}
            footer={false}
            closeIcon={
              <div style={{position:"absolute",top:"0",left:"0",right:"0"}}> 
        <CloseOutlined style={{ backgroundColor: 'white' ,borderRadius:"50%",fontSize:"15px",fontWeight:"bold",padding:'5px',color:"#2171B9",marginTop:"10px",marginRight:"5px"}} />
        </div>
            }
          > 
        
          
            <Form
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              onFinish={handleExpiryDateUpdate}
              autoComplete="off"
              style={{
                marginLeft:"50px",
                marginTop:"10px"
              }}
            >
            
            
              <Form.Item
                label="Expire Date"
                // name="expiryDate"
                // rules={[{ message: "Please Enter Expiration Date" }]}
              >
                <DatePicker
                  defaultValue={selectedRow.expiryDate?moment(selectedRow.expiryDate, 'MM-DD-YYYY') : null}
                  value={updatedExpiryDate?moment(updatedExpiryDate, 'MM-DD-YYYY') : moment(selectedRow.expiryDate, 'MM-DD-YYYY')}
                  inputReadOnly={true}
                style={{ width: '60%',marginLeft:"20px" }}
                format="MM-DD-YYYY"
                placeholder="Select Expiration Date"
                onChange={handleDateChange}
                      />
              </Form.Item>
              
              <Form.Item>
              <div style={{display:"flex"}}>
              <Button type="primary"  style={{background:"gray",borderRadius:"5px",border:"none"}} className={styles.cancelButton} onClick={()=>{
                setlicenseExpiry(false)
              }}>
              Cancel
                </Button>
                <Button type="primary" htmlType="submit" style={{background:"#2171B9",marginLeft:"10px",borderRadius:"5px"}}>
                  Save
                </Button>
                </div>
              </Form.Item>
            </Form>
        </Modal>
        <Modal visible={licenseId}
            width={400}
            title={
            <div style={{position:"absolute",top:"0",left:"0",right:"0",height:"50px",display:"flex",alignItems:"center",background:"#2171B9",justifyContent:"center"}}> 
            <Text style={{ color: "white",fontSize:"20px" ,}}>
              UPDATE ID NO.
              </Text></div>
            }
            okText="Save"
            cancelText="Cancel"
            onCancel={()=>{
              setlicenseId(false)
            setUpdatedId("")
            }}
            onOk={()=>{
              setlicenseExpiry(false)
            setUpdatedExpiryDate("")
            }}
            footer={false}
            closeIcon={
              <div style={{position:"absolute",top:"0",left:"0",right:"0"}}> 
        <CloseOutlined style={{ backgroundColor: 'white' ,borderRadius:"50%",fontSize:"15px",fontWeight:"bold",padding:'5px',color:"#2171B9",marginTop:"10px",marginRight:"5px"}} />
        </div>
            }
          > 
        
          
            <Form
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              onFinish={handleIdUpdate }
              autoComplete="off"
              style={{
                marginLeft:"50px",
                marginTop:"10px"
              }}
            >
            
            
              <Form.Item
                label="Id No."
                // name="expiryDate"
                // rules={[{ message: "Please Enter Expiration Date" }]}
              >
                <Input
                            value={updatedId.toUpperCase()}
                            style={{width:"200px"}}
                            autoFocus
                            onChange={(e) => setUpdatedId(e.target.value)}
                          />
              </Form.Item>
              
              <Form.Item >
            <div style={{display:"flex"}}>
              <Button type="primary"  style={{background:"gray",borderRadius:"5px",border:"none"}} className={styles.cancelLicenseId} onClick={()=>{
                setlicenseId(false)
              }}>
              Cancel
                </Button>
                <Button type="primary" htmlType="submit" style={{background:"#2171B9",marginLeft:"10px",borderRadius:"5px"}}>
                  Save
                </Button>
              </div>
              </Form.Item>
            </Form>
        </Modal>
      </div> 
    )
  );
};
export default LicenceDetails;
