import { Avatar, Button, Collapse, Form, Input, Tabs, Tooltip, List } from "antd";
import React from "react";
import { PlusCircleFilled, UserOutlined, CaretRightOutlined, DeleteTwoTone, UserAddOutlined } from "@ant-design/icons";
import { clientAction } from "../../Client/state/actions";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { EmptyData } from "../../Common/components/EmptyData";
import TabPane from "antd/lib/tabs/TabPane";
import { generateFullNameFromObj, generateInitialsFromObj } from "../../Common/utils/extraFunctions";
import { CreateCustomerDrawer } from "./CreateCustomerDrawer";
import { documentAction } from "../state/actions";
import { useMediaQuery } from "react-responsive";
import DelegateSeller from "./DelegateSeller";
// import { ClientList } from "../../Client/components/ClientList";

const CorpSigners = ({ setPage, handleDeleteClientOrCorp, source, sourceConditon, setShowClientListModal, delegate, setListingModalFields, onChange, delegateOwnerId, selectedSellerAgent }) => {
  const dispatch = useDispatch();
  const { Panel } = Collapse;
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const { clientsArray, drawerVisible, entityName } = useSelector((state) => state.documentSection.CorpReducer);

  const handleAddSigners = (element) => {
    setShowClientListModal(true);
    dispatch(documentAction.setCorpState({ currentlySelectedCorpData: element }));
  };
  const handleEntityNameChange = (e) => {
    dispatch(documentAction.setCorpState({ entityName: e.target.value }));
  };
  const handleInputOrSearchClick = (element) => {
    setShowClientListModal(true);
    // dispatch(documentAction.setCorpState({ showClientListModal: true }));
    dispatch(clientAction.clearGetClientData());
    setPage([1]);
  };
  const handleCreateUserClick = (element) => {
    dispatch(documentAction.setCorpState({ drawerVisible: true }));
  };

  const handleCorpAdd = () => {
    dispatch(documentAction.setCorpState({ entityName: "" }));
    let corpInfo = {
      fullName: entityName?.trim(),
      isCorp: true,
      key: uuidv4(),
      signers: [],
    };

    let newData = [...(clientsArray || []), corpInfo];
    dispatch(documentAction.setCorpState({ clientsArray: newData }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleCorpAdd();
    }
  };
  let drawerClose = () => {
    dispatch(documentAction.clearCustomerData());
    dispatch(documentAction.setCorpState({ drawerVisible: false }));
  };

  const handleTabClick = (key) => {
    if(key === "2"){
      dispatch(documentAction.setCorpType(true));
    }else{
      dispatch(documentAction.setCorpType(false));
    }
  }

  return (
    <>
      {/* create new customer */}
      <CreateCustomerDrawer visible={drawerVisible} onClose={drawerClose} nameData="contact" delegateOwnerId={delegateOwnerId}/>
      {delegate && <><DelegateSeller setListingModalFields={setListingModalFields} onChange={onChange} selectedSellerAgent={selectedSellerAgent}/><hr style={{marginTop:"30px"}}/></> }
      <Tabs defaultActiveKey="1" size="small" type="card" onTabClick={(key) => handleTabClick(key)}>
        <TabPane tab={<span style={{ textTransform: "none" }}>Persons</span>} key="1">
          <div style={{ width: "100%" }}>
            {/* <Form layout="vertical" initialValues={{}} autoComplete="off"> */}
            {sourceConditon !== "NO_EDIT" ? (
                // <div style={{width:"80%",margin:"auto",border:"1px solid red", alignItems:"center",display:"flex",justifyContent:"center"}}>
              <Form.Item name="buyer" label={<div style={{ fontSize: "16px", fontWeight: "500", color: "#8e959a" }}>Add Person</div>} rules={[{ required: true, message: "" }]}>
                <div style={{ display: "flex", gap: "10px",width:"80%",justifyContent:"center",}}>
                  <Input placeholder={`Select a person`} onClick={handleInputOrSearchClick} disabled={delegate ? !delegateOwnerId : false} />
                  <Tooltip placement="top" title="Create contact">
                    <Button disabled={delegate ? !delegateOwnerId : false} onClick={handleCreateUserClick}>
                      <UserAddOutlined style={{ fontSize: "18px", color: "#5959dcd9" }} />
                    </Button>
                  </Tooltip>
                </div>
              </Form.Item>
            //   </div>
            ) : null}
            <div
              style={{
                minHeight: isMobile ? "200px" : "300px",
                maxHeight: source === "SELLER" ? "300px" : "500px" ,
                overflow: "auto",
                marginBottom: "10px",
              }}
            >
              {clientsArray?.length > 0 ? (
                <Form.Item>
                  <div>
                    <List
                      style={{ margin: isMobile ? "0px 0px" : "0px 2%", width: isMobile ? "85%" : "89%" }}
                      dataSource={clientsArray.filter((ele) => !ele.isCorp)}
                      renderItem={(item) => {
                        const intials = generateInitialsFromObj(item);
                        return (
                          <List.Item  key={item._id} >
                            <List.Item.Meta
                              avatar={
                                intials ? (
                                  <Avatar size={45} style={{ background: "#085191" }}>
                                    {intials}
                                  </Avatar>
                                ) : (
                                  <Avatar size={45} icon={<UserOutlined />} style={{ background: "#085191" }} />
                                )
                              }
                              title={<span style={{ fontSize: "15px", display: "inline-block", verticalAlign: "middle", lineHeight: "45px" }}>{generateFullNameFromObj(item)}</span>}
                              // description={<span style={{ fontSize: "15px" }}>{item?.email}</span>}
                            />
                            {sourceConditon === "NO_EDIT" ? null : (
                              <span onClick={() => handleDeleteClientOrCorp(item, "CLIENT")} style={{ cursor: "pointer" }}>
                                <DeleteTwoTone style={{ fontSize: "medium" }} />
                              </span>
                            )}
                          </List.Item>
                        );
                      }}
                    />
                  </div>
                </Form.Item>
              ) : (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: isMobile ? "25px" : "100px" }}>
                  <EmptyData />
                </div>
              )}
            </div>
            {/* </Form>{" "} */}
          </div>
        </TabPane>

        <TabPane tab={<span style={{ textTransform: "none" }}>Corporation/Trust/Other</span>} key="2">
          <div style={{ display: "flex", width: "100%", minHeight: "350px", maxHeight: source === "SELLER" ? "350px" : "500px", flexDirection: "column", alignItems: "stretch", overflow: "auto" }}>
            {sourceConditon === "NO_EDIT" ? null : (
              <div style={{ display: "flex", gap: "10px", width:"100%" }}>
                <Input placeholder="Enter Corporation/Trust Name" onKeyPress={handleKeyPress} name="trustee" value={entityName || ""} onChange={(e) => handleEntityNameChange(e)} />
                <Button type="primary" onClick={handleCorpAdd} disabled={!entityName}>
                  Add
                </Button>
              </div>
            )}
            <Collapse accordion bordered={false} expandIcon={({ isActive }) => <CaretRightOutlined style={{ fontSize: "20px" }} rotate={isActive ? 90 : 0} />} style={{ background: "white" }}>
              {clientsArray &&
                clientsArray
                  .filter((ele) => ele.isCorp)
                  .map((ele, ind) => (
                    <Panel
                      key={ind}
                      header={
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span style={{ fontSize: "18px" }}>{ele.fullName}</span>
                            {sourceConditon === "NO_EDIT" ? null : (
                              <>
                                {" "}
                                <div style={{ position: "absolute", right: "70px" }}>
                                  <Tooltip title="Add Signers">
                                    <PlusCircleFilled
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleAddSigners(ele);
                                        return;
                                      }}
                                      style={{ fontSize: "20px", fill: "#085191", color: "#085191", marginTop: "6px" }}
                                    />
                                  </Tooltip>
                                </div>
                                <div style={{ position: "absolute", right: "20px", cursor: "pointer" }}>
                                  <Tooltip title="Delete Corporation/Trust">
                                    <DeleteTwoTone
                                      style={{ fontSize: "medium", marginTop: "8px" }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteClientOrCorp(ele, "CORP");
                                        return;
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      }
                    >
                      <List
                        dataSource={ele.signers || []}
                        renderItem={(item) => {
                          const intials = generateInitialsFromObj(item);
                          return (
                            <List.Item  key={item?._id} style={{ margin: isMobile ? "-10px 20px" : "-10px 7%", width: isMobile ? "93%" : "90%",  }}>
                              <List.Item.Meta
                                avatar={
                                  intials ? (
                                    <Avatar size={45} style={{ background: "#085191" }}>
                                      {intials}
                                    </Avatar>
                                  ) : (
                                    <Avatar size={45} icon={<UserOutlined />} style={{ background: "#085191" }} />
                                  )
                                }
                                title={<span style={{ fontSize: "15px", display: "inline-block", verticalAlign: "middle",lineHeight: "45px" }}>{item.fullName}</span>}
                              />
                              {sourceConditon === "NO_EDIT" ? null : (
                                <span onClick={() => handleDeleteClientOrCorp(ele, "SIGNERS", item)} style={{ cursor: "pointer", display: "inline-block", verticalAlign: "middle" }}>
                                  <DeleteTwoTone style={{ fontSize: "medium" }} />
                                </span>
                              )}
                            </List.Item>
                          );
                        }}
                      />
                    </Panel>
                  ))}
            </Collapse>
          </div>
        </TabPane>
      </Tabs>
    </>
  );
};

export { CorpSigners };
