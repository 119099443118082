import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Form, Modal, message, Button } from "antd";
import { ClientList } from "../../Client/components/ClientList";

import { documentAction } from "../../DocumentSection/state/actions";
import { useMediaQuery } from "react-responsive";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { CorpSigners } from "../../DocumentSection/components/CorpSigners";
import { generateFullNameFromObj } from "../../Common/utils/extraFunctions";

const AddSeller = ({  fieldEmpty, setFieldIsEmpty, delegate, setListingModalFields, listingModalFields }) => {
  const [showClientListModal, setShowClientListModal] = useState(false);
  const [input, setInput] = useState("");
  // const [drawerVisible, setDrawerVisible] = useState(false);
  const dispatch = useDispatch();
  let { clientsArray,  entityName, currentlySelectedCorpData,isCorpTrue } = useSelector((state) => state.documentSection.CorpReducer);
  const isMobile = useMediaQuery({ maxWidth: 730 });
  let delegateOwnerId = listingModalFields?.delegateSeller?.id;


  const [showSearchInput, setShowSearchInput] = useState(true);
  const [value, setValue] = useState("");
  const [page, setPage] = useState([1]);

  const handleCancel = () => {
    setShowClientListModal(false);
  };
  // when the user select any client in the clientList
  const handleClientSelected = (selectedClient) => {
    if (!selectedClient.email) {
      message.error("Please add the client's email to proceed!");
      return;
    }

    const clients = clientsArray || [];

    if (clients.length >= 10) {
      message.error(`Maximum number of clients already added.`);
      return;
    }

    const isCorp = currentlySelectedCorpData?.isCorp;
    const existingIndex = clientsArray.findIndex((el) => el.key === currentlySelectedCorpData?.key);

    if (isCorpTrue && existingIndex !== -1) {
      const existingSigners = clientsArray[existingIndex].signers || [];

      if (existingSigners.some((el) => el.personId === selectedClient._id)) {
        message.error(`Client already exists in the signers list.`);
        return;
      }
      clientsArray[existingIndex].signers = [...(existingSigners || []), { fullName: generateFullNameFromObj(selectedClient), personId: selectedClient._id }];

      dispatch(documentAction.setCorpState({ clientsArray }));
      message.success(`Signer Added!`);
    } else if (!isCorp && clients.some((client) => client.personId === selectedClient._id)) {
      message.error(`Client already exists in the sellers list.`);
      return;
    } else {
      clientsArray.push({
        fullName:generateFullNameFromObj(selectedClient),
        personId: selectedClient._id,
        isCorp: false,
        signers: [],
      });
      dispatch(documentAction.setCorpState({ clientsArray }));
      message.success(`Seller Added!`);
    }

    // onChange({ nameOfSeller, sellerDetails: selectedRows }, "searchBuyerType");
  };

  function onChange(value, key) {
    const fieldEmptyUpdated = listingModalFields;
    fieldEmptyUpdated[key] = value;
    setListingModalFields({ ...fieldEmptyUpdated });
  }

  const handleDeleteClientOrCorp = (element, type, item) => {
    if (type === "CORP") {
      handleCorpDelete(element);
      return;
    } else if (type === "SIGNERS") {
      let newDataIndex = clientsArray.findIndex((el) => el.key === element.key);
      if (newDataIndex !== -1) {
        // console.log("coming inside this one", clientsArray[newDataIndex].signers, item);
        clientsArray[newDataIndex].signers = clientsArray[newDataIndex].signers.filter((signer) => signer.personId !== item.personId);
      }
    } else {
      clientsArray = clientsArray.filter((el) => el.personId !== element.personId);
    }
    dispatch(documentAction.setCorpState({ clientsArray: clientsArray }));
  };

  const handleAdd = () => {
    setShowClientListModal(false);
  }

  const handleCorpDelete = (element = {}) => {
    Modal.confirm({
      title: `Are you sure you want to remove ${element.fullName} from this form?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "Note: This will also remove all the signers associated with it.",
      onOk() {
        let newData = clientsArray.filter((el) => el.key !== element.key);
        dispatch(documentAction.setCorpState({ clientsArray: newData }));
      },
      onCancel() {},
    });
  };


  return (
    <>
      {/* <div style={{ width:"100%", marginTop: "20px auto", border:"1px solid red" }}> */}
        <Form layout="horizontal" initialValues={{}} autoComplete="off">
          <CorpSigners setPage={setPage} showClientListModal={showClientListModal} source={"SELLER"} setShowClientListModal={setShowClientListModal} handleDeleteClientOrCorp={handleDeleteClientOrCorp} entityName={entityName} delegate={delegate} setListingModalFields={setListingModalFields} onChange={onChange} delegateOwnerId={delegateOwnerId} selectedSellerAgent={listingModalFields?.delegateSeller} />
        </Form>
      {/* </div> */}
      <Modal visible={showClientListModal} onOk={() => {}} onCancel={handleCancel} footer={false} style={{ marginTop: "-80px" }}>
        <ClientList listSource={"offerSection"} selectClient={handleClientSelected} searchBuyerNameInOffer={input} handleCancel={handleCancel} page={page} setPage={setPage} showSearchInput={showSearchInput} setShowSearchInput={setShowSearchInput} value={value} setValue={setValue} clientsArray={clientsArray} isCorpTrue={isCorpTrue} currentlySelectedCorpData={currentlySelectedCorpData} delegateOwnerId={delegateOwnerId}/>
        <Button type="primary" style={{bottom:0,left:175,position:"relative",minWidth:"10vw",marginTop:"20px"}} onClick={handleAdd} disabled={isCorpTrue && currentlySelectedCorpData?.signers?.length > 0 ? false : !isCorpTrue && clientsArray?.filter((el) => el.isCorp === false)?.length > 0 ? false : true}>Add</Button>
      </Modal>

    </>
  );
};

export { AddSeller };
