import { Template } from "../Template";
// import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import { Layout, Tabs, Col, Row } from "antd";
import React, { useRef, useState } from "react";
import Board from "./components/Board";
// import Card from "./components/Card";
// import Toolbar from "./components/Toolbar";
import "./styles/main.css";
import { templateAction } from "../Template/state/actions";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DocsClause } from "./components/DocsClause";
import { useEffect } from "react";
import styles from "./styles/Tool.module.css";
import { EMAIL_TEMPLATE } from "./components/EMAIL_TEMPLATE";
import { EmailCampaign } from "./components/EmailCampaign";
import ZipUpload from "../Account/components/ZipUpload";
import { accountHandle } from "../Account/state/actions";
import { toolsAction } from "./state/actions";
import { clientAction } from "../Client/state/actions";
import TutorialsVideos from "../Landing/components/TutorialsVideos";


const { TabPane } = Tabs;
const { Content } = Layout;

const Tools = () => {
  const dispatch=useDispatch()
  const categoryRef = useRef(null);
  useEffect(()=>{
    dispatch(toolsAction.getEmailDripConstant());
    dispatch(toolsAction.getEmailDripRealtor());
  },[])
  const history = useHistory();

 // Get method of safe
  useEffect((el) => {
    dispatch(accountHandle.getsafeTDriveDetails());
  }, []);

  return (
    <Layout style={{ height: "100%", padding: "0px" }}>
      <Content
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        <Row
          gutter={[12, 24]}
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Switch>
              {/* <Route
                exact
                path="/tools/pdf-edit"
                component={() => <TabCommon idx={"2"} />}
              /> */}

              <Route
                // exact
                path="/tools/docsClause/:id"
                component={() => <TabCommon idx={"3"} />}
              />
              <Route
                exact
                path="/tools/email_template"
                component={() => <TabCommon idx={"4"} />}
              />
               <Route
                exact
                path="/tools/safe-T-drive"
                component={() => <TabCommon idx={"5"} />}
              />
               <Route
                exact
                path="/tools/email_drip_campaign"
                component={() => <TabCommon idx={"6"} />}
              />

              <Route
                exact
                path="/tools/tutorials"
                component={() => <TabCommon idx={"7"} />}
              />

              <Route
                exact
                path="/tools"
                component={() => <TabCommon idx={"1"} categoryRef={categoryRef}  />}
              />


              {/* <Route path="*">
                <Redirect to="/error" />
              </Route> */}
            </Switch>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
// created this function for handling the routes.
function TabCommon({ idx, categoryRef}) {
  // const [activeKey, setActiveKey] = React.useState(idx);
  const [screenSize, setScreenSize] = useState("left");
  // const [model, setModel] = React.useState();
  const [selectedTab, setSelectedTab] = useState(false);

  const history = useHistory();


  // created this function for showing the exact routes when key changes
  const handleChange = (key) => {
    if (key === "1") {
      history.push("/tools");
    } else if (key === "3") {
      history.push("/tools/docsClause/clauses");
    } else if (key === "4") {
      history.push("/tools/email_template");
    }
    else if (key === "6") {
      history.push("/tools/email_drip_campaign");
    }
     else if (key === "5") {
        history.push("/tools/safe-T-drive");
      }else if(key === "7"){
      history.push("/tools/tutorials");
      }

    // else if (key === "2") {
    //   history.push("/tools/pdf-edit");
    // }
    // setActiveKey(key);
  };
  // if (activeKey === "1") {
  //   document.getElementById("top-bar")?.remove();
  // }



  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setScreenSize("top");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) {
        setScreenSize("top");
      } else {
        setScreenSize("left");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function ssoResureSign() {
    history.push("/tools")
    toolsAction.getResureSignSSOLink()
  }


  return (
    <div
      className={styles.toolsContainer}
      style={{
        padding: "0.2rem 0rem 0.5rem 0rem",
        marginTop:"5px",
        // border: "1px solid  rgba(0,0,0,0.2)",
        borderRadius: "8px",
        backgroundColor: "#fff",
        // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s",
        height: "100%",
        marginRight: ".4rem",
      
      }}
    >
      <Tabs
        defaultActiveKey={idx}
        // activeKey={activeKey}
       onChange={handleChange}
        tabPosition={screenSize}
        size="small"
        tabBarGutter={15}
      className={styles.tabscreenwidth}
        // type="card"
      >
        <TabPane
        tab={<span style={{textTransform:"none"}}>Templates</span>}
          key="1"
        >
          <Template categoryRef={categoryRef} />
        </TabPane>

        <TabPane
        tab={<span style={{textTransform:"none"}}>Docs/Clause</span>}
          key="3"
        >
          <DocsClause />
        </TabPane>
        {/* <TabPane
        tab={'Email Drip Campaign'}
          key="6"
        >
          <EmailCampaign/>
        </TabPane> */}
        <TabPane
        tab={<span style={{textTransform:"none"}}>Email Template</span>}
          key="4"
        >
          <EMAIL_TEMPLATE />
        </TabPane>
        <TabPane
        tab={<span style={{textTransform:"none"}}>RE-Sure Drive</span>}
          key="5"
        >
          <ZipUpload rowsPerPage={25}
           />
        </TabPane>

        <TabPane
          tab={
              <span
                // className={styles.tabPaneNameBug}
                style={{textTransform:"none"}}
                onClick={ssoResureSign}
              >
                RE-Sure Sign
              </span>
          }
          key="resure-sign"
          style={{ margin: 0 }}
        ></TabPane>

         {/* created another tabpane for providing the option to the user to go to the bugsReport Page */}
         <TabPane
          tab={
            <Link to="/bugsReport" target={"_blank"}>
              <span
                // className={styles.tabPaneNameBug}
                style={{color:"grey",textTransform:"none"}}
                onClick={() => history.push("/tools")}
              >
                Customer Support
              </span>
            </Link>
          }
          key="0"
          style={{ margin: 0 }}
        ></TabPane>
        
        <TabPane
          tab={<span style={{ textTransform: "none" }}>Tutorial Videos and Q&A</span>}
          key="7"
        >
          <TutorialsVideos />
        </TabPane>
        
      </Tabs>
      {/* {activeKey === "2" ? (
        <div className="toolbar">
          <Toolbar model={model} setModel={setModel} />
        </div>
      ) : null} */}
    </div>
  );
}

export default Tools;
