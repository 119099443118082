/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, Modal, Spin, Tabs, Tooltip } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React, { useEffect, useRef, useState } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import {
  FileImageOutlined,
  FolderOutlined,
  PlusCircleFilled,
  FileTextOutlined,
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { InfoCircleOutlined, MailOutlined } from "@ant-design/icons";
import moment from "moment";
import confirm from "antd/lib/modal/confirm";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CreateDocs from "./CreateDocs";
import { SignableAndReadOnly } from "./SignableAndReadOnly";
import { PropertyHandlers } from "../state/actions";
import { DraggerComp } from "../../Common/components/DraggerComp";

import axios from "axios";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import { isDisclosureDocPresent } from "../utils/helper";
import { checkIfDisabled, createOfferContractTypes, disabledTransactionText } from "../../Common/commondata/commonData";

const PropertyDocsModal = ({ isModalOpen, setIsModalOpen, role, section, item }) => {
  const delegate = (section === "DELEGATE");
  let { propertyDocs, propertDocsLoading, updatedPropertyDoc, uploadedPdfDocSuccess } = useSelector((state) => state.PropertyReducer,shallowEqual);
  const isSmallScreen = useMediaQuery({ maxWidth: 480 });
  const location = useLocation();
  const dispatch = useDispatch();
  const uploadFile = useRef("");
  // const agentDetails = (item?.isProperty || item?.isErts) ? (item?.currentSellerAgentId) : item?.currentBuyerAgentId;
  const agentDetails = (item?.currentAgentId);
  const {filterSort} = useSelector((state) => state.PropertyReducer);

  const [currentPropertyDocId, setCurrentPropertyDocId] = useState({
    clickedItem: "",
    source: "",
  });
  
  const [nestedModalOpen, setNestedModalOpen] = useState({
    isUploadDocsModal: false,
    isEmailModalVisible: false,
    isLegendOpen: false,
    sendMultipleDoc:false,
    isclearTransactionLaneData:false,
  });

  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  // console.log("age", agentDetails);
  useEffect(()=>{
    const params = {
      ...(item?.offerId ? { offerId: item?.offerId } : {}),
      ...(item?.isProperty || item?.isErts  ? item?.persistId ? { ertsId: item?.persistId } : item?.propertyId   ? { propertyId: item?.propertyId } : {}: {}),
      ...(item?.thirdpartyId ? { thirdpartyIdDoc: item?.thirdpartyId } : {}),
      openFor: item?.isProperty || item?.isErts ? "SELLER_AGENT" : "BUYER_AGENT",
      personId:  agentDetails?._id,
      ...(delegate ? ((item.isProperty || item?.isErts) ? {delegateSide: "SELLER", delegateOwnerId: agentDetails?._id} : {delegateSide: "BUYER", delegateOwnerId: item?.agentDetails?._id}) : {}),
     section: "PROPERTY_DOCS"
    };
    dispatch(PropertyHandlers.getPropertyDocsFun({params, unmounted, source}));
    // console.log("params", params);
    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [updatedPropertyDoc, uploadedPdfDocSuccess])

  // Property Docs functions
  const handleActivePropertyDocsOk = () => {
    setIsModalOpen({ ...isModalOpen, propertyDocs: false });
  };

  const handleActivePropertyDocsCancel = () => {
    setIsModalOpen({ ...isModalOpen, propertyDocs: false });
  };

  const handleuploadmodal = () => {
    setNestedModalOpen({ ...nestedModalOpen, isUploadDocsModal: true });
  };

  // const handleShareIconClick = (ele, source) => {
  //   setCurrentPropertyDocId({ clickedItem: ele, source });
  //   setNestedModalOpen({ ...nestedModalOpen, isEmailModalVisible: true });
  // };

  // ---------------  functions to open the files ---------------------
  const handlePropertyDocs = (ele) => {
    let buildData = {
      documentId: ele.documentId,
      contractType: ele.contractType,
      contractId: ele.contractId,
      openFor: item?.isProperty || item?.isErts ? "SELLER_AGENT" : "BUYER_AGENT",
      token: localStorage.getItem("userAuth"),
      builtForId: agentDetails?._id,
      key: agentDetails?._id,
      ...(item.offerId ? { offerId: item.offerId } : {}),
      ...(delegate ? { delegateOwnerId: agentDetails?._id } : {}),
      builtForSection: "DOCUMENT",
      signable: true,
      ...(ele.RTDInitiator && {  RTDInitiator: ele.RTDInitiator}),
      ...(item?.propertyId ? { propertyId: item?.propertyId } : {}),
      ...(item?.thirdpartyId ? { thirdpartyId: item?.thirdpartyId } : {}),
    };
    dispatch(transactionLaneDataHandle.doraDocsUrlShorthandApiCalling(buildData, location?.pathname));
  };

  const handleOPenDocs = (ele) => {
    if (ele?.fileUrl) {
      window.open(ele?.fileUrl);
    }
  };


// -------------------------------------------------------------------


  const handleConfirm = (ele) => {
    if (role === "SELLER") {
      confirm({
        title: (
          <>
            <p>
              Are you sure you want to share this file with{" "}
              {item?.buyerAgentId?.fullName
                ? item?.buyerAgentId?.fullName
                : ""}{" "}
              (
              {item?.buyerAgentId?.email
                ? item?.buyerAgentId?.email
                : ""}
              )
            </p>
            <p>
              <strong>Note:</strong> This shared file can be accessed from
              Shared folder in the Transaction Dashboard.
            </p>
          </>
        ),
        okText: "Yes",
        cancelText: "No",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          handleShared(ele);
        },
        onCancel() {},
      });
    } else {
      confirm({
        title: (
          <>
            <p>
              Are you sure you want to share this file with{" "}
              {item?.sellerAgentId ? (
                <>
                  {item?.sellerAgentId?.fullName && (
                    <>
                      <span>
                        {item?.sellerAgentId?.fullName}
                      </span>
                      <br />
                    </>
                  )}
                  {item?.sellerAgentId?.email && (
                    <span>({item?.sellerAgentId?.email})</span>
                  )}
                </>
              ) : (
                <>
                  {item?.newTransactionData?.[
                    "SellerBrokerName"
                  ] && (
                    <>
                      <span>
                        {
                          item?.newTransactionData?.[
                            "SellerBrokerName"
                          ]
                        }
                      </span>
                      <br />
                    </>
                  )}
                  {item?.newTransactionData?.[
                    "SellerBrokerEmailAddress"
                  ] && (
                    <span>
                      (
                      {
                        item?.newTransactionData?.[
                          "SellerBrokerEmailAddress"
                        ]
                      }
                      )
                    </span>
                  )}
                </>
              )}
            </p>{" "}
            <p>
              <strong>Note:</strong> This shared file can be accessed from
              Shared folder in the Transaction Dashboard.
            </p>
          </>
        ),
        okText: "Yes",
        cancelText: "No",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          handleShared(ele);
        },
        onCancel() {},
      });
    }
  };

  const handleConfirm2 = (ele) => {
    if (role === "SELLER") {
      confirm({
        title: (
          <>
            <p>
              Are you sure you want to Unshare this file with{" "}
              {item?.buyerAgentId?.fullName
                ? item?.buyerAgentId?.fullName
                : ""}{" "}
              (
              {item?.buyerAgentId?.email
                ? item?.buyerAgentId?.email
                : ""}
              )
            </p>
            <p>
              <strong>Note:</strong> This Unshared file will be removed from
              Shared folder in the Transaction Dashboard.
            </p>
          </>
        ),
        okText: "Yes",
        cancelText: "No",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          handleShared(ele);
        },
        onCancel() {},
      });
    } else {
      confirm({
        title: (
          <>
            <p>
              Are you sure you want to Unshare this file with $
              {item?.sellerAgentId?.fullName
                ? item?.sellerAgentId?.fullName
                : ""}
              ,($
              {item?.sellerAgentId?.email
                ? item?.sellerAgentId?.email
                : ""}
              )
            </p>
            <p>
              {" "}
              <strong>Note:</strong> This Unshared file will be removed from
              Shared folder in the Transaction Dashboard.
            </p>
          </>
        ),
        okText: "Yes",
        cancelText: "No",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          handleShared(ele);
        },
        onCancel() {},
      });
    }
  };

// The common function used in handleconfirm & handleconfirm2
  const handleShared = (ele) => {
    const params = {
      ...(item?._id ? { propertyDocId: ele._id } : {}),
      ...(item?.offerId ? { offerId: item?.offerId } : {}),
      ...(item?.persistId ? { ertsId: item?.persistId } : {}),
      ...(item?.thirdpartyId ? { thirdpartyIdDoc: item?.thirdpartyId } : {}),
      ...(ele.isShared ? { unShare: "true" } : {}),
      ...(delegate ? ((item.isProperty || item?.isErts) ? {delegateSide: "SELLER", delegateOwnerId: agentDetails?._id} : {delegateSide: "BUYER", delegateOwnerId: item?.agentDetails._id}) : {}),
    };

    if (item?.offerId) {
      dispatch(PropertyHandlers.sharePropertyDocs(params, true));
    } else {
      dispatch(PropertyHandlers.sharePropertyDocs(params, true));
    }
  };

//   Function to Upload doc will be passed as prop
  const uploadTemp = (setShowInputFileName,fileNametext,setFileNametext) => {
    setShowInputFileName(false);
    uploadFile.current.append("filename", fileNametext);
    if (item?.offerId) uploadFile.current.append("offerId", item?.offerId);
    if (item?.persistId) uploadFile.current.append("ertsId", item?.persistId);
     uploadFile.current.append("personId",agentDetails?._id);

    if(delegate){
      if (item.isProperty || item?.isErts) {
        uploadFile.current.append("delegateSide", "SELLER")
        uploadFile.current.append("delegateOwnerId", agentDetails?._id)
      } else {
        uploadFile.current.append("delegateSide", "BUYER");
        uploadFile.current.append("delegateOwnerId", agentDetails?._id)
      }
    }
    if (item?.thirdpartyId) uploadFile.current.append("thirdpartyIdDoc", item?.thirdpartyId);
    if (item?.isProperty) uploadFile.current.append("propertyId", item?._id);
    uploadFile.current.append("openFor", (item?.isProperty || item?.isErts) ? "SELLER_AGENT":"BUYER_AGENT");

    dispatch(PropertyHandlers.uploadPropertyDocsFun(uploadFile.current));
    setFileNametext("");
    setNestedModalOpen({...nestedModalOpen,isUploadDocsModal:false});
  };

  const checkForDisable = checkIfDisabled({openFor: (item?.isProperty || item?.isErts) ? "SELLER" : "BUYER", actualPropertyStatus: item?.actualPropertyStatus, buyingStatus: item?.actualOfferStatus, offerStatus: item?.status});
  const handleCreateDocCancel = () => setNestedModalOpen({ ...nestedModalOpen, isUploadDocsModal: false })

  // Function To Delete Document from propertyDocs folder

  const handleDeletePropertyDocs = (ele, docType) => {
    // Determine the message and content based on requiresWholeReRender
    const isWholeReRenderRequired = ele.contractType === "ERTS" || createOfferContractTypes.includes(ele.contractType);
    
    const confirmationMessage = isWholeReRenderRequired
      ? (
        <>
          Are you sure you want to delete this <span style={{ color: 'red' }}>{ele?.contractType}</span> document? This action will affect all other documents
        </>
      )
      : "Are you sure you want to delete this document?";
    
    const confirmationContent = isWholeReRenderRequired 
        ? "Note: This action is irreversible. Deleting this document will permanently remove it from your account and all associated documents." 
        : "Note: This action is irreversible. Deleting this document will permanently remove it from your account.";

    confirm({
      title: confirmationMessage,
      content: confirmationContent,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        if (docType === 'uploadedDoc') {
          dispatch(PropertyHandlers.removePropertyDocsFun(ele?._id));
        } else if (docType === 'contractDoc') {
          const payloadData = {
            documentId: ele?.documentId,
            ...(delegate ? ((item?.isProperty || item?.isErts) ? { delegateSide: "SELLER" } : { delegateSide: "BUYER" }) : {}),
            ...(item?.isProperty && { propertyId: item?.propertyId }),
            ...(item?.isErts && { ertsId: item?.persistId }),
            ...(item?.isOffer && { offerId: item?.offerId }),
            ...(item?.isDraftOffer && { offerId: item?.offerId }),
            requiresRender: true,
            requiresWholeReRender: isWholeReRenderRequired,
            filterSort
          };
          dispatch(PropertyHandlers.deletePropertyContractDocs({ payloadData }));
        }
      },
      onCancel() {},
    });
};

  const handleToggleDashboard = (ele, checkBoxValue) => {
   
    const payloadData = {
      documentId: ele?.documentId,
      isToggleFrom: "PROPERTY_DOCS",
      ...(delegate ? ((item?.isProperty || item?.isErts) ? { delegateSide: "SELLER" } : { delegateSide: "BUYER" }) : {}),
      ...(item?.isProperty && { propertyId: item?.propertyId }),
      ...(item?.isErts && { ertsId: item?.persistId }),
      ...(item?.isOffer && { offerId: item?.offerId }),
      ...(item?.isDraftOffer && { offerId: item?.offerId }),
      requiresRender: true,
      showInTransactionDashboard: checkBoxValue
    };
    confirm({
      title: checkBoxValue
        ? `Are you sure you want to move this document to the transaction dashboard?`
        : `Are you sure you want to remove this document from the transaction dashboard?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(PropertyHandlers.toggleDocumentInTransactionDashboard({ payloadData }));
      },
      onCancel() { },
    });
  };
  
  

  return (
    <>
      {/* Main property docs modal  */}
      <Modal
        style={{ top: "20px", height: "500px" }}
        width={750}
        title={
          <div
            className="documentModal"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "grey",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            <div style={{ width: "40%", textAlign: "left" }}>
              <FolderOutlined
                style={{
                  fontSize: isSmallScreen ? "18px" : "22px",
                  color: "rgb(8,81,145)",
                }}
              />
            </div>
            <div style={{ width: "100%", textAlign: "center", fontSize: isSmallScreen ? "15px" : "20px", color: "#178DFA" }}>Property Docs</div>
            <div style={{ width: "40%", display: "flex", alignItems: "center", justifyContent: "flex-end", gap: isSmallScreen ? "10px" : "20px", marginRight: "10px" }}>
            <Button
            onClick={()=>setNestedModalOpen({...nestedModalOpen, sendMultipleDoc:true, isEmailModalVisible:true})}
            icon={ <MailOutlined style={{fontSize: isSmallScreen ? "16px" : "25px",}}/>}
            style={{
                color: "#085191",
                border:"none",
              }}/>
              <Button
                className="borderless-button"
                style={{ border: 0 }}
                icon={
                  <PlusCircleFilled
                    style={{
                      fontSize: isSmallScreen ? "16px" : "25px",
                      color: "#085191",
                    }}
                    {...(checkForDisable?.disabled ? {onClick: disabledTransactionText} : {onClick: () => handleuploadmodal()})}
                  />
                }
              ></Button>
              <Tooltip title="Legend" placement="right">
                <InfoCircleOutlined
                onClick={() =>
                  setNestedModalOpen({
                    ...nestedModalOpen,
                    isLegendOpen: true,
                  }) }
                  style={{
                    fontSize: isSmallScreen ? "16px" : "24px",
                    cursor: "pointer",
                    fontWeight: "bold",
                    // paddingTop: "3px",
                    color: "hsl(209deg 72% 51%)",
                  }}
                />
              </Tooltip>
            </div>
          </div>
        }
        footer={false}
        open={isModalOpen?.propertyDocs}
        onOk={handleActivePropertyDocsOk}
        onCancel={handleActivePropertyDocsCancel}
        className="documentModal"
      >
        <div style={{ height: "450px", marginTop: "-20px" }} className="documentModal">
          <div>
            {propertDocsLoading ? (
              <div
                style={{
                  width: "100%",
                  fontSize: "15px",
                  height: "40%",
                  paddingTop: "10%",
                  textAlign: "center",
                }}
              >
                <Spin />
              </div>
            ) : (
              <>
                <div
                  style={{
                    width: "100%",
                    color: "black",
                    height: isDisclosureDocPresent(propertyDocs||[])? "380px":"420px",
                    overflow: "auto",
                  }}
                >
                  <div style={{ margin: "0px" }}>
                    <table className="licenseTable" style={{ width: "100%", border: 0 }}>
                      <thead style={{ position: "sticky", top: 0, zIndex: 1, background: "white" }}>
                        <tr>
                        <th>File Name</th>
                        <th>Creation Date</th>
                            <th>Shared Folder</th>
                            <th>Show in dashboard</th>
                            <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {propertyDocs?.map((ele) => (
                          <tr key={ele._id}>
                               <td
                            onClick={() => {
                              ele.isPdf === true ? window.open(ele.pdfLink, "_blank") : ele.fileType === "IMAGE" || ele.fileType === "PDF" ? handleOPenDocs(ele) : handlePropertyDocs(ele);
                            }}
                            style={{
                              fontWeight: "bolder",
                              color: "blue",
                              minWidth: "250px",
                              width: "65%",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                fontSize: isSmallScreen ? "13px" : "16px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {ele.fileType === "IMAGE" ? (
                                  <FileImageOutlined
                                    style={{
                                      color: "#ecd540",
                                      marginTop: "5px",
                                      marginRight: "5px",
                                    }}
                                  />
                                ) : ele.fileType === "PDF" || ele.isPdf ? (
                                  <FaRegFilePdf
                                    style={{
                                      color: "red",
                                      marginTop: "5px",
                                      marginRight: "5px",
                                    }}
                                  />
                                ) : ele.offerDocument && ele.contractType === "LCAES" ? (
                                  <FileTextOutlined
                                    style={{
                                      color: "#74b3ed",
                                      marginRight: "5px",
                                      marginLeft: "20px",
                                    }}
                                  />
                                ) : (
                                  <FileTextOutlined
                                    style={{
                                      color: "#74b3ed",
                                      marginRight: "5px",
                                    }}
                                  />
                                )}
                                  {ele.offerDocument ? ele.documentName : ele.fileName || ""}
                                  {ele.offerDocument && !ele?.offerId && !["ERTS", "LCAES"].includes(ele.contractType) && <span style={{ color: "blue", fontSize: "20px", marginLeft: "5px" }}>* </span>}
                              </div>
                              {ele?.transactionData && (
                                <div
                                  style={{
                                    color: "grey",
                                    fontSize: "11px",
                                    marginLeft: "24px",
                                  }}
                                >
                                  {ele.contractType === "ERTS" && ele.transactionData && ele.transactionData.ListingPeriodBeginDate && <span>{moment(ele.transactionData.ListingPeriodBeginDate).format("MM/DD/YYYY")}</span>}
                                  {ele.contractType === "ERTS" && ele.transactionData && ele.transactionData.ListingPeriodEndByDate && <> - {moment(ele.transactionData.ListingPeriodEndByDate).format("MM/DD/YYYY")}</>}
                                  <span style={{ marginLeft: "19px" }}>
                                    {ele.contractType === "LCAES" && ele.transactionData && ele.transactionData.ListingPeriodEndByDate && <span>Ending Date </span>}
                                    {ele.contractType === "LCAES" && ele.transactionData && ele.transactionData.ListingPeriodEndByDate && <span> - {moment(ele.transactionData.ListingPeriodEndByDate).format("MM/DD/YYYY")}</span>}
                                  </span>
                                </div>
                              )}
                              <div
                                style={{
                                  color: "grey",
                                  fontSize: "11px",
                                  marginLeft: "20px",
                                }}
                              >
                                {ele.fileType ? `Uploaded by: ${ele.personId?.fullName}` : ""}
                              </div>
                            </div>
                          </td>

                            <td
                              style={{
                                color: "black",
                                fontWeight: "500",
                                textAlign: "center",
                                minWidth: "50px",
                                width: "10%",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              <div>
                                {moment(ele.createdDateMili).format("MM/DD/YYYY")}
                              </div>
                              <div>
                                {moment(ele.createdDateMili).format("hh:mm A")}
                              </div>
                            </td>

                            {/* Shared Folder Column */}
                            <td
                              style={{
                                fontWeight: "bolder",
                                textAlign: "center",
                                minWidth: "70px",
                                width: "10%",
                                color: "#085191",
                              }}
                            >
                              { !ele?.contractId && (
                                role === "SELLER" && item?.offerId ? (
                                  <Checkbox
                                    checked={ele?.isShared}
                                    onChange={() => {
                                      ele.isShared ? handleConfirm2(ele) : handleConfirm(ele);
                                    }}
                                    style={{
                                      color: ele.isShared ? "white" : "black",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : role === "BUYER" && ele?.showSeller ? (
                                  <Checkbox
                                    checked={ele?.isShared}
                                    onChange={() => {
                                      ele.isShared ? handleConfirm2(ele) : handleConfirm(ele);
                                    }}
                                    style={{
                                      color: ele?.isShared ? "white" : "black",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : (
                                  <Tooltip
                                    title={
                                      role === "SELLER" && !item?.offerId
                                        ? "Cannot share: Accepted offer does not exist."
                                        : role === "BUYER" && !ele?.showSeller
                                          ? "Please send this offer to seller agent to enable sharing."
                                          : ""
                                    }
                                    placement="right"
                                    style={{ width: "50px" }}
                                  >
                                    <Checkbox
                                      checked={false}
                                      disabled={true}
                                      style={{
                                        color: "black",
                                        borderRadius: "5px",
                                        cursor: "not-allowed",
                                      }}
                                    />
                                  </Tooltip>
                                )
                              )}
                            </td>

                            {/* Show in dashboard Column */}
                            <td
                              style={{
                                textAlign: "center",
                                minWidth: "100px",
                                width: "15%",
                              }}
                            >
                              {ele?.offerId && ele?.offerDocument && ele?.contractId  && !["ERTS"].includes(ele?.contractType) &&
                                 (
                                  <Checkbox
                                    checked={ele?.isCreatedInRtd}
                                    onChange={(e) => handleToggleDashboard(ele, e.target.checked)}
                                    disabled={
                                      (createOfferContractTypes.includes(ele?.contractType) && item?.status === "ACCEPTED")
                                    }
                                    style={{
                                      color: "black",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                    }}
                                  />
                                )}
                            </td>
                            
                            {/* Delete Column */}
                            <td
                              style={{
                                textAlign: "center",
                                minWidth: "70px",
                                width: "10%",
                                color: "#085191",
                              }}
                            >
        
                              {!(["ERTS"].includes(ele?.contractType) && item?.isProperty) &&
                                !(createOfferContractTypes.includes(ele?.contractType) && !["INPROGRESS", "SUBMITTED", "DRAFT"].includes(item?.status)) && (
                                  <Tooltip title="Delete" placement="right" style={{ width: "50px" }}>
                                    <Button
                                      icon={<DeleteOutlined />}
                                      onClick={() =>
                                        handleDeletePropertyDocs(
                                          ele,
                                          ele?.RTDInitiator ? "contractDoc" : "uploadedDoc"
                                        )
                                      }
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "red",
                                        border: "none",
                                      }}
                                    />
                                  </Tooltip>
                                )}
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {isDisclosureDocPresent(propertyDocs||[]) && (
                  <div style={{ margin: "10px 0px" }}>
                    <span style={{ color: "blue", fontWeight: "bolder", fontSize: "20px", marginRight: "2px" }}>* </span> This document was created before a transaction existed. Documents signed by buyer(s) will appear in Transaction Dashboard.
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Modal>

      {/* upload docs modal here  */}
      {
        nestedModalOpen?.isUploadDocsModal && <Modal
          width={800}
          open={nestedModalOpen?.isUploadDocsModal}
          footer={false}
          onCancel={handleCreateDocCancel}
          style={{marginTop: "-30px"}}
        >
          <div
            style={{
              textAlign: "center",
              color: "#888888",
              height: "72vh",
              fontSize: "18px",
              padding: "5px",
              marginTop: "10px",
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomRightRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
          >
            <Button
              icon={<ArrowLeftOutlined />}
              style={{
                position: "absolute",
                height: "5px",
                fontSize: "15px",
                cursor: "pointer",
                top: "0px",
                left: "0px",
                zIndex: "1",
                border: "none",
              }}
              onClick={() => {
                // Handle the back button click event here
                setNestedModalOpen({
                  ...nestedModalOpen,
                  isUploadDocsModal: false,
                });
              }}
            >
              <text style={{ color: "gray", marginLeft: "5px" }}>Back</text>
            </Button>
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", width: "100%", fontSize: "15px" }}>
                <Tabs
                  defaultActiveKey="1"
                  style={{ width: "100%", height: "calc(100vh - 8rem)" }}
                  className="centered-tabs"
                >
                  <TabPane
                    tab="Create New"
                    key={"1"}
                    style={{ width: "100%", height: "100%" }}
                    // disabled={role === "BUYER" ? true : false}
                  >
                    <CreateDocs role={role} item={item} section={section} handleCreateDocCancel={handleCreateDocCancel}/>
                  </TabPane>
                  <TabPane
                    tab="Upload"
                    key={"2"}
                    style={{ width: "100%", height: "100%" }}
                  >
                  <div style={{width:"100%",margin:"auto",marginTop:"20px",display:"flex",justifyContent:"center"}}>
                    <DraggerComp
                    uploadFile={uploadFile}
                    isModalOpen={isModalOpen}
                    setNestedModalOpen={setNestedModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    uploadTemp={uploadTemp}
                    accept=".pdf,image/*"
                    />
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </Modal>
      }


      <SignableAndReadOnly
        role={role}
        section={section}
        source={"PROPERTY_DOCS"}
        docSelected={true}
        nestedModalOpen={nestedModalOpen}
        setNestedModalOpen={setNestedModalOpen}
        currentPropertyDocId={currentPropertyDocId}
        setCurrentPropertyDocId={setCurrentPropertyDocId}
        selectedData={item}
        delegateOwnerId={delegate &&  agentDetails?._id}
        documentId={currentPropertyDocId.documentId}
      />
    </>
  );
};

export { PropertyDocsModal };
