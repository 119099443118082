/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Col, Row,Typography } from "antd";
import { templateAction } from "../Template/state/actions";
import { documentAction } from "./state/actions";
import { DocumentForm } from "./components/DocumentForm";
import { TimeLine } from "./components/TimeLine";
import { CompletedPercentage } from "./components/CompletedPercentage";
import styles from "./style/DocumentSection.module.css";
import { accountHandle } from "../Account/state/actions";
const { Content } = Layout;
const { Title } = Typography;

function DocumentSection(props) {
  const dispatch = useDispatch();
  const [redirectToCreateOffer, setRedirectToCreateOffer] = useState(false);

  useEffect(() => {
    if (props.networkConnection === false) {
      window.location.reload();
    }
  }, [props]);

  useEffect(() => {
    dispatch(templateAction.gettemplateData());
    dispatch(documentAction.getDocumentData());
  }, []);
  return (
    <Layout style={{ minHeight: "100%", padding: "0px",marginTop:"-10px"  }}>
      <Content>
        <Row gutter={[12, 24]}  style={{
                height: "100%",
              }}>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <div
              className={styles.documentContainer}
              style={{
                height: "100%",
              }}
            >
              <div
                style={{
                  border: "1px solid  rgba(0,0,0,0.2)",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  transition: "0.3s",
                  padding: "0.5rem 0.5rem 0.5rem 1rem",
                  height: "100%",
                }}
              >
                <div>
                  <Title level={3}>Steps</Title>
                </div>
                <div className={styles.stepBox}>
                  <div>
                    <TimeLine delegate={props?.delegate}/>
                  </div>
                  <div className={styles.percentageCircle}>
                    <CompletedPercentage progress={props?.progress} />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={19} lg={19} xl={19}>
            <div className="offer_screenForm documentFormContainer">
              <DocumentForm setProgress={props?.setProgress} redirectToCreateOffer={redirectToCreateOffer} setRedirectToCreateOffer={setRedirectToCreateOffer} progress={props?.progress} redirectFromClient={props} delegate={props?.delegate} />
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
export { DocumentSection };
