import { v4 as uuidv4 } from "uuid";

export const getNeedToKnowRoute = (pathname) => {
  try {
    if (!pathname || typeof pathname !== "string") {
      return "";
    }
    let needToKnowRoute = pathname.split("/");
    if (needToKnowRoute[2] === "documents") {
      return "/" + needToKnowRoute[1] + "/needtoknow/";
    } else {
      return "/" + needToKnowRoute[1] + "/" + needToKnowRoute[2] + "/needtoknow/";
    }
  } catch (error) {
    return "";
  }
};


export const addAgentData = ({ getIndividualTransactionData, agentData, agentList, agentType }) => {
  const transactionData = getIndividualTransactionData?.newTransactionData;

  if (!agentData && transactionData && transactionData[`${agentType}BrokerName`]) {
    const newAgent = {
      _id: uuidv4(),
      fullName: transactionData[`${agentType}BrokerName`],
      email: transactionData[`${agentType}BrokerEmailAddress`],
      phoneNumber: transactionData[`${agentType}BrokerPhoneNumber`],
      mobilePhoneNumber: {
        phoneNumber: transactionData[`${agentType}BrokerPhoneNumber`],
      },
    };
    agentList.push(newAgent);
  } else if (agentData) {
    agentList.push(agentData);
  }

  const coAgentKey = agentType === "Buyer" ? "CoBuyerAgents" : "CoSellerAgents";
  const additionalCoAgentData = transactionData?.[coAgentKey]?.filter(el => el.personId.length !== 24) || [];
  additionalCoAgentData.forEach(el => {
    const newAgent = {
      ...el,
      mobilePhoneNumber: {
        phoneNumber: el.phoneNumber,
      },
    };
    agentList.push(newAgent);
  });
};
